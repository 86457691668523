import React, { useState, useEffect } from "react";

import { Row, Col, Form, Input, Button, Select, Table, Slider } from 'antd';
import { ReactComponent as ExternalLinkIcon } from '../../images/icons/external-link.svg';
import styles from './CrabadaSearcher.module.scss';
import Header from '../../components/Header/Header'
import { CrabadaSearcherAxios, CrabadaSubclassesQuery } from '../../graphql/axios';

const { Option } = Select

const CrabadaSearcher = (props) => {
  const [loading, setLoading] = useState(false);
  const [searchResults, setSearchResults] = useState([]);
  const [form] = Form.useForm();
  const [subClasses, setSubClasses] = useState(false);

  const showLoading = () => {
    setLoading(true);
  }

  const stopLoading = () => {
    setLoading(false);
  }

  const columns = [
    {
      title: 'ID',
      dataIndex: 'id',
      key: 'id',
      render: (text) => <a target="_blank" href={`https://marketplace.crabada.com/crabada/${text}`}>
        {text} 
        <ExternalLinkIcon style={{
          verticalAlign: 'middle',
          transform: 'translateY(-2px)',
          marginLeft: '5px',
        }} />
      </a>
    },
    {
      title: 'Name',
      dataIndex: 'name',
      key: 'name',
    },
    {
      title: 'Price TUS',
      dataIndex: 'priceTus',
      key: 'priceTus',
      width: 200,
    },
    {
      title: 'Price USD',
      dataIndex: 'priceUsd',
      key: 'priceUsd',
      width: 200,
    },
    {
      title: 'Breeder Algo %',
      dataIndex: 'purityScore',
      key: 'purityScore',
      width: 200,
      render: (n) => <div>{n.toFixed(2)}</div>,
    },
  ]

  const onFinish = (values) => {
    const data = {};

    // Check all fields. Set "Any" as default if nothing is selected.
    for (var value in values) {
      if (Object.prototype.hasOwnProperty.call(values, value)) {
        data[value] = values[value];
      }
    }
    
    showLoading()

    CrabadaSearcherAxios(data)
      .then((result) => {
        setSearchResults(result.data?.data?.CrabadaSearcher?.results);
        stopLoading()
      }
    );
  }

  const clear = () => {
    form.resetFields()
  }

  const sliderHandler = (value, name) => {
    form.setFieldsValue({
      [name]: value,
    })
  }

  useEffect(() => {
    CrabadaSubclassesQuery()
      .then((result) => {
        setSubClasses(result.data?.data?.CrabadaSubclassesQuery)
        console.log('result',result.data.CrabadaSubclassesQuery);
      });
  }, [])

  useEffect(() => {
    console.log('subClasses',subClasses);
  }, [subClasses])

  return(
    <>
      <Header title='Crabada Searcher' />
      <Form
        form={form}
        layout="horizontal"
        name="advanced_search"
        onFinish={onFinish}
      >
        <Row gutter={40}>
          <Col xs={24}>
            <div className={styles.fieldsWrapper}>
              
              {/* Pincers starts here */}
              <div>
                <label className="white">Pincers</label>
                <Form.Item
                  name='pincer'
                  initialValue={[]}
                  rules={[
                    {
                      required: false,
                      message: 'Input something!',
                    },
                  ]}
                >
                  <Select showSearch name="pincer" placeholder="Please choose the pincer type" mode="multiple" allowClear style={{width: '100%'}}>
                    {
                      subClasses && (subClasses?.map((item, key) => <Option key={key} value={item.name.replace('-', '')}>{item.name}</Option>))
                    }
                  </Select>
                </Form.Item>
              </div>
              {/* Pincers ends here  */}
              
              {/* Body starts here */}
              <div>
                <label className="white">Body</label>
                <Form.Item
                  name='body'
                  initialValue={[]}
                  rules={[
                    {
                      required: false,
                      message: 'Input something!',
                    },
                  ]}
                >
                  <Select showSearch name="body" placeholder="Please choose Body type" mode="multiple" allowClear style={{width: '100%'}}>
                    {
                      subClasses && (subClasses?.map((item, key) => <Option key={key} value={item.name.replace('-', '')}>{item.name}</Option>))
                    }
                  </Select>
                </Form.Item>
              </div>
              {/* Body ends here  */}

              {/* Shell starts here */}
              <div>
                <label className="white">Shell</label>
                <Form.Item
                  name='shell'
                  initialValue={[]}
                  rules={[
                    {
                      required: false,
                      message: 'Input something!',
                    },
                  ]}
                >
                  <Select showSearch name="shell" placeholder="Please choose Shell type" mode="multiple" allowClear style={{width: '100%'}}>
                    {
                      subClasses && (subClasses?.map((item, key) => <Option key={key} value={item.name.replace('-', '')}>{item.name}</Option>))
                    }
                  </Select>
                </Form.Item>
              </div>
              {/* Shell ends here  */}

              {/* Mouth starts here */}
              <div>
                <label className="white">Mouth</label>
                <Form.Item
                  name='mouth'
                  initialValue={[]}
                  rules={[
                    {
                      required: false,
                      message: 'Input something!',
                    },
                  ]}
                >
                  <Select showSearch name="mouth" placeholder="Please choose Mouth type" mode="multiple" allowClear style={{width: '100%'}}>
                    {
                      subClasses && (subClasses?.map((item, key) => <Option key={key} value={item.name.replace('-', '')}>{item.name}</Option>))
                    }
                  </Select>
                </Form.Item>
              </div>
              {/* Mouth ends here  */}

              {/* Eyes starts here */}
              <div>
                <label className="white">Eyes</label>
                <Form.Item
                  name='eyes'
                  initialValue={[]}
                  rules={[
                    {
                      required: false,
                      message: 'Input something!',
                    },
                  ]}
                >
                  <Select showSearch name="eyes" placeholder="Please choose Eyes type" mode="multiple" allowClear style={{width: '100%'}}>
                    {
                      subClasses && (subClasses?.map((item, key) => <Option key={key} value={item.name.replace('-', '')}>{item.name}</Option>))
                    }
                  </Select>
                </Form.Item>
              </div>
              {/* Eyes ends here  */}

              {/* Horn starts here */}
              <div>
                <label className="white">Horn</label>
                <Form.Item
                  name='horn'
                  initialValue={[]}
                  rules={[
                    {
                      required: false,
                      message: 'Input something!',
                    },
                  ]}
                >
                  <Select showSearch name="horn" placeholder="Please choose Horn type" mode="multiple" allowClear style={{width: '100%'}}>
                    {
                      subClasses && (subClasses?.map((item, key) => <Option key={key} value={item.name.replace('-', '')}>{item.name}</Option>))
                    }
                  </Select>
                </Form.Item>
              </div>
              {/* Horn ends here  */}

              {/* Class starts here */}
              <div>
                <label className="white">Class</label>
                <Form.Item
                  name='class'
                  initialValue={[]}
                  rules={[
                    {
                      required: false,
                      message: 'Input something!',
                    },
                  ]}
                >
                  <Select showSearch name="class" placeholder="Please choose a class" mode="multiple" allowClear style={{width: '100%'}}>
                    <Option value={'BULK'}>Bulk</Option>
                    <Option value={'CRABOID'}>Craboid</Option>
                    <Option value={'GEM'}>Gem</Option>
                    <Option value={'ORGANIC'}>Organic</Option>
                    <Option value={'PRIME'}>Prime</Option>
                    <Option value={'RUINED'}>Ruined</Option>
                    <Option value={'SUNKEN'}>Sunken</Option>
                    <Option value={'SURGE'}>Surge</Option>
                  </Select>
                </Form.Item>
              </div>
              {/* Class ends here  */}

              {/* Purity starts here */}
              <div>
                <label className="white">Purity</label>
                <Form.Item
                  name='pure'
                  initialValue={[0, 6]}
                  rules={[
                    {
                      required: false,
                      message: 'Input something!',
                    },
                  ]}
                >
                    <Slider name="pure" range min={0} max={6} defaultValue={[0, 6]} style={{ width: '100%' }} onChange={value => { sliderHandler(value, 'pure') }} />
                    <table width={'100%'}>
                        <tr>
                            <td width={'14.285714286%'}>0</td>
                            <td width={'14.285714286%'}>1</td>
                            <td width={'14.285714286%'}>2</td>
                            <td width={'14.285714286%'}>3</td>
                            <td width={'14.285714286%'}>4</td>
                            <td width={'14.285714286%'}>5</td>
                            <td width={'14.285714286%'}>6</td>
                        </tr>
                    </table>
                </Form.Item>
              </div>
              {/* Purity ends here  */}

              {/* Breed Count starts here */}
              <div>
                <label className="white">Breed Count</label>
                <Form.Item
                  name='breedCount'
                  initialValue={[0, 5]}
                  rules={[
                    {
                      required: false,
                      message: 'Input something!',
                    },
                  ]}
                >
                    <Slider name="breedCount" range min={0} max={5} defaultValue={[0, 5]} style={{ width: '100%' }} onChange={value => { sliderHandler(value, 'breedCount') }} />
                    <table width={'100%'}>
                        <tr>
                            <td width={'16.666666667%'}>0</td>
                            <td width={'16.666666667%'}>1</td>
                            <td width={'16.666666667%'}>2</td>
                            <td width={'16.666666667%'}>3</td>
                            <td width={'16.666666667%'}>4</td>
                            <td width={'16.666666667%'}>5</td>
                        </tr>
                    </table>
                </Form.Item>
              </div>
              {/* Breed Count ends here  */}

              {/* Legend starts here */}
              <div>
                <label className="white">Legend</label>
                <Form.Item
                  name='legend'
                  initialValue={[0, 6]}
                  rules={[
                    {
                      required: false,
                      message: 'Input something!',
                    },
                  ]}
                >
                  <Slider name="legend" range min={0} max={6} defaultValue={[0, 6]} style={{ width: '100%' }} onChange={value => { sliderHandler(value, 'legend') }} />
                  <table width={'100%'}>
                      <tr>
                          <td width={'14.285714286%'}>0</td>
                          <td width={'14.285714286%'}>1</td>
                          <td width={'14.285714286%'}>2</td>
                          <td width={'14.285714286%'}>3</td>
                          <td width={'14.285714286%'}>4</td>
                          <td width={'14.285714286%'}>5</td>
                          <td width={'14.285714286%'}>6</td>
                      </tr>
                  </table>
                </Form.Item>
              </div>
              {/* Legend ends here  */}

              {/* 
              breed Type starts here */}
              <div>
                <label className="white">Breed Type</label>
                <Form.Item
                  name='breedType'
                  initialValue={[]}
                  rules={[
                    {
                      required: false,
                      message: 'Input something!',
                    },
                  ]}
                >
                  <Select showSearch name="breedType" placeholder="Please choose the breed type" mode="multiple" allowClear style={{width: '100%'}}>
                    {
                      subClasses && (subClasses?.map((item, key) => <Option key={key} value={item.name.replace('-', '')}>{item.name}</Option>))
                    }
                  </Select>
                </Form.Item>
              </div>
              {/* Breed Type ends here  */}

              {/* Breed Type Score starts here */}
              <div>
                <label className="white">Breed Type Score</label>
                <Form.Item
                  name='breedTypeCount'
                  initialValue={0}
                  rules={[
                    {
                      required: false,
                      message: 'Input something!',
                    },
                  ]}
                >
                  <Input type="number" min={0} max={18} name="breedTypeCount" placeholder="Please input the breed type score" />
                </Form.Item>
              </div>
              {/* Breed Type Score ends here  */}

              {/* Threshold starts here */}
              <div>
                <label className="white">Threshold</label>
                <Form.Item
                  name='threshold'
                  initialValue={0}
                  rules={[
                    {
                      required: false,
                      message: 'Input something!',
                    },
                  ]}
                >
                  <Input type="number" name="pure" placeholder="Please input the threshold" />
                </Form.Item>
              </div>
              {/* Threshold ends here  */}

              {/* Sort starts here */}
              <div>
                <label className="white">Sort</label>
                <Form.Item
                  name='orderBy'
                  initialValue={'PRICE_DESC'}
                  rules={[
                    {
                      required: false,
                      message: 'Input something!',
                    },
                  ]}
                >
                  <Select name="orderBy" defaultValue={'PRICE_ASC'} placeholder="Please choose the sort order" style={{width: '100%'}}>
                    <Option value={'PRICE_ASC'} selected>Price Ascending</Option>
                    <Option value={'PRICE_DESC'}>Price Descending</Option>
                  </Select>
                </Form.Item>
              </div>
              {/* Sort ends here  */}

            </div>
          </Col>
        </Row>

        <Row gutter={40}>
          <Col xs={24} xl={24} className={styles.actionWrapper}>
            <Button type="primary" className="btn btn-info" disabled={loading} htmlType="submit">Search</Button>
            <Button type="default" className="btn" onClick={clear}>Clear</Button>
          </Col>
        </Row>
      </Form>

      <Row gutter={40}>
        <Col xs={24} xl={24}>
          <Table
            columns={columns}
            dataSource={searchResults?.length > 0 && searchResults}
            loading={loading}
            pagination={true}
            scroll={{ x: true }}
          />
        </Col>
      </Row>
    </>
  )
}

export default CrabadaSearcher
