import React from "react";
import { Sparklines, SparklinesLine } from 'react-sparklines';
import { Card, Typography } from 'antd';

const { Title } = Typography;
const ChartCard = (props) =>{
	return(
	<Card className="chartcard" style={{ width: '97%', borderColor:'#293b53'}}>
		<Title level={5} style={{color:"#73859d"}}>
			{props.title}
		</Title>
		<Title level={3} className="title" style={{marginTop:0}}>
			Ξ{props.amount}
			<small style={{paddingLeft:'1em',color:'#A3B2C6'}}>
				{props.currency}
			</small>
			<span style={{float:'right',color:'#90ccb9'}}>
				%{props.rate}
			</span>
		</Title>
	    <Sparklines data={props.data} height={74}>
		    <SparklinesLine color="#70ad9f"  />
		</Sparklines>
	</Card>
	)
}

export default ChartCard;