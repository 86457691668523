import React from "react";
import { Table, Typography } from 'antd';

const { Title, Text } = Typography;

const columns = [
  {
    title: 'Strategy',
    dataIndex: 'strategy',
    key: 'strategy',
    // render: text => <a>{text}</a>,
  },
  {
    title: 'Game',
    dataIndex: 'game',
    key: 'game',
    //responsive: ['md'],
  },
  {
    title: 'Avg. Floor Price (1wk)',
    dataIndex: 'floor_7d',
    key: 'floor_7d',
    //responsive: ['md'],
  },
  {
    title: 'Supply / Demand Ratio (1wk)',
    dataIndex: 'sdratio_7d',
    key: 'sdratio_7d',
    //responsive: ['lg'],
  },
  {
    title: 'Cycle Duration',
    dataIndex: 'cycle',
    key: 'cycle',
    //responsive: ['lg'],
  },
  {
    title: 'Expected Cycle Return Rate',
    dataIndex: 'cycle_return_rate',
    key: 'cycle_return_rate',
    //responsive: ['lg'],
  },
  {
    title: 'Status',
    dataIndex: 'status',
    key: 'status',
    render: text => <TextStatus text={text}></TextStatus>
  },
];

const data = [
  {
    key: '1',
    strategy: 'RIMP Double Nut',
    game: 'Axie Infinity',
    floor_7d: 'Ξ0.3',
    sdratio_7d: '3:1',
    cycle:'5 days',
    cycle_return_rate: '21%',
    status: 'Go Aggresive'
  },
  {
    key: '2',
    strategy: 'Terminator',
    game: 'Axie Infinity',
    floor_7d: 'Ξ0.15',
    sdratio_7d: '10:1',
    cycle:'5 days',
    cycle_return_rate: '14%',
    status: 'Maintain Position'
  },
  {
    key: '3',
    strategy: 'Poison Plant',
    game: 'Axie Infinity',
    floor_7d: 'Ξ0.1',
    sdratio_7d: '5:1',
    cycle:'5 days',
    cycle_return_rate: '4%',
    status: 'Stay Conservative'
  },
  {
    key: '4',
    strategy: 'Nakamoto',
    game: 'ZED Run',
    floor_7d: 'Ξ0.1',
    sdratio_7d: '5:1',
    cycle:'20 days',
    cycle_return_rate: '2%',
    status: 'Stay Conservative'
  }
];


const TextStatus = (props) => {
  const type = props.text === "Go Aggresive" && "success" ||
  props.text === "Maintain Position" && "warning" ||
  props.text === "Stay Conservative" && "danger";

  return(<Text type={type} strong={true}>{props.text}</Text>)
}
const BreedingStrategyTable = (props) =>
{
  return(
    <>
      <Title level={3} className="table-title">
        Breeding Strategy
      </Title>
      <Table
        className="table"
        size="middle"
        columns={columns}
        dataSource={data} //props
        rowClassName="sd-table"
        pagination={false}/>
    </>
    )
}

export default BreedingStrategyTable;