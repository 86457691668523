import './App.css';
import AppWrapper from './pages/AppWrapper'
import client from "./graphql/apollo";
import './styles/app.scss'
import {
  ApolloProvider,
} from "@apollo/client";

function App() {
  return ( 
    <ApolloProvider client={client}>
      <AppWrapper /> 
    </ApolloProvider>
    // <Dashboard/>
  );
}

export default App;
