
import {
  gql
} from "@apollo/client";

// export const LoginQuery = (username,password) =>{
//   return gql `{
//   LoginQuery(username: "${username}", password: "${password}") {
//       accessToken
//     }
//   }`
// }

export const AdminLoginQuery = (username,password) =>{
  return gql `{
  AdminLoginQuery(username: "${username}", password: "${password}") {
      accessToken
      refreshToken
    }
  }`
}

export const RefreshAdminLoginQuery = () =>{
  return gql `{
  AdminLoginQuery{
      accessToken
      refreshToken
    }
  }`
}

export const UserMutation = (username,password,ronin,role) =>{
  const user_role = role.toUpperCase()
  if(user_role === "AUTHORIZER")
  {
    return gql`
    mutation
    {
      UserMutation(
        input:
        {
          role: AUTHORIZER,
          username: "${username}",
          password: "${password}",
          ronin: "${ronin}"
        }
      )
      {
        result
        {
          success
        }
      }
    }`  
  }
  else if(user_role === "SUPER_AUTHORIZER")
  {
    return gql`
    mutation
    {
      UserMutation(
        input:
        {
          role: SUPER_AUTHORIZER,
          username: "${username}",
          password: "${password}",
          ronin: "${ronin}"
        }
      )
      {
        result
        {
          success
        }
      }
    }`  
  }
  else
  {
    return gql`
    mutation
    {
      UserMutation(
        input:
        {
          role: BREEDER,
          username: "${username}",
          password: "${password}",
          ronin: "${ronin}"
        }
      )
      {
        result
        {
          success
        }
      }
    }`  
  }
}

export const UserUpdateMutation = (id,username,ronin,role) =>{
  const user_role = role.toUpperCase()
  if(user_role === "AUTHORIZER")
  {
    return gql`
    mutation
    {
      UserUpdateMutation(
        input:
        {
          userId:"${id}",
          role: AUTHORIZER,
          username: "${username}",
          ronin: "${ronin}"
        }
      )
      {
        result
        {
          id
        }
      }
    }`  
  }
  else if(user_role === "SUPER_AUTHORIZER")
  {
    return gql`
    mutation
    {
      UserUpdateMutation(
        input:
        {
          userId:"${id}",
          role: SUPER_AUTHORIZER,
          username: "${username}",
          ronin: "${ronin}"
        }
      )
      {
        result
        {
          id
        }
      }
    }`  
  }
  else
  {
    return gql`
    mutation
    {
      UserUpdateMutation(
        input:
        {
          userId:"${id}",
          role: BREEDER,
          username: "${username}",
          ronin: "${ronin}"
        }
      )
      {
        result
        {
          id
        }
      }
    }`  
  }
}  

export const UserDeleteQuery = (id) => gql`{
  UserDeleteQuery(userId:"${id}")
  {
    success
  }
}`
export const UsersQuery = gql`{
  UsersQuery{
    id
    username
    ronin
    role
  }
}`

export const SearchParametersQuery = gql`
  {
  SearchParametersQuery {
    classes {
      name
    }
    mouth {
      name
      partId
    }
    horn {
      name
      partId
    }
    back {
      name
      partId
    }
    tail {
      name
      partId
    }
    eyes {
      name
      partId
    }
    ears {
      name
      partId
    }
  }
}`
export const SearchAxiesQuery = (axieClass,axieParts,breedCount,purity,from,to,sort) => gql`{
  SearchAxiesQuery(
    axieClass: ${axieClass}
    axieParts: ["${axieParts.join('","')}"]
    breedCount: ${breedCount}
    pureness: ${purity}
    from: ${from}
    size: ${to}
    sort: ${sort}
  ) {
    total
    results {
      id
      name
      ethPrice
      breedCount
      purity
      ability
      r1r2
    }
  }
}`

export const SearchAxiesR1R2Query = (axieClass,axieParts,breedCount,purity,from) => gql`{
  SearchAxiesR1R2Query(
    axieClass: ${axieClass}
    axieParts: ["${axieParts.join('","')}"]
    breedCount: ${breedCount}
    pureness: ${purity}
    from: ${from}
  ){
    total
    results {
      id
      name
      ethPrice
      breedCount
      purity
      ability
      r1r2
    }
  }
}`
export const DashboardQuery = gql`{
  AxieMarketQuery{
    marketStats{
      last24Hours {
        supply
        demand
        volumeEth
        volumeUsd        
      },
      last7Days {
        supply
        demand
        volumeEth
        volumeUsd  
      }
      floorPrice
    }
  }
}`

export const CrabadaChartQuery = (params) => {
  return gql`{
    CrabadaChartQuery(
      saleType: ${params.saletype}
      crabadaClass: ${params.class}
      stage: ${params.stage}
      purity: ${params.purity}
      virgin: ${params.virgin}
    ) {
      results {
        date
        total
      }
    }
  }`
}

export const AxieFloorPrice = gql`{
  AxiesQuery(
    auctionType: SALE,
    from: 0,
    size: 1,
    sort: PRICE_ASC
  ) {
    axies {
      results {
        id
        name
        auction {
          currentPrice
          currentPriceUSD
        }
        battleInfo {
          banned
        }
      }
    }
  }
}`

export const AxieQuery = (id) => gql`{
  AxieQuery(axieId:"${id}")
  {
    axie {
      id
      breedCount
      name
      genes
      chain
      image
      class
      pureness
      owner
      stage
      title
      parts {
        id
        name
        class
        specialGenes
        type
        stage
      }
    }
  }
}`

export const CryptoUnicornsParametersQuery = gql`{
  CryptoUnicornsParametersQuery {
    body {
      value
      enum
    }
    face {
      value
      enum
    }
    hooves {
      value
      enum
    }
    horn {
      value
      enum
    }
    mane {
      value
      enum
    }
    tail {
      value
      enum
    }
    unicornClass {
      value
      enum
    }
    lifecycle {
      value
      enum
    }
    mythic {
      value
      enum
    }
  }
}`

// const NewEthExchangeRate = gql`query NewEthExchangeRate {
//   exchangeRate {
//     eth {
//       usd
//       __typename
//     }
//     __typename
//   }
// }`

// const GetOverviewToday = gql`query GetOverviewToday {
//   marketStats {
//     last24Hours {
//       ...OverviewFragment
//       __typename
//     }
//     last7Days {
//       ...OverviewFragment
//       __typename
//     }
//     last30Days {
//       ...OverviewFragment
//       __typename
//     }
//     __typename
//   }
// }
// fragment OverviewFragment on SettlementStats {
//   count
//   axieCount
//   volume
//   volumeUsd
//   __typename
// }`

// const GetAxieBriefList = gql`query GetAxieBriefList($auctionType: AuctionType, $criteria: AxieSearchCriteria, $from: Int, $sort: SortBy, $size: Int, $owner: String) {
//   axies(auctionType: $auctionType, criteria: $criteria, from: $from, sort: $sort, size: $size, owner: $owner) {
//     total
//     results {
//       ...AxieBrief
//       __typename
//     }
//     __typename
//   }
// }
// fragment AxieBrief on Axie {
//   id
//   name
//   stage
//   class
//   breedCount
//   image
//   title
//   battleInfo {
//     banned
//     __typename
//   }
//   auction {
//     currentPrice
//     currentPriceUSD
//     __typename
//   }
//   parts {
//     id
//     name
//     class
//     type
//     specialGenes
//     __typename
//   }
//   __typename
// }`