
import { refreshTokenQuery } from '../graphql/axios';
import { notification } from 'antd';

export const refreshTokenHandler = () => {
    refreshTokenQuery()
        .then((result) => {

            let accessToken;
            let refreshToken;
            const response = result?.data?.data?.AdminLoginQuery;

            if(response !== undefined) {
                accessToken = response.accessToken
                refreshToken = response.refreshToken;
            }

            // remove old token
            localStorage.removeItem('appToken');
            localStorage.removeItem('refreshToken');

            // console.log("accessToken: ", accessToken);
            // console.log("refreshToken: ", refreshToken);

            // set new token from refresh endpoint
            localStorage.setItem("appToken", accessToken)
            localStorage.setItem("refreshToken", refreshToken)
            
            // notify user that the token has expired and the page is going to refresh
            notification['warning']({
                message: 'This page will automatically refresh.',
                description:
                'JWT token is detected expired.',
            });

            window.location.reload();
        }
    );
};