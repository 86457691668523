import React from "react";
import { Table, Typography } from 'antd';
const { Title } = Typography;

const columns = [
  {
    title: 'Name',
    dataIndex: 'name',
    key: 'name',
    // render: text => <a>{text}</a>,
  },
  {
    title: 'Total Supply',
    dataIndex: 'supply_24hr',
    key: 'supply_24hr',
    //responsive: ['md'],
  },
  {
    title: '24hr Demand',
    dataIndex: 'demand_24hr',
    key: 'demand_24hr',
    //responsive: ['md'],
  },
  {
    title: 'Supply / Demand Ratio',
    dataIndex: 'sdratio',
    key: 'sdratio',
    render: text => text.toFixed(2)
    //responsive: ['lg'],
  },
  {
    title: 'Avg. Daily Supply (1wk)',
    dataIndex: 'supply_7d',
    key: 'supply_7d',
    //responsive: ['lg'],
  },
  {
    title: 'Avg. Daily Demand (1wk)',
    dataIndex: 'demand_7d',
    key: 'demand_7d',
    render: text => text.toFixed(2)
    //responsive: ['lg'],
  },
  {
    title: 'Supply / Demand Ratio (1wk)',
    dataIndex: 'sdratio_7d',
    key: 'sdratio_7d',
    render: text => text.toFixed(2)
    //responsive: ['lg'],
  },
  {
    title: 'Axie Floor Price',
    dataIndex: 'floor_7d',
    key: 'floor_7d',
    render: text => `Ξ ${text}`
    //responsive: ['lg'],
  },
];

// const data = [
//   {
//     key: '1',
//     name: 'Axie Infinity',
//     supply_24hr: 100000,
//     demand_24hr: 650000,
//     sdratio: '3:1',
//     supply_7d: 3000000,
//     demand_7d: 1000000,
//     sdratio_7d: '3:1',
//     floor_7d: 'Ξ0.3'
//   },
//   {
//     key: '2',
//     name: 'ZED Run',
//     supply_24hr: 50000,
//     demand_24hr: 30000,
//     sdratio: '10:1',
//     supply_7d: 1000000,
//     demand_7d: 100000,
//     sdratio_7d: '10:1',
//     floor_7d: 'Ξ0.15'
//   },
// ];

const SupplyDemandTable = (props) =>
{
  props.data.map((item,key)=>{
    item.key = key;
    return item;
  })
  return(
    <>
      <Title level={3} className="table-title">
        Marketplace Liquidity
      </Title>
      <Table
        className="table"
        size="middle"
        columns={columns}
        loading={props.loading}
        dataSource={props.data} //props
        rowClassName="sd-table"
        pagination={false}/>
    </>
    )
}

export default SupplyDemandTable;