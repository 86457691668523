import React, { useState, useRef } from "react";
import _, { isEmpty } from "lodash";
import { useQuery } from "@apollo/client";
import { CryptoUnicornsParametersQuery } from '../../graphql/queries';
import { CryptoUnicornSearcherQuery, CryptoUnicornSearchByIdQuery } from '../../graphql/axios';
import { Row, Col, Table, Button, Form, Select, Slider, Input, notification } from 'antd';
import { ReactComponent as ExternalLinkIcon } from '../../images/icons/external-link.svg';
import Header from '../../components/Header/Header'
import styles from './CyptoUnicornSearcher.module.scss';

const { Option } = Select;

const CyptoUnicornSearcher = (props) => {
    const [loading, setLoading] = useState(false);
    const [form] = Form.useForm();
    const sort = useRef('HIGHEST_BREED_POINTS');
    const [searchResults, setSearchResults] = useState([]);
    const { data } = useQuery(CryptoUnicornsParametersQuery);
    const [breedingpoints, setBreedingPoints] = useState(8);
    const [power, setPower] = useState([188,644]);
    const [speed, setSpeed] = useState([188,696]);
    const [endurance, setEndurance] = useState([188,677]);
    const [intelligence, setIntelligence] = useState([188,575]);
    const [accuracy, setAccuracy] = useState([94,218]);
    const [attack, setAttack] = useState([94,363]);
    const [attackspeed, setAttackSpeed] = useState([94,348]);
    const [defense, setDefense] = useState([94,295]);
    const [magic, setMagic] = useState([94,288]);
    const [movement, setMovement] = useState([94,348]);
    const [vitality, setVitality] = useState([94,382]);
    const [resistance, setResistance] = useState([94,287]);
    const totaScoreMinMax = useRef([0,0]);
    // const [disableInput, setDisableInput] = useState(false);
    const waitTime = 500;  

    let timer;             

    const setValue = (value, a, key) => {
        const stats = ["breedingpoints", "power", "endurance", "speed", "attackspeed", "intelligence", "accuracy", "defense", "magic", "attack", "movement", "vitality", "resistance"];

        if(stats.includes(a)) {
            if(value.length > 3){
                value = value.substring(0,3);
                openNotification('error', 'Invalid value', 'Min and Max inputs only accept 3 digits.');
            }

            if(a === 'breedingpoints') setBreedingPoints(value);
            if(a === 'speed') {
                if(key === 0) setSpeed([value, speed[1]]);
                else setSpeed([speed[0], value]);
            }
            if(a === 'power'){ 
                if(key === 0) setPower([value, power[1]]);
                else setPower([power[0], value]);
            }
            if(a === 'endurance'){
                if(key === 0) setEndurance([value, endurance[1]]);
                else setEndurance([speed[0], value]);
            }
            if(a === 'intelligence'){
                if(key === 0) setIntelligence([value, intelligence[1]]);
                else setIntelligence([intelligence[0], value]);
            }
            if(a === 'accuracy'){
                if(key === 0) setAccuracy([value, accuracy[1]]);
                else setAccuracy([accuracy[0], value]);
            }
            if(a === 'attack'){
                if(key === 0) setAttack([value, attack[1]]);
                else setAttack([accuracy[0], value]);
            } 
            if(a === 'attackspeed'){
                if(key === 0) setAttackSpeed([value, attackspeed[1]]);
                else setAttackSpeed([attackspeed[0], value]);
            } 
            if(a === 'defense'){
                if(key === 0) setDefense([value, defense[1]]);
                else setDefense([defense[0], value]);
            }
            if(a === 'magic'){
                if(key === 0) setMagic([value, magic[1]]);
                else setMagic([magic[0], value]);
            }
            if(a === 'movement'){
                if(key === 0) setMovement([value, movement[1]]);
                else setMovement([movement[0], value]);
            } 
            if(a === 'vitality'){
                if(key === 0) setVitality([value, vitality[1]]);
                else setVitality([vitality[0], value]);
            }  
            if(a === 'resistance'){
                if(key === 0) setResistance([value, resistance[1]]);
                else setResistance([resistance[0], value]);
            }
        }
    }   

    const onFinish = (values) => {
        let CUResults = [];

        setLoading(true);

        const searchData = (values) => {
            CryptoUnicornSearcherQuery(values, breedingpoints, power, endurance, speed, attackspeed, intelligence, accuracy, defense, magic, attack, movement, vitality, resistance)
                .then((result) => {
                    const results = result?.data?.data?.CryptoUnicornSearcherQuery?.results ? result?.data?.data?.CryptoUnicornSearcherQuery?.results : [];
                    
                    CUResults.push(...results);
                    done(CUResults, values);
                }
            );
        }

        searchData(values);
    }

    const done = (CUResults, values) => {
        if(totaScoreMinMax.current[0] !== 0 && totaScoreMinMax.current[1] !== 0) {
            const countForMinMaxTotalScore = _.filter(CUResults, function(items){ 
                return items.totalScore >= totaScoreMinMax.current[0] && items.totalScore <= totaScoreMinMax.current[1]
            });

            if(countForMinMaxTotalScore.length === 0) {
                openNotification('info', '', 'Results found are not within the specified Total Score range filter.');
            } else {
                CUResults = countForMinMaxTotalScore;
            }
        } 
        
        setSearchResults(CUResults);
        setLoading(false);
    }

    const setValueMinMax = (value, type) => {
        if(type === 'min') totaScoreMinMax.current[0] = (value === '') ? 0 : value;
        if(type === 'max') totaScoreMinMax.current[1] = (value === '') ? 0 : value;
    }

    const searchById = (id) => {
        if(id === '' || isEmpty(id) === true) {
            // setDisableInput(false);
            onReset();
        } else {
            // setDisableInput(true);

            clearTimeout(timer);
    
            function fake(id) {
                CryptoUnicornSearchByIdQuery(id)
                    .then((result) => {
                        const results = result?.data?.data?.CryptoUnicornSearchByIdQuery ? result?.data?.data?.CryptoUnicornSearchByIdQuery : [];

                        if(results.length !== 0) {
                            // update states
                            setSpeed((results.speedScore.includes('null') === true) ? [speed[0], results.speedScore[1]] : results.speedScore);
                            setPower((results.powerScore[0] === null) ? [power[0], results.powerScore[1]] : results.powerScore);
                            setEndurance((results.enduranceScore[0] === null) ? [endurance[0], results.enduranceScore[1]] : results.enduranceScore );
                            setIntelligence((results.intelligenceScore[0] == null) ? [intelligence[0], results.enduranceScore[1]] :  results.enduranceScore);
                            setAccuracy((results.accuracy[0] === null) ? [accuracy[0], results.accuracy[1]] : results.accuracy);
                            setAttack((results.attack[0] === null) ? [attack[0], results.attack[1]] : results.attack);
                            setAttackSpeed((results.attackSpeed[0] === null) ? [attackspeed[0], results.attackSpeed[1]] : results.attackSpeed);
                            setDefense((results.defense[0] === null) ? [defense[0], results.defense[1]] : results.defense);
                            setMagic((results.magic === null) ? [magic[0], results.magic[0]] : results.magic);
                            setMovement((results.movementSpeed[0] === null) ? [movement[0], results.movementSpeed[1]] : results.movementSpeed);
                            setVitality((results.vitality[0] === null) ? [vitality[0], results.vitality[1]] : results.vitality);
                            setResistance((results.resistance[0] === null) ? [resistance[0], results.resistance[1]] : results.resistance);
                            setBreedingPoints((results.breedingPoints === null) ? breedingpoints : results.breedingPoints);
    
                            form.setFieldsValue({'Class': (results.unicornClass) ? results.unicornClass : [] });
                            form.setFieldsValue({'Mythic': (results.mythic) ? results.mythic : [] });
                            form.setFieldsValue({'Hooves': (results.hooves) ? results.hooves : [] });
                            form.setFieldsValue({'Mane': (results.mane) ? results.mane : [] });
                            form.setFieldsValue({'Tail': (results.tail) ? results.tail : [] });
                            form.setFieldsValue({'Face': (results.face) ? results.face : [] });
                            form.setFieldsValue({'Horn': (results.horn) ? results.horn : [] });
                            form.setFieldsValue({'Body': (results.body) ? results.body : [] });
                            form.setFieldsValue({'Lifecycle': (results.lifecycle) ? results.lifecycle : []});
                            form.setFieldsValue({'power_min': (results.powerScore[0] === null) ? power[0] : results.powerScore[0]});
                            form.setFieldsValue({'power_max': results.powerScore[1]});
                            form.setFieldsValue({'speed_min': (results.speedScore[0] === null) ? speed[0] : results.speedScore[0]});
                            form.setFieldsValue({'speed_max': results.speedScore[1]});
                            form.setFieldsValue({'endurance_min': (results.enduranceScore[0] === null) ? endurance[0] : results.enduranceScore[0]});
                            form.setFieldsValue({'endurance_max': results.enduranceScore[1]});
                            form.setFieldsValue({'intelligence_min': (results.intelligenceScore[0] === null) ? intelligence[0] : results.intelligenceScore[0] });
                            form.setFieldsValue({'intelligence_max': results.intelligenceScore[1]});
                            form.setFieldsValue({'accuracy_min': (results.accuracy[0] === null) ? accuracy[0] : results.accuracy[0]});
                            form.setFieldsValue({'accuracy_max': results.accuracy[1]});
                            form.setFieldsValue({'attack_speed_min': (results.attackSpeed[0] == null) ? attackspeed[0] : results.attackSpeed[0]});
                            form.setFieldsValue({'attack_speed_max': results.attackSpeed[1]});
                            form.setFieldsValue({'magic_min': (results.magic[0] === null) ? magic[0] : results.magic[0]});
                            form.setFieldsValue({'magic_max': results.magic[1]});
                            form.setFieldsValue({'defense_min': (results.defense[0] === null) ? defense[0] : results.defense[0]});
                            form.setFieldsValue({'defense_max': results.defense[1]});
                            form.setFieldsValue({'total_score_min': (results.totalScore[0] === null) ? totaScoreMinMax.current[0] : results.totalScore[0]});
                            form.setFieldsValue({'total_score_max': (results.totalScore[1] === null) ? totaScoreMinMax.current[1] :  results.totalScore[1]});
                            form.setFieldsValue({'attack_min': (results.attack[0] === null) ? attack[0] : results.attack[0]});
                            form.setFieldsValue({'attack_max': results.attack[1]});
                            form.setFieldsValue({'movement_min': (results.movementSpeed[0] === null) ? movement[0] : results.movementSpeed[0]});
                            form.setFieldsValue({'movement_max': results.movementSpeed[1]});
                            form.setFieldsValue({'vitality_min': (results.vitality[0] === null) ? vitality[0] : results.vitality[0] });
                            form.setFieldsValue({'vitality_max': results.vitality[1]});
                            form.setFieldsValue({'resistance_min': (results.resistance[0] === null) ? resistance[0] : results.resistance[0]});
                            form.setFieldsValue({'resistance_max': results.resistance[1]});
                        }

                    }
                );
            }
    
            timer = setTimeout(() => { fake(id) }, waitTime);
        }
    }

    const onReset = () => {
        setPower([188,644]);
        setSpeed([188,696]);
        setEndurance([188,677]);
        setIntelligence([188,575]);
        setAccuracy([94,218]);
        setAttack([94,363]);
        setDefense([94,295]);
        setMagic([94,288]);
        setMovement([94,348]);
        setVitality([94,382]);
        setResistance([94,287]);
        setResistance([94,287]);
        setBreedingPoints(8);

        form.resetFields();
        form.setFieldsValue({'power_min': power[0]});
        form.setFieldsValue({'power_max': power[1]});
        form.setFieldsValue({'speed_min': speed[0]});
        form.setFieldsValue({'speed_max': speed[1]});
        form.setFieldsValue({'endurance_min': endurance[0]});
        form.setFieldsValue({'endurance_max': endurance[1]});
        form.setFieldsValue({'intelligence_min': intelligence[0]});
        form.setFieldsValue({'intelligence_max': intelligence[1]});
        form.setFieldsValue({'accuracy_min': accuracy[0]});
        form.setFieldsValue({'accuracy_max': accuracy[1]});
        form.setFieldsValue({'attack_speed_min': attackspeed[0]});
        form.setFieldsValue({'attack_speed_max': attackspeed[1]});
        form.setFieldsValue({'magic_min': magic[0]});
        form.setFieldsValue({'magic_max': magic[1]});
        form.setFieldsValue({'defense_min': defense[0]});
        form.setFieldsValue({'defense_max': defense[1]});
        form.setFieldsValue({'attack_min': attack[0]});
        form.setFieldsValue({'attack_max': attack[1]});
        form.setFieldsValue({'movement_min': movement[0]});
        form.setFieldsValue({'movement_max': movement[1]});
        form.setFieldsValue({'vitality_min': vitality[0]});
        form.setFieldsValue({'vitality_max': vitality[1]});
        form.setFieldsValue({'resistance_min': resistance[0]});
        form.setFieldsValue({'resistance_max': resistance[1]});
    }

    const closeNotification = () => {
        return;
    }
      
    const openNotification = (type, message, description) => {
        const key = `open${Date.now()}`;
        const btn = (
            <Button type="primary" size="small" onClick={() => notification.close(key)}>
                Confirm
            </Button>
        );
        notification.open({
            type: type,
            message: message,
            description:
                description,
            btn,
            key,
            onClose: closeNotification,
            duration: 0,
        });
    }
    
    const columns = [
        {
            title: 'ID',
            dataIndex: 'id',
            key: 'id',
            render: (text, data) => <a target="_blank" href={data.link}>
                {text} 
                <ExternalLinkIcon style={{
                    verticalAlign: 'middle',
                    transform: 'translateY(-2px)',
                    marginLeft: '5px',
                }} />
            </a>,
            width: 150,
        },
        {
            title: 'Name',
            dataIndex: 'name',
            key: 'name',
            width: 250,
        },
        // {
        //     title: 'Price',
        //     dataIndex: 'price',
        //     key: 'price',
        // },
        {
            title: 'Breeding Points',
            dataIndex: 'breedingPoints',
            key: 'breedCbreedingPointsount',
            width: 200,
        },
        {
            title: 'Power Score',
            dataIndex: 'powerScore',
            key: 'powerScore',
            width: 200,
        },
        {
            title: 'Speed Score',
            dataIndex: 'speedScore',
            key: 'speedScore',
            width: 200,
        },
        {
            title: 'Endurance Score',
            dataIndex: 'enduranceScore',
            key: 'enduranceScore',
            width: 200,
        },
        {
            title: 'Intelligence Score',
            dataIndex: 'intelligenceScore',
            key: 'intelligenceScore',
            width: 200,
        },
        {
            title: 'Total Score',
            dataIndex: 'totalScore',
            key: 'totalScore',
            width: 200,
        },
    ]

    return (
        <>
            <Header title='Crypto Unicorn Searcher' />
            <Form
                form={form}
                layout="horizontal"
                name="advanced_search"
                className="ant-advanced-search-form"
                onFinish={onFinish}
                style={{marginTop: '-30px'}}
            >
                <Row gutter={40}>
                    <Col xs={24}>
                        <div className={styles.fieldsWrapper}>
                            <div style={{width: '100%'}}>
                                <label className="white"><b>Search By ID</b></label>
                                <Form.Item
                                    name={`ID`}
                                    rules={[
                                        {
                                            required: false,
                                            message: 'Input something!',
                                        },
                                    ]}
                                >
                                    <Input name="id" onKeyUpCapture={(e) => searchById(e.target.value)} style={{width: '18%'}} />
                                </Form.Item>
                            </div>
                            <div>
                                <label className="white">Class</label>
                                <Form.Item
                                    name={`Class`}
                                    rules={[
                                        {
                                            required: false,
                                            message: 'Input something!',
                                        },
                                    ]}
                                >
                                    <Select  showSearch mode="multiple" name="class" placeholder="Please choose Class" allowClear>
                                        {
                                            data && data.CryptoUnicornsParametersQuery && data.CryptoUnicornsParametersQuery.unicornClass.map((item, key) => <Option key={key} value={item.enum}>{item.value}</Option>)
                                        }
                                    </Select>
                                </Form.Item>
                            </div>
                            <div>
                                <label className="white">Mythic</label>
                                <Form.Item
                                    name={`Mythic`}
                                    rules={[
                                        {
                                            required: false,
                                            message: 'Input something!',
                                        },
                                    ]}
                                >
                                    <Select  showSearch mode="multiple" name="mythic" placeholder="Please choose Mythic" allowClear>
                                        {
                                            data && data.CryptoUnicornsParametersQuery && data.CryptoUnicornsParametersQuery.mythic.map((item, key) => <Option key={key} value={item.enum}>{item.value}</Option>)
                                        }
                                    </Select>
                                </Form.Item>
                            </div>    
                            <div>
                                <label className="white">Body</label>
                                <Form.Item
                                    name={`Body`}
                                    rules={[
                                        {
                                            required: false,
                                            message: 'Input something!',
                                        },
                                    ]}
                                >
                                    <Select  showSearch mode="multiple" name="body" placeholder="Please choose Body" allowClear>
                                        {
                                            data && data.CryptoUnicornsParametersQuery && data.CryptoUnicornsParametersQuery.body.map((item, key) => <Option key={key} value={item.enum}>{item.value}</Option>)
                                        }
                                    </Select>
                                </Form.Item>
                            </div>
                            <div>
                                <label className="white">Face</label>
                                <Form.Item
                                    name={`Face`}
                                    rules={[
                                        {
                                            required: false,
                                            message: 'Input something!',
                                        },
                                    ]}

                                >
                                    <Select  showSearch mode="multiple" name="face" placeholder="Please choose Face" allowClear>
                                        {
                                            data && data.CryptoUnicornsParametersQuery && data.CryptoUnicornsParametersQuery.face.map((item, key) => <Option key={key} value={item.enum}>{item.value}</Option>)
                                        }
                                    </Select>
                                </Form.Item>
                            </div>
                            <div style={{paddingLeft: '20px'}}>
                                <label className="white">Hooves</label>
                                <Form.Item
                                    name={`Hooves`}
                                    rules={[
                                        {
                                            required: false,
                                            message: 'Input something!',
                                        },
                                    ]}

                                >
                                    <Select  showSearch mode="multiple" name="hooves" placeholder="Please choose Hooves" allowClear>
                                        {
                                            data && data.CryptoUnicornsParametersQuery && data.CryptoUnicornsParametersQuery.hooves.map((item, key) => <Option key={key} value={item.enum}>{item.value}</Option>)
                                        }
                                    </Select>
                                </Form.Item>
                            </div>
                            <div>
                                <label className="white">Horn</label>
                                <Form.Item
                                    name={`Horn`}
                                    rules={[
                                        {
                                            required: false,
                                            message: 'Input something!',
                                        },
                                    ]}

                                >
                                    <Select  showSearch mode="multiple" name="horn" placeholder="Please choose Horn" allowClear>
                                        {
                                            data && data.CryptoUnicornsParametersQuery && data.CryptoUnicornsParametersQuery.horn.map((item, key) => <Option key={key} value={item.enum}>{item.value}</Option>)
                                        }
                                    </Select>
                                </Form.Item>
                            </div>
                            <div>
                                <label className="white">Mane</label>
                                <Form.Item
                                    name={`Mane`}
                                    rules={[
                                        {
                                            required: false,
                                            message: 'Input something!',
                                        },
                                    ]}
                                >
                                    <Select  name="mane" showSearch mode="multiple" placeholder="Please choose Mane" allowClear>
                                        {
                                            data && data.CryptoUnicornsParametersQuery && data.CryptoUnicornsParametersQuery.mane.map((item, key) => <Option key={key} value={item.enum}>{item.value}</Option>)
                                        }
                                    </Select>
                                </Form.Item>
                            </div>
                            <div>
                                <label className="white">Tail</label>
                                <Form.Item
                                    name={`Tail`}
                                    rules={[
                                        {
                                            required: false,
                                            message: 'Input something!',
                                        },
                                    ]}

                                >
                                    <Select  showSearch mode="multiple" name="tail" placeholder="Please choose tail" allowClear>
                                        {
                                            data && data.CryptoUnicornsParametersQuery && data.CryptoUnicornsParametersQuery.tail.map((item, key) => <Option key={key} value={item.enum}>{item.value}</Option>)
                                        }
                                    </Select>
                                </Form.Item>
                            </div>
                            <div>
                                <label className="white">
                                    BREEDING POINTS (<i style={{color: "#FFB70F"}}>{breedingpoints}</i>)
                                </label>
                                <Slider name="breedingpoints"  max={8} value={breedingpoints} defaultValue={breedingpoints} style={{ width: '80%' }} onChange={(e) => {setValue(e, 'breedingpoints')}}/>
                            </div>
                            <div style={{paddingLeft: '20px'}}>
                                <label className="white">Sort</label>
                                <Form.Item
                                    name={`Sort`}
                                    rules={[
                                        {
                                            required: false,
                                            message: 'Input something!',
                                        },
                                    ]}
                                    initialValue={sort.current}
                                >
                                    <Select name="sort" placeholder="Sort">
                                        <Option value="HIGHEST_BREED_POINTS">HIGHEST BREED POINTS</Option>
                                        <Option value="LOWEST_BREED_POINTS">LOWEST BREED POINTS</Option>
                                        <Option value="HIGHEST_SCORE">HIGHEST SCORE</Option>
                                        <Option value="LOWEST_SCORE">LOWEST SCORE</Option>
                                    </Select>
                                </Form.Item>
                            </div>

                            <div>
                                <label className="white">
                                    POWER (Accuracy + Attack) <br />
                                </label>
                                <br/>
                                <table cellPadding={2} cellSpacing={0}>
                                    <tbody>
                                        <tr>
                                            <td className="white">
                                                Min (<i style={{color: "#FFB70F"}}>188</i>)
                                                <Form.Item name={`power_min`} >
                                                    <Input defaultValue={power[0]} name="power_min"  type={'number'} value={power[0]} onChange={(e) => {setValue(e.target.value, 'power', 0)}}/>
                                                </Form.Item>
                                            </td>
                                            <td className="white">
                                                Max (<i style={{color: "#FFB70F"}}>644</i>)
                                                <Form.Item name={`power_max`} >
                                                    <Input defaultValue={power[1]} name="power_max"  type={'number'} value={power[1]} onChange={(e) => {setValue(e.target.value, 'power', 1)}}/>
                                                </Form.Item>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                            <div>
                                <label className="white">
                                    SPEED (Attack Speed  + Movement Speed)
                                </label>
                                <table cellPadding={2} cellSpacing={0}>
                                    <tbody>
                                        <tr>
                                            <td className="white">
                                                Min (<i style={{color: "#FFB70F"}}>188</i>)
                                                <Form.Item name={`speed_min`} >
                                                    <Input defaultValue={speed[0]} name="speed_min"  type={'number'} value={speed[0]} onChange={(e) => {setValue(e.target.value, 'speed', 0)}}/>
                                                </Form.Item>
                                            </td>
                                            <td className="white">
                                                Max (<i style={{color: "#FFB70F"}}>696</i>)
                                                <Form.Item name={`speed_max`} >
                                                    <Input defaultValue={speed[1]} name="speed_max"  type={'number'} value={speed[1]} onChange={(e) => {setValue(e.target.value, 'speed', 1)}}/>
                                                </Form.Item>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                            <div>
                                <label className="white">
                                    ENDURANCE (Defense + Vitality)<br/><br/>
                                </label>
                                <table cellPadding={2} cellSpacing={0}>
                                    <tbody>
                                        <tr>
                                            <td className="white">
                                                Min (<i style={{color: "#FFB70F"}}>188</i>)
                                                <Form.Item name={`endurance_min`} >
                                                    <Input defaultValue={endurance[0]} name="endurance_min"  type={'number'} value={endurance[0]} onChange={(e) => {setValue(e.target.value, 'endurance', 0)}}/>
                                                </Form.Item>
                                            </td>
                                            <td className="white">
                                                Max (<i style={{color: "#FFB70F"}}>677</i>)
                                                <Form.Item name={`endurance_max`} >
                                                    <Input defaultValue={endurance[1]} name="endurance_max"  type={'number'} value={endurance[1]} onChange={(e) => {setValue(e.target.value, 'endurance', 1)}}/>
                                                </Form.Item>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                            <div>
                                <label className="white">
                                    INTELLIGENCE (Magic + Resistance)<br/>
                                </label>
                                <table cellPadding={2} cellSpacing={0}>
                                    <tbody>
                                            <tr>
                                            <td className="white">
                                                Min (<i style={{color: "#FFB70F"}}>188</i>)
                                                <Form.Item name={`intelligence_min`} >
                                                    <Input defaultValue={intelligence[0]} name="intelligence_min"  type={'number'} value={intelligence[0]} onChange={(e) => {setValue(e.target.value, 'intelligence', 0)}}/>
                                                </Form.Item>
                                            </td>
                                            <td className="white">
                                                Max (<i style={{color: "#FFB70F"}}>575</i>)
                                                <Form.Item name={`intelligence_max`} >
                                                    <Input defaultValue={intelligence[1]} name="intelligence_max"  type={'number'} value={intelligence[1]} onChange={(e) => {setValue(e.target.value, 'intelligence', 1)}}/>
                                                </Form.Item>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                            <div style={{paddingLeft: '20px'}}>
                                <label className="white">LIFECYCLE</label>
                                <br/><br/><br/>
                                <Form.Item
                                    name={`Lifecycle`}
                                    rules={[
                                        {
                                            required: false,
                                            message: 'Input something!',
                                        },
                                    ]}

                                >
                                    <Select  name="Lifecycle" showSearch mode="multiple" placeholder="Please choose Lifecycle" allowClear>
                                        {
                                            data && data.CryptoUnicornsParametersQuery && data.CryptoUnicornsParametersQuery.lifecycle.map((item, key) => <Option key={key} value={item.enum}>{item.value}</Option>)
                                        }
                                    </Select>
                                </Form.Item>
                            </div>
                            
                            <div style={{"marginTop": "20px"}}>
                                <label className="white">
                                    ACCURACY
                                </label>
                                <table cellPadding={2} cellSpacing={0}>
                                    <tbody>
                                        <tr>
                                            <td className="white">
                                                Min (<i style={{color: "#FFB70F"}}>94</i>)
                                                <Form.Item name={`accuracy_min`} >
                                                    <Input defaultValue={accuracy[0]} name="accuracy_min"  type={'number'} value={accuracy[0]} onChange={(e) => {setValue(e.target.value, 'accuracy', 0)}}/>
                                                </Form.Item>
                                            </td>
                                            <td className="white">
                                                Max (<i style={{color: "#FFB70F"}}>281</i>)
                                                <Form.Item name={`accuracy_max`} >
                                                    <Input  defaultValue={accuracy[1]} name="accuracy_max"  type={'number'} value={accuracy[1]} onChange={(e) => {setValue(e.target.value, 'accuracy', 1)}}/>
                                                </Form.Item>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                            <div style={{"marginTop": "20px"}}>
                                <label className="white">
                                    ATTACK SPEED 
                                </label>
                                <table cellPadding={2} cellSpacing={0}>
                                    <tbody>
                                        <tr>
                                            <td className="white">
                                                Min (<i style={{color: "#FFB70F"}}>94</i>)
                                                <Form.Item name={`attack_speed_min`} >
                                                    <Input defaultValue={attackspeed[0]} name="attack_speed_min"  type={'number'} value={attackspeed[0]} onChange={(e) => {setValue(e.target.value, 'attackspeed', 0)}}/>
                                                </Form.Item>
                                            </td>
                                            <td className="white">
                                                Max (<i style={{color: "#FFB70F"}}>348</i>)
                                                <Form.Item name={`attack_speed_max`} >
                                                    <Input  defaultValue={attackspeed[1]} name="attack_speed_max"  type={'number'} value={attackspeed[1]} onChange={(e) => {setValue(e.target.value, 'attackspeed', 1)}}/>
                                                </Form.Item>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                            <div style={{"marginTop": "20px"}}>
                                <label className="white">
                                    DEFENSE
                                </label>
                                <table cellPadding={2} cellSpacing={0}>
                                    <tbody>
                                        <tr>
                                            <td className="white">
                                                Min (<i style={{color: "#FFB70F"}}>94</i>)
                                                <Form.Item name={`defense_min`} >
                                                    <Input defaultValue={defense[0]} name="defense_min"  type={'number'} value={defense[0]} onChange={(e) => {setValue(e.target.value, 'defense', 0)}}/>
                                                </Form.Item>
                                            </td>
                                            <td className="white">
                                                Max (<i style={{color: "#FFB70F"}}>295</i>)
                                                <Form.Item name={`defense_max`} >
                                                    <Input defaultValue={defense[1]} name="defense_max"  type={'number'} value={defense[1]} onChange={(e) => {setValue(e.target.value, 'defense', 1)}}/>
                                                </Form.Item>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                            <div style={{"marginTop": "20px"}}>
                                <label className="white">
                                    MAGIC
                                </label>
                                <table cellPadding={2} cellSpacing={0}>
                                    <tbody>
                                        <tr>
                                            <td className="white">
                                                Min (<i style={{color: "#FFB70F"}}>94</i>)
                                                <Form.Item name={`magic_min`} >
                                                    <Input defaultValue={magic[0]} name="magic_min"  type={'number'} value={magic[0]} onChange={(e) => {setValue(e.target.value, 'magic', 0)}}/>
                                                </Form.Item>
                                            </td>
                                            <td className="white">
                                                Max (<i style={{color: "#FFB70F"}}>288</i>)
                                                <Form.Item name={`magic_max`} >
                                                    <Input defaultValue={magic[1]} name="magic_max"  type={'number'} value={magic[1]} onChange={(e) => {setValue(e.target.value, 'magic', 1)}}/>
                                                </Form.Item>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                            <div style={{"marginTop": "20px", "paddingLeft": "20px"}}>
                                <label className="white">
                                    TOTAL SCORE<br/>
                                </label>
                                <table cellPadding={2} cellSpacing={0}>
                                    <tbody>
                                        <tr>
                                            <td className="white">
                                                Min
                                                <Form.Item
                                                    name={`total_score_min`}
                                                >
                                                    <Input  type={'number'} name="total_score_min" onChange={(e) => setValueMinMax(e.target.value, 'min') } />
                                                </Form.Item>
                                            </td>
                                            <td className="white">
                                                Max
                                                <Form.Item
                                                    name={`total_score_max`}
                                                >
                                                <Input  type={'number'} name="total_score_max" onChange={(e) => setValueMinMax(e.target.value, 'max') } />
                                                </Form.Item>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                            
                            <div style={{"marginTop": "20px"}}>
                                <label className="white">
                                    ATTACK
                                </label>
                                <table cellPadding={2} cellSpacing={0}>
                                    <tbody>
                                        <tr>
                                            <td className="white">
                                                Min (<i style={{color: "#FFB70F"}}>94</i>)
                                                <Form.Item name={`attack_min`} >
                                                    <Input defaultValue={attack[0]} name="attack_min"  type={'number'} value={attack[0]} onChange={(e) => {setValue(e.target.value, 'attack', 0)}}/>
                                                </Form.Item>
                                            </td>
                                            <td className="white">
                                                Max (<i style={{color: "#FFB70F"}}>363</i>)
                                                <Form.Item name={`attack_max`} >
                                                    <Input defaultValue={attack[1]} name="attack_max"  type={'number'} value={attack[1]} onChange={(e) => {setValue(e.target.value, 'attack', 1)}}/>
                                                </Form.Item>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                            <div style={{"marginTop": "20px"}}>
                                <label className="white">
                                    MOVEMENT
                                </label>
                                <table cellPadding={2} cellSpacing={0}>
                                    <tbody>
                                        <tr>
                                            <td className="white">
                                                Min (<i style={{color: "#FFB70F"}}>94</i>)
                                                <Form.Item name={`movement_min`} >
                                                    <Input defaultValue={movement[0]} name="movement_min"  type={'number'} value={movement[0]} onChange={(e) => {setValue(e.target.value, 'movement', 0)}}/>
                                                </Form.Item>
                                            </td>
                                            <td className="white">
                                                Max (<i style={{color: "#FFB70F"}}>348</i>)
                                                <Form.Item name={`movement_max`} >
                                                    <Input defaultValue={movement[1]} name="movement_max"  type={'number'} value={movement[1]} onChange={(e) => {setValue(e.target.value, 'movement', 1)}}/>
                                                </Form.Item>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                            <div style={{"marginTop": "20px"}}>
                                <label className="white">
                                    VITALITY
                                </label>
                                <table cellPadding={2} cellSpacing={0}>
                                    <tbody>
                                        <tr>
                                            <td className="white">
                                                Min (<i style={{color: "#FFB70F"}}>94</i>)
                                                <Form.Item name={`vitality_min`} >
                                                    <Input defaultValue={vitality[0]} name="vitality_min"  type={'number'} value={vitality[0]} onChange={(e) => {setValue(e.target.value, 'vitality', 0)}}/>
                                                </Form.Item>
                                            </td>
                                            <td className="white">
                                                Max (<i style={{color: "#FFB70F"}}>382</i>)
                                                <Form.Item name={`vitality_max`} >
                                                    <Input  defaultValue={vitality[1]} name="vitality_max"  type={'number'} value={vitality[1]} onChange={(e) => {setValue(e.target.value, 'vitality', 1)}}/>
                                                </Form.Item>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                            <div style={{"marginTop": "20px"}}>
                                <label className="white">
                                    RESISTANCE
                                </label>
                                <table cellPadding={2} cellSpacing={0}>
                                    <tbody>
                                        <tr>
                                            <td className="white">
                                                Min (<i style={{color: "#FFB70F"}}>94</i>)
                                                <Form.Item name={`resistance_min`} >
                                                    <Input defaultValue={resistance[0]} name="resistance_min"  type={'number'} value={resistance[0]} onChange={(e) => {setValue(e.target.value, 'resistance', 0)}}/>
                                                </Form.Item>
                                            </td>
                                            <td className="white">
                                                Max (<i style={{color: "#FFB70F"}}>287</i>)
                                                <Form.Item name={`resistance_max`} >
                                                    <Input defaultValue={resistance[1]} name="resistance_max"  type={'number'} value={resistance[1]} onChange={(e) => {setValue(e.target.value, 'resistance', 1)}}/>
                                                </Form.Item>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                            <div>&nbsp;</div>

                        </div>
                    </Col>
                </Row>

                <Row gutter={40}>
                    <Col
                        span={24}
                        style={{
                            textAlign: 'right',
                        }}
                    >
                        <Button type="primary" className="btn btn-info" disabled={loading} htmlType="submit">Search</Button>
                        <Button type="default" className="btn" 
                            onClick={() => {
                                form.resetFields();
                                onReset()
                            }}
                            style={{
                                margin: '0 8px',
                            }}
                        >Clear</Button>
                    </Col>
                </Row>
            </Form>

            <Row gutter={40}>
                <Col xs={24} xl={24}>
                    <br />
                    {/*<Pagination size="small" onChange={pageChange} itemRender={itemRender} total={!loading && searchResults && searchResults.SearchAxiesR1R2Query.results.length}/>*/}
                    <Table
                        className="table"
                        columns={columns}
                        pagination={true}
                        dataSource={searchResults.length > 0 && searchResults}
                        // onChange={onChangeTable}
                        loading={loading}
                        rowKey="id"
                    />
                </Col>
            </Row>
        </>
    )
}

export default CyptoUnicornSearcher