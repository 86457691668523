import React, {useRef, useState, Fragment, useEffect} from 'react';
import { Typography, Row, Col, Button, Tag, Tabs, Select, Radio, Form, Input, notification, Checkbox, Slider } from 'antd';
import { useQuery } from "@apollo/client";
import { SearchParametersQuery } from '../../graphql/queries';
import { ProfitabilityCalculatorQueryB } from '../../graphql/axios';
import { BankOutlined, UsergroupAddOutlined, LoadingOutlined } from '@ant-design/icons';
import { isEmpty } from 'lodash';
import Header from '../../components/Header/Header'
import styles from './calculator.module.scss';

const { Title } = Typography;
const { Option } = Select;
const { TabPane } = Tabs;

const Profitability = (props) => {
  const [form] = Form.useForm();
  const { data } = useQuery(SearchParametersQuery);
  const [isPreOriginChecked, setIsPreOriginChecked] = useState(true);
  const isPreOriginStat = useRef(true);
  const [health, setHealth] = useState([27,61]);
  const [moral, setMoral] = useState([27,61]);
  const [speed, setSpeed] = useState([27,61]);
  const [skill, setSkill] = useState([27,61]);

  const input = useRef({
      'preOrigin': isPreOriginChecked,
      'eyesClass': '',
      'earsClass': '',
      'eyes': '',
      'ears': '',
      'back': '',
      'horn': '',
      'mouth': '',
      'tail': '',
      'axieClass': '',
      'threshold': 93.77,
      'sellStrategy': 'MARKET',
      'desiredTraitsSellingPrice': 0,
      'percentToProduceDesirableTraits': 25,
      'percentToProducePrinter': 45,
      'percentToProduceFloor': 100 - (45 +  25),
      'numberOfAxiesToBuy': 0,
      'generation': 13,
      'skill': [27,61],
      'moral': [27,61],
      'health': [27,61],
      'speed': [27,61],
  });
  const [produceFloor, setProduceFloor] = useState(100 - (45 +  25));
  const [desiredTraitsSellingPrice, setDesiredTraitsSellingPrice] = useState(false);
  const [loading, setLoading] = useState(false);
  const [resultData, setResultData] = useState(false);
  const [resultMode, setResultMode] = useState(false);
  const [activeTab, setActiveTab] = useState("1");
  const [breedCount, setBreedCount] = useState(1);
  const onKeyChange = (key) => setActiveTab(key)
  
  // const [csvData, setCsvData] = useState([]);

  const showLoading = () => {
      setLoading(true);
  }

  const stopLoading = () => {
      setLoading(false);
  }
  
  const setValue = (value, a) => {
      input.current[a] = value;

      if(a === 'sellStrategy') {
          if(value === 'GUILD') setDesiredTraitsSellingPrice(true);
          else setDesiredTraitsSellingPrice(false);
      }

      if(a === 'percentToProducePrinter' || a === 'percentToProduceDesirableTraits') {
          const val = isNaN(value) || isEmpty(value) ? 0 : value;
          input.current[a] = val;
  
          const total = parseInt(input.current['percentToProducePrinter']) +  parseInt(input.current['percentToProduceDesirableTraits']);
          setProduceFloor(100 - total);
          input.current['percentToProduceFloor'] = 100 - total;
          
          if(total > 99) {
              notification['error']({
                  message: 'Inout error.',
                  description:
                    'Total of Produce Desirable Traits and Produce Printer should not exceed to 99%.',
              });
          }
      }
      const stats = ["health", "moral", "skill", "speed"];

      if(stats.includes(a)) {
          if(a === 'health') setHealth(value);
          if(a === 'moral') setMoral(value);
          if(a === 'skill') setSkill(value);
          if(a === 'speed') setSpeed(value);
      }
  }  

  const requestResult = (e) => {
      if(produceFloor <= 0) {
          notification['error']({
              message: 'Input error.',
              description:
                'Total of Produce Desirable Traits and Produce Printer should not exceed to 99%.',
          });
      }
      else
      {
          showLoading();

          ProfitabilityCalculatorQueryB(input.current).then((response) => {
              const mappedData = response?.data?.data?.ProfitabilityCalculatorQuery ? response?.data?.data?.ProfitabilityCalculatorQuery : false;
              setResultData(mappedData);

              if(response?.data?.data?.ProfitabilityCalculatorQuery !== null) {
                  
                  notification['success']({
                      message: 'Data has been fetched.',
                      description:
                      'Results has been gathered.',
                  });

                  if(mappedData['message']) {
                    openNotification(mappedData['message']);
                  }

                  setActiveTab("3");
                  stopLoading();
                  setResultMode(true);
                  setBreedCount("1")
                  // setProduceFloor(0);
                  // input.current['percentToProducePrinter'] = 0;
                  // input.current['percentToProduceDesirableTraits'] = 0;
                  // console.log("resultData: ", resultData);
              }
              else
              {
                const message = response?.data?.errors[0].message;
                
                notification['error']({
                    message: 'Result Error.',
                    description: message,
                });

                stopLoading();
              }
          });
      }
  }

  // custom close button
  const openNotification = (message) => {
    const key = `open${Date.now()}`;
    const btn = (
      <Button type="primary" size="small" onClick={() => notification.close(key)}>
        Confirm
      </Button>
    );
    notification.open({
      type: 'info',
      message: message,
      description: '',
      duration: 0,
      btn,
      key,
      // onClose: closeNotif,
    });
  }
  // end custom close button

  const resetMode = () => {
      setResultMode(false);
  }

  // const exportCSV = () => {
  //     let midway = ` ,Breed Count 1, Breed Count 2, Breed Count3`;
  //     for(let i in resultData.midwayResults) {
  //         midway += ` ,Desired,${resultData.midwayResults[i].desirable[0]},${resultData.midwayResults[i].desirable[1]},${resultData.midwayResults[i].desirable[2]}`;
  //         midway += `${i + 1},Printer,${resultData.midwayResults[i].printer[0]},${resultData.midwayResults[i].printer[1]},${resultData.midwayResults[i].printer[2]}`;
  //         midway += ` ,Floor,${resultData.midwayResults[i].floor[0]},${resultData.midwayResults[i].floor[1]},${resultData.midwayResults[i].floor[2]}`;
  //     }

  //     data = json2csv(midway);
  //     setCsvData(data);
  // }

  const midwayResultsHeader = [];
  for (let index = 1; index < Object.keys(resultData).length - 1; index++) {
    midwayResultsHeader.push(<td className={`noBorder`} key={`head-${index}`} width="140">Breed Count {index}</td>)
  }

  const BreedCountSelector = () => {
    return <Select shouldUpdate className='ant-select-custom-color breedCount' name="breedCount" placeholder='Breed Count' defaultValue={breedCount} value={breedCount} onChange={value => {setBreedCount(String(value))}}>
      <Option value="1">1</Option>
      <Option value="2">2</Option>
      <Option value="3">3</Option>
      <Option value="4">4</Option>
      <Option value="5">5</Option>
      <Option value="6">6</Option>
      <Option value="7">7</Option>
    </Select>
  }

  const isPreOrigin = (e) => {
    setIsPreOriginChecked(e.target.checked);
    isPreOriginStat.current = e.target.checked;
    input.current['preOrigin'] = e.target.checked;
}

  useEffect(function () {
    form.setFieldsValue({
      ['breedCount']: breedCount,
    })
  }, [breedCount]);

  return(
    <>
      <Header title='Profitability Calculator' description={'Gauge how much profit you will generate from our newest tool, designed for you.'} />

      {
        (loading) ? 
        <>
            <div className="text-center">
                <LoadingOutlined style={{ fontSize: '50px'}} theme="outlined" className="text-center"  /><br />
                Initializing Results
            </div>
        </> :
        <Tabs defaultActiveKey="1" className='results' activeKey={activeTab} onChange={onKeyChange}>
          <TabPane tab="Options" key="1">
            <Form
              form={form}
              layout="horizontal"
              onFinish={requestResult}
            >
              <Row gutter={40}>
                <Col xs={24}>
                <div className="fieldsWrapper">
                  <div>
                    <Checkbox defaultChecked onChange={isPreOrigin} style={{color: "#fff"}} checked={isPreOriginChecked}>Select Pre-origin version</Checkbox>
                    <br />&nbsp;
                  </div>
                </div>
                </Col>
                <Col xs={24}>
                  <div className="fieldsWrapper">

                    {/* Eyes Class starts here */}
                    {isPreOriginChecked ? 
                    <>
                      <div>
                        <label className="white">Eyes Class</label>
                        <Form.Item
                          name='eyesClass'
                          initialValue={''}
                          rules={[
                            {
                              required: false,
                              message: 'Input something!',
                            },
                          ]}
                        >
                          <Select defaultValue={""} showSearch className='ant-select-custom-color' allowClear name="eyesClass" style={{ width: '100%' }} placeholder='Select Eyes Class' required onChange={(e) => {setValue(e, 'eyesClass')}}>
                            <Option value="">Select Eyes Class</Option>
                            {
                              data?.SearchParametersQuery && data?.SearchParametersQuery.classes.filter(i=> !["Dusk","Dawn","Mech"].includes(i.name)).map((item, key) => <Option key={key} value={item.name.toUpperCase()}>{item.name}</Option>)
                            }
                          </Select>
                        </Form.Item>
                      </div>
                      {/* Eyes Class ends here */}

                      {/* Ears Class starts here */}
                      <div>
                        <label className="white">Ears Class</label>
                        <Form.Item
                          name='earsClass'
                          initialValue={''}
                          rules={[
                            {
                              required: false,
                              message: 'Input something!',
                            },
                          ]}
                        >
                          <Select showSearch allowClear name="earsClass" style={{ width: '100%' }} placeholder='Select Ears Class' required onChange={(e) => {setValue(e, 'earsClass')}}>
                            <Option value="">Select Ears Class</Option>
                            {
                              data?.SearchParametersQuery && data?.SearchParametersQuery.classes.filter(i=> !["Dusk","Dawn","Mech"].includes(i.name)).map((item, key) => <Option key={key} value={item.name.toUpperCase()}>{item.name}</Option>)
                            }
                          </Select>
                        </Form.Item>
                      </div>
                      {/* Ears Class ends here */}
                    </> : 
                    <>
                      {/* Eyes starts here */}
                      <div>
                        <label className="white">Eyes</label>
                        <Form.Item
                          name='eyes'
                          rules={[
                            {
                              required: false,
                              message: 'Input something!',
                            },
                          ]}
                        >
                          <Select allowClear showSearch name="eyes" style={{ width: '100%' }} placeholder='Select Eyes' required onChange={(e) => {setValue(e, 'eyes')}}>
                            {
                              data?.SearchParametersQuery && data?.SearchParametersQuery?.eyes.map((item, key) => <Option key={key} value={item.partId}>{item.name}</Option>)
                            }
                          </Select>
                        </Form.Item>
                      </div>
                      {/* Eyes ends here */}

                      {/* Ears starts here */}
                      <div>
                        <label className="white">Ears</label>
                        <Form.Item
                          name='ears'
                          rules={[
                            {
                              required: false,
                              message: 'Input something!',
                            },
                          ]}
                        >
                          <Select allowClear showSearch name="ears" style={{ width: '100%' }} placeholder='Select Ears' required onChange={(e) => {setValue(e, 'ears')}}>
                            {
                              data?.SearchParametersQuery && data?.SearchParametersQuery?.ears.map((item, key) => <Option key={key} value={item.partId}>{item.name}</Option>)
                            }
                          </Select>
                        </Form.Item>
                      </div>
                      {/* Ears ends here */}
                    </>
                    }

                    {/* Mouth starts here */}
                    <div>
                      <label className="white">Mouth</label>
                      <Form.Item
                        name='mouth'
                        rules={[
                          {
                            required: true,
                            message: 'Input something!',
                          },
                        ]}
                      >
                        <Select  showSearch name="mouth" style={{ width: '100%' }} placeholder='Select Mouth' required onChange={(e) => {setValue(e, 'mouth')}}>
                          {
                            data?.SearchParametersQuery && data?.SearchParametersQuery?.mouth.map((item, key) => <Option key={key} value={item.partId}>{item.name}</Option>)
                          }
                        </Select>
                      </Form.Item>
                    </div>
                    {/* Mouth ends here */}

                    {/* Horn starts here */}
                    <div>
                      <label className="white">Horn</label>
                      <Form.Item
                        name='horn'
                        rules={[
                          {
                            required: true,
                            message: 'Input something!',
                          },
                        ]}
                      >
                        <Select  showSearch name="horn" style={{ width: '100%' }} placeholder='Select Horn' required onChange={(e) => {setValue(e, 'horn')}}>
                          {
                            data?.SearchParametersQuery && data?.SearchParametersQuery?.horn.map((item, key) => <Option key={key} value={item.partId}>{item.name}</Option>)
                          }
                        </Select>
                      </Form.Item>
                    </div>
                    {/* Horn ends here */}

                    {/* Back starts here */}
                    <div>
                      <label className="white">Back</label>
                      <Form.Item
                        name='back'
                        rules={[
                          {
                            required: true,
                            message: 'Input something!',
                          },
                        ]}
                      >
                        <Select  showSearch name="back" style={{ width: '100%' }} placeholder='Select Back' required onChange={(e) => {setValue(e, 'back')}}>
                          {
                            data?.SearchParametersQuery && data?.SearchParametersQuery?.back.map((item, key) => <Option key={key} value={item.partId}>{item.name}</Option>)
                          }
                        </Select>
                      </Form.Item>
                    </div>
                    {/* Back ends here */}

                    {/* Tail starts here */}
                    <div>
                      <label className="white">Tail</label>
                      <Form.Item
                        name='tail'
                        rules={[
                          {
                            required: true,
                            message: 'Input something!',
                          },
                        ]}
                      >
                        <Select  showSearch name="tail" style={{ width: '100%' }} placeholder='Select Tail' required onChange={(e) => {setValue(e, 'tail')}}>
                          {
                            data?.SearchParametersQuery && data?.SearchParametersQuery?.tail.map((item, key) => <Option key={key} value={item.partId}>{item.name}</Option>)
                          }
                        </Select>
                      </Form.Item>
                    </div>
                    {/* Tail ends here */}

                    {/* Class starts here */}
                    <div>
                      <label className="white">Desired Printer Axie Class</label>
                      <Form.Item
                        name='class'
                        rules={[
                          {
                            required: true,
                            message: 'Input something!',
                          },
                        ]}
                      >
                        <Select  showSearch name="class" style={{ width: '100%' }} placeholder='Select a value' required onChange={(e) => {setValue(e, 'axieClass')}} >
                          {
                            data?.SearchParametersQuery && data?.SearchParametersQuery?.classes.map((item, key) => <Option key={key} value={item.name.toUpperCase()}><img src={`/assets/images/class/${item.name.toLowerCase()}.png`} /> {item.name}</Option>)
                          }
                        </Select>
                      </Form.Item>
                    </div>
                    {/* Class ends here */}

                    {/* Threshold starts here */}
                    <div>
                      <label className="white">Printer Axie Threshold</label>
                      <Form.Item
                        name='threshold'
                        initialValue={93.77}
                        rules={[
                          {
                            required: true,
                            message: 'Input something!',
                          },
                        ]}
                      >
                        <Input  type="text" name="threshold" defaultValue={93.77} className='ant-input' placeholder="Enter a percentage" min={1} maxLength={5} onChange={(e) => setValue(e.target.value, 'threshold')} />
                      </Form.Item>
                    </div>
                    {/* Threshold ends here */}

                    {/* % to Produce Desirable Traits starts here */}
                    <div>
                      <label className="white">Desirable Traits %</label>
                      <Form.Item
                        name='percentToProduceDesirableTraits'
                        initialValue={25}
                        rules={[
                          {
                            required: true,
                            message: 'Input something!',
                          },
                        ]}
                      >
                        <Input  type="text" name="percentToProduceDesirableTraits" defaultValue={25} className='ant-input' placeholder="Input a value" min={1} maxLength={2} max={99} onChange={(e) => setValue(e.target.value, 'percentToProduceDesirableTraits')} />
                      </Form.Item>
                    </div>
                    {/* % to Produce Desirable Traits ends here */}

                    {/* % to Produce Printer starts here */}
                    <div>
                      <label className="white">% to Produce Printer</label>
                      <Form.Item
                        name='percentToProducePrinter'
                        initialValue={45}
                        rules={[
                          {
                            required: true,
                            message: 'Input something!',
                          },
                        ]}
                      >
                        <input  type="text" name="percentToProducePrinter" defaultValue={45} className='ant-input' placeholder="Enter a percentage" min={1} maxLength={2} max={99} onChange={(e) => setValue(e.target.value, 'percentToProducePrinter')} />
                      </Form.Item>
                    </div>
                    {/* % to Produce Printer ends here */}

                    {/* Number of Axies to Buy starts here */}
                    <div>
                      <label className="white">Number of Axies to Buy</label>
                      <Form.Item
                        name='numberOfAxiesToBuy'
                        rules={[
                          {
                            required: true,
                            message: 'Input something!',
                          },
                        ]}
                      >
                        <input  type="text" name="numberOfAxiesToBuy" className='ant-input' placeholder="Input a value" min={1} maxLength={3} onChange={(e) => setValue(e.target.value, 'numberOfAxiesToBuy')} />
                      </Form.Item>
                    </div>
                    {/* Number of Axies to Buy ends here */}

                    {/* % to Produce Floor starts here */}
                    <div>
                      <label className="white">% to Produce Floor</label>
                      <Title level={3} className="content-title">
                        {produceFloor} %
                      </Title>
                    </div>
                    {/* % to Produce Floor ends here */}

                    {/* Generation starts here */}
                    <div>
                      <label className="white">Generation</label>
                      <Form.Item
                        name='generation'
                        initialValue={13}
                        rules={[
                          {
                            required: true,
                            message: 'Input something!',
                          },
                        ]}
                      >
                        <input type="text" name="generation" defaultValue={13} className='ant-input' placeholder="Input a value" min={1} maxLength={13} onChange={(e) => setValue(e.target.value, 'generation')} />
                      </Form.Item>
                    </div>
                    {/* Generation ends here */}

                    {/* Sell Strategy starts here */}
                    <div>
                      <label className="white">Sell Strategy</label>
                      <Form.Item
                        name='sellStrategy'
                        rules={[
                          {
                            required: false,
                            message: 'Input something!',
                          },
                        ]}
                      >
                        <Radio.Group defaultValue="MARKET" buttonStyle="solid" onChange={(e) => setValue(e.target.value, 'sellStrategy')} required>
                            <Radio.Button value="MARKET" checked><BankOutlined />&nbsp;Market</Radio.Button>
                            <Radio.Button value="GUILD"><UsergroupAddOutlined />&nbsp;Guild</Radio.Button>
                        </Radio.Group>
                      </Form.Item>
                    </div>
                    {/* Sell Strategy ends here */}

                    {/* Desired Trait Selling Price starts here */}
                    {
                      desiredTraitsSellingPrice && (
                        <div>
                          <label className="white">Desired Trait Selling Price</label>
                          <Form.Item
                            name='desiredTraitsSellingPrice'
                            rules={[
                              {
                                required: true,
                                message: 'Input something!',
                              },
                            ]}
                          >
                            <input  type="text" name="desiredTraitsSellingPrice" className='ant-input' placeholder="Input a value" min={1} maxLength={3} onChange={(e) => setValue(e.target.value, 'desiredTraitsSellingPrice')} />
                          </Form.Item>
                        </div>
                      )
                    }
                    {/* Desired Trait Selling Price ends here */}

                  </div>
                </Col>
                <Col xs={24}>
                  <div className="fieldsWrapper">
                    <div>
                      <label className="white">
                          HEALTH (Min <i style={{color: "#FFB70F"}}>{health[0]}</i>, Max <i style={{color: "#FFB70F"}}>{health[1]}</i>)
                      </label>
                      <Slider range min={27} max={61} value={health} defaultValue={health} style={{ width: '80%' }} onChange={(e) => {setValue(e, 'health')}}/>
                      </div>
                      <div>
                      <label className="white">
                          SPEED (Min <i style={{color: "#FFB70F"}}>{speed[0]}</i>, Max <i style={{color: "#FFB70F"}}>{speed[1]}</i>)
                      </label>
                      <Slider range min={27} max={61} value={speed} defaultValue={speed} style={{ width: '80%' }} onChange={(e) => {setValue(e, 'speed')}}/>
                      </div>
                      <div>
                      <label className="white">
                          SKILL (Min <i style={{color: "#FFB70F"}}>{skill[0]}</i>, Max <i style={{color: "#FFB70F"}}>{skill[1]}</i>)
                      </label>
                      <Slider range min={27} max={61} value={skill} defaultValue={skill} style={{ width: '80%' }} onChange={(e) => {setValue(e, 'skill')}}/>
                      </div>
                      <div>
                      <label className="white">
                          MORALE (Min <i style={{color: "#FFB70F"}}>{moral[0]}</i>, Max <i style={{color: "#FFB70F"}}>{moral[1]}</i>)
                      </label>
                      <Slider range min={27} max={61} value={moral} defaultValue={moral} style={{ width: '80%' }} onChange={(e) => {setValue(e, 'moral')}}/>
                    </div>
                  </div>
                </Col>
              </Row>

              <Row gutter={40}>
                <Col xs={24} xl={24} className={`actionWrapper`}>
                  <Button type="primary" className="btn btn-info ant-btn-lg" disabled={loading} htmlType="submit">Submit</Button>
                </Col>
              </Row>
            </Form>
          </TabPane>
          {
            resultData && (
              <>
                <TabPane tab="Midway Results" key="2">
                  <Row gutter={40}>
                    <Col xs={12} xl={6}>
                        <Form.Item
                          name='breedCountA'
                          label='Breed Count'
                          initialValue={breedCount}
                          rules={[
                            {
                              required: true,
                              message: 'Input something!',
                            },
                          ]}
                        >
                          <BreedCountSelector />
                        </Form.Item>
                    </Col>
                  </Row>
                  <Row gutter={40}>
                    <Col xl={24}>
                      <table style={{color: '#fff'}} width={'99.9%'} className={`tblRes ${styles.midwayTable}`}>
                        <tbody>
                          <tr>
                            <td colSpan={2} className='noBorder' width="280"></td>
                            {midwayResultsHeader}
                          </tr>
                          {
                            resultData && resultData[`breed_cycle_${breedCount}`] && 
                            resultData[`breed_cycle_${breedCount}`].midwayResults.map((item, i) => 
                              <Fragment key={`res-${i}`}>
                                <tr>
                                  <td rowSpan={3} className='hasBorderAll' width="140" style={{maxWidth: "100px"}}>
                                    <p style={{fontSize: '30px', fontWeight: 'bold'}}>{i + 1}</p>
                                  </td>
                                  <td className={`hasBorder ${styles.hl}`} width="140">Desired</td>
                                  <td className={`hasBorder ${styles.bar} ${styles.hl}`} width="140">
                                    <Tag className='black-text'>
                                      {resultData[`breed_cycle_${breedCount}`]?.midwayResults[i].desirable}
                                    </Tag>
                                  </td>
                                  <td className={`hasBorder ${styles.bar} ${styles.hl}`} width="140">
                                    <Tag className='black-text'>
                                      {breedCount >= 2 ? resultData[`breed_cycle_${breedCount}`]?.midwayResults[i].desirable : 0}
                                    </Tag>
                                  </td>
                                  <td className={`hasBorder ${styles.bar} ${styles.hl}`} width="140">
                                    <Tag className='black-text'>
                                      {breedCount >= 3 ? resultData[`breed_cycle_${breedCount}`]?.midwayResults[i].desirable : 0}
                                    </Tag>
                                  </td>
                                  <td className={`hasBorder ${styles.bar} ${styles.hl}`} width="140">
                                    <Tag className='black-text'>
                                      {breedCount >= 4 ? resultData[`breed_cycle_${breedCount}`]?.midwayResults[i].desirable : 0}
                                    </Tag>
                                  </td>
                                  <td className={`hasBorder ${styles.bar} ${styles.hl}`} width="140">
                                    <Tag className='black-text'>
                                      {breedCount >= 5 ? resultData[`breed_cycle_${breedCount}`]?.midwayResults[i].desirable : 0}
                                    </Tag>
                                  </td>
                                  <td className={`hasBorder ${styles.bar} ${styles.hl}`} width="140">
                                    <Tag className='black-text'>
                                      {breedCount >= 6 ? resultData[`breed_cycle_${breedCount}`]?.midwayResults[i].desirable : 0}
                                    </Tag>
                                  </td>
                                  <td className={`hasBorderRight ${styles.bar} ${styles.hl}`} width="140">
                                    <Tag className='black-text'>
                                      {breedCount >= 7 ? resultData[`breed_cycle_${breedCount}`]?.midwayResults[i].desirable : 0}
                                    </Tag>
                                  </td>
                                </tr>
                                <tr>
                                  <td className={`hasBorder ${styles.hl}`} width="140">Printer</td>
                                  <td className={`hasBorder ${styles.bar} ${styles.hl}`} width="140">
                                    <Tag className='black-text'>
                                      {resultData[`breed_cycle_${breedCount}`]?.midwayResults[i].printer}
                                    </Tag>
                                  </td>
                                  <td className={`hasBorder ${styles.bar} ${styles.hl}`} width="140">
                                    <Tag className='black-text'>
                                      {breedCount >= 2 ? resultData[`breed_cycle_${breedCount}`]?.midwayResults[i].printer : 0}
                                    </Tag>
                                  </td>
                                  <td className={`hasBorder ${styles.bar} ${styles.hl}`} width="140">
                                    <Tag className='black-text'>
                                      {breedCount >= 3 ? resultData[`breed_cycle_${breedCount}`]?.midwayResults[i].printer : 0}
                                    </Tag>
                                  </td>
                                  <td className={`hasBorder ${styles.bar} ${styles.hl}`} width="140">
                                    <Tag className='black-text'>
                                      {breedCount >= 4 ? resultData[`breed_cycle_${breedCount}`]?.midwayResults[i].printer : 0}
                                    </Tag>
                                  </td>
                                  <td className={`hasBorder ${styles.bar} ${styles.hl}`} width="140">
                                    <Tag className='black-text'>
                                      {breedCount >= 5 ? resultData[`breed_cycle_${breedCount}`]?.midwayResults[i].printer : 0}
                                    </Tag>
                                  </td>
                                  <td className={`hasBorder ${styles.bar} ${styles.hl}`} width="140">
                                    <Tag className='black-text'>
                                      {breedCount >= 6 ? resultData[`breed_cycle_${breedCount}`]?.midwayResults[i].printer : 0}
                                    </Tag>
                                  </td>
                                  <td className={`hasBorderRight ${styles.bar} ${styles.hl}`} width="140">
                                    <Tag className='black-text'>
                                      {breedCount >= 7 ? resultData[`breed_cycle_${breedCount}`]?.midwayResults[i].printer : 0}
                                    </Tag>
                                  </td>
                                </tr>
                                <tr>
                                  <td className={`hasBorder ${styles.hl}`} width="140">Floor</td>
                                  <td className={`hasBorder ${styles.bar} ${styles.hl}`} width="140">
                                    <Tag className='black-text'>
                                      {resultData[`breed_cycle_${breedCount}`]?.midwayResults[i].floor}
                                    </Tag>
                                  </td>
                                  <td className={`hasBorder ${styles.bar} ${styles.hl}`} width="140">
                                    <Tag className='black-text'>
                                      {breedCount >= 2 ? resultData[`breed_cycle_${breedCount}`]?.midwayResults[i].floor : 0}
                                    </Tag>
                                  </td>
                                  <td className={`hasBorder ${styles.bar} ${styles.hl}`} width="140">
                                    <Tag className='black-text'>
                                      {breedCount >= 3 ? resultData[`breed_cycle_${breedCount}`]?.midwayResults[i].floor : 0}
                                    </Tag>
                                  </td>
                                  <td className={`hasBorder ${styles.bar} ${styles.hl}`} width="140">
                                    <Tag className='black-text'>
                                      {breedCount >= 4 ? resultData[`breed_cycle_${breedCount}`]?.midwayResults[i].floor : 0}
                                    </Tag>
                                  </td>
                                  <td className={`hasBorder ${styles.bar} ${styles.hl}`} width="140">
                                    <Tag className='black-text'>
                                      {breedCount >= 5 ? resultData[`breed_cycle_${breedCount}`]?.midwayResults[i].floor : 0}
                                    </Tag>
                                  </td>
                                  <td className={`hasBorder ${styles.bar} ${styles.hl}`} width="140">
                                    <Tag className='black-text'>
                                      {breedCount >= 6 ? resultData[`breed_cycle_${breedCount}`]?.midwayResults[i].floor : 0}
                                    </Tag>
                                  </td>
                                  <td className={`hasBorderRight ${styles.bar} ${styles.hl}`} width="140">
                                    <Tag className='black-text'>
                                      {breedCount >= 7 ? resultData[`breed_cycle_${breedCount}`]?.midwayResults[i].floor : 0}
                                    </Tag>
                                  </td>
                                </tr>
                              </Fragment>
                            )
                          }
                        </tbody>
                      </table>
                    </Col>
                  </Row>
                </TabPane>
                <TabPane tab="Total Computation" key="3">
                  <Row gutter={40}>
                    <Col xs={12} xl={6}>
                        <Form.Item
                          name='breedCountB'
                          label='Breed Count'
                          initialValue={breedCount}
                          rules={[
                            {
                              required: true,
                              message: 'Input something!',
                            },
                          ]}
                        >
                          <BreedCountSelector />
                        </Form.Item>
                    </Col>
                  </Row>
                  <Row gutter={40} style={{marginBottom: 60}}>
                    <Col xs={12} xl={24}>
                      <table width={'99.9%'} className='tblInv'>
                        <tbody>
                          <tr className='sndaryhead'>
                            <td width={'20%'}>
                              Most Profitable
                            </td>
                            <td width={'20%'}>
                              Profit
                            </td>
                            <td width={'20%'}>
                              Profit Margin
                            </td>
                          </tr>
                          {
                            resultData?.most_profitable && (
                              <tr className={`tbodyI ${styles.hlP}`}>
                                <td className={styles.hlC}>
                                  {resultData.most_profitable.name}
                                </td>
                                <td className={styles.hlC}>
                                  {resultData.most_profitable.profit?.toFixed(3).replace(/\d(?=(\d{3})+\.)/g, '$&,')}
                                </td>
                                <td className={styles.hlC}>
                                  {resultData.most_profitable.profitMargin?.toFixed(3).replace(/\d(?=(\d{3})+\.)/g, '$&,')}
                                </td>
                              </tr>
                            )
                          }
                        </tbody>
                      </table>
                    </Col>
                  </Row>
                  <Row gutter={40} style={{marginBottom: 40}}>
                    <Col xs={12} xl={24}>
                      <table width={'99.9%'} className='tblInv'>
                        <tbody>
                          <tr>
                            <td className='white'>
                              <p style={{fontSize: '18px'}}>Investments Total</p>
                            </td>
                            <td colSpan={2}>
                                
                            </td>
                            <td className='text-right white'>
                              <p style={{fontSize: '20px', fontWeight: 'bold'}}>${ resultData ? resultData[`breed_cycle_${breedCount}`].investments[resultData[`breed_cycle_${breedCount}`].investments.length - 1]?.total?.toFixed(3).replace(/\d(?=(\d{3})+\.)/g, '$&,') : '0'}</p>
                            </td>
                          </tr>
                          <tr className='sndaryhead'>
                            <td width={'20%'}>
                              INVESTMENTS
                            </td>
                            <td width={'20%'}>
                              QUANTITY
                            </td>
                            <td width={'20%'}>
                              $ / QUANTITY
                            </td>
                            <td width={'20%'}>
                              TOTAL
                            </td>
                          </tr>
                          {
                            resultData && resultData[`breed_cycle_${breedCount}`] && 
                            resultData[`breed_cycle_${breedCount}`].investments.map((item, i) => 
                              <tr className={`tbodyI ${styles.hlP}`} key={`t3-${i}`}>
                                <td className={styles.hlC}>
                                  {item.name}
                                </td>
                                <td className={styles.hlC}>
                                  {item.quantity?.toFixed(3).replace(/\d(?=(\d{3})+\.)/g, '$&,')}
                                </td>
                                <td className={styles.hlC}>
                                  {item.dollarQuantity?.toFixed(3).replace(/\d(?=(\d{3})+\.)/g, '$&,')}
                                </td>
                                <td className={styles.hlC}>
                                  {item.total?.toFixed(3).replace(/\d(?=(\d{3})+\.)/g, '$&,')}
                                </td>
                              </tr>
                            )
                          }
                        </tbody>
                      </table>
                    </Col>
                  </Row>
                  <Row gutter={40} style={{marginBottom: 10}}>
                    <Col xs={12} xl={24}>
                      <table width={'99.9%'} className='tblInv'>
                        <tbody>
                          <tr>
                            <td className='white'>
                              <p style={{fontSize: '18px'}}>Sales Total</p>
                            </td>
                            <td colSpan={2}>
                                
                            </td>
                            <td className='text-right white'>
                              <p style={{fontSize: '20px', fontWeight: 'bold'}}>${ resultData ? resultData[`breed_cycle_${breedCount}`].sales[resultData[`breed_cycle_${breedCount}`].sales.length - 1]?.total?.toFixed(3).replace(/\d(?=(\d{3})+\.)/g, '$&,') : '0'}</p>
                            </td>
                          </tr>
                          <tr className='sndaryhead'>
                            <td width={'20%'}>
                              INVESTMENTS
                            </td>
                            <td width={'20%'}>
                              QUANTITY
                            </td>
                            <td width={'20%'}>
                              $ / QUANTITY
                            </td>
                            <td width={'20%'}>
                              TOTAL
                            </td>
                          </tr>
                          {
                            resultData && resultData[`breed_cycle_${breedCount}`].sales && 
                            resultData[`breed_cycle_${breedCount}`].sales.map((item, i) => 
                              <tr className={`tbodyI ${styles.hlP}`} key={`t2-${i}`}>
                                <td className={styles.hlC}>
                                  {item.name}
                                </td>
                                <td className={styles.hlC}>
                                  {item.quantity?.toFixed(3).replace(/\d(?=(\d{3})+\.)/g, '$&,')}
                                </td>
                                <td className={styles.hlC}>
                                  {item.dollarQuantity?.toFixed(3).replace(/\d(?=(\d{3})+\.)/g, '$&,')}
                                </td>
                                <td className={styles.hlC}>
                                  {item.total?.toFixed(3).replace(/\d(?=(\d{3})+\.)/g, '$&,')}
                                </td>
                              </tr>
                            )
                          }
                        </tbody>
                    </table>
                    </Col>
                  </Row>
                  <Row gutter={40} style={{marginBottom: 60}}>
                    <Col xs={12} xl={24}>
                      <table width={'99.9%'} className='tblPr'>
                        <tbody>
                          <tr>
                            <td className='white hasBg' width={'23.5%'} style={{borderRadius: 0}}>
                              <p style={{fontSize: '15px'}}>Profit</p>
                            </td>
                            <td className='colorProfit text-right hasBg' width={'23.5%'} style={{borderRadius: 0}}>
                              <p style={{fontSize: '20px', fontWeight: 'bold'}}>${ resultData ? resultData[`breed_cycle_${breedCount}`].profit?.profit?.toFixed(3).replace(/\d(?=(\d{3})+\.)/g, '$&,') : '0'}</p>
                            </td>
                            <td width={'10%'}></td>
                            <td className='white hasBg' width={'23.5%'} style={{borderRadius: 0}}>
                              <p style={{fontSize: '15px'}}>Profit Margin</p>
                            </td>
                            <td className='text-right colorProfit hasBg' width={'23.5%'} style={{borderRadius: 0}}>
                              <p style={{fontSize: '20px', fontWeight: 'bold'}}>{ resultData ? resultData[`breed_cycle_${breedCount}`].profit?.profitMargin?.toFixed(3).replace(/\d(?=(\d{3})+\.)/g, '$&,') : '0'}%</p>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </Col>
                  </Row>
                </TabPane>
              </>
            )
          }
        </Tabs>
      }
    </>
  )
}

export default Profitability
