import React,{useState,useEffect,useMemo} from "react";
import {
  useMutation,
  useLazyQuery
} from "@apollo/client";
import { UserUpdateMutation, UserDeleteQuery } from "../graphql/queries";
import {Row, Col, Button, Drawer, Form, Input, Select, Space,message } from 'antd';
import _ from "lodash";

const { Option } = Select;

const UpdateUserDrawer = ({onClose,visible,id,username,ronin,role,refresh,parentUpdate,initialValue}) => {
  const [form] = Form.useForm();

  const [userData,setUserData] = useState({
    id:id,
    username:username,
    ronin:ronin,
    role:role
  });

  const [updateUser] = useMutation(UserUpdateMutation(userData.id,userData.username,userData.ronin,userData.role),{
    onCompleted: data => {
      if(data && data.UserUpdateMutation)
      {
        refresh()
        return message.success("Successfully Updated User ID:"+userData.id)
      }
    },
    onError: error =>
    {
        return message.error("Something went wrong please try again")
    }
  });
  const [deleteUser, {data,loading,error}] = useLazyQuery(UserDeleteQuery(userData.id));
  
  const formData = (event) => setUserData({
      ...userData,
      [event.target.name] : event.target.value
  });

  const selectRole = (value) => setUserData({
      ...userData,
      "role" : value
  });

  const updateUserbtn = () => {
    const {id,username,ronin,role} = form.getFieldsValue(true);
    parentUpdate(prev=>{
      _.find(prev,{id:id}).username = username
      _.find(prev,{id:id}).ronin = ronin
      _.find(prev,{id:id}).role = role.replace("_","-").toLowerCase();

      return prev
    })
    updateUser()
    onClose()
    return message.info("Updating User ID "+userData.id+"...")
  }

  const deleteUserbtn = () => {
    const {id} = form.getFieldsValue(true);
    parentUpdate(prev=>{
      _.remove(prev,{id:id})
     
      return prev
    })
    deleteUser()
    return message.info("Deleting User ID "+userData.id+ "....")
  }

  useEffect(()=>{
    form.setFieldsValue({
      id:id,
      username: username,
      role: role,
      ronin: ronin
    });
  },[id,username,role,ronin])

  useEffect(()=>{
    if(data && data.UserDeleteQuery)
    {
      refresh()
      onClose()
      return message.success("Successfully Deleted User ID:"+userData.id)
    }
  },[data,loading,error])

  const isDisabled = useMemo(()=>_.isEqual(userData,initialValue),[userData])
  return(
    <Drawer
      title="Update an account"
      width={720}
      onClose={onClose}
      visible={visible}
      bodyStyle={{ paddingBottom: 80 }}
      extra={
        <Space>
          <Button onClick={onClose}>Cancel</Button>
          <Button onClick={onClose} type="primary">
            Submit
          </Button>
        </Space>
      }
    >
    <Form layout="vertical" hideRequiredMark form={form}>
      {/*<Row gutter={16}>
        <Col span={24}>
          <Form.Item
            name="id"
            label="id"
            rules={[{ required: true, message: 'Please enter user name' }]}
          >
            <Input name="id" disabled={true}/>
          </Form.Item>
        </Col>
      </Row>*/}
      <Row gutter={16}>
        <Col span={24}>
          <Form.Item
            name="username"
            onChange={formData}
            label="Username"
            rules={[{ required: true, message: 'Please enter user name' }]}
          >
            <Input name="username" placeholder="Please enter username" />
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={16}>
        <Col span={24}>
          <Form.Item
            name="ronin"
            onChange={formData}
            label="Ronin"
            rules={[{ required: true, message: 'Please enter ronin address' }]}
          >
            <Input name="ronin" placeholder="Please enter ronin address E.G.: 123ABC13" />
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={16}>
        <Col span={24}>
          <Form.Item
            name="role"
            
            label="Role"
            rules={[{ required: true, message: 'Please choose role' }]}
          >
            <Select onChange={selectRole} name="role" placeholder="Please choose role">
              <Option value="BREEDER">Breeder</Option>
              <Option value="AUTHORIZER">Authorizer</Option>
              <Option value="SUPER_AUTHORIZER">Super Authorizer</Option>
            </Select>
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={16}>
        <Col span={24}>
            <Button onClick={deleteUserbtn} style={{ marginLeft:'1em',float:'right',background:'linear-gradient(272.64deg, #E03B3B 0%, #FC3535 33.61%, #FD4D4D 66.95%, #FF6D6D 100%)', border:'unset', color:'black'}}>
              DELETE USER
            </Button>
            <Button onClick={updateUserbtn} disabled={isDisabled} style={{float:'right',background:'linear-gradient(272.64deg, #3BE0D6 0%, #35B1FC 33.61%, #4D94FD 66.95%, #6D6DFF 100%)', border:'unset', color:'black'}}>
              UPDATE USER
            </Button>
        </Col>
      </Row>
    </Form>
  </Drawer>)
}

export default UpdateUserDrawer
