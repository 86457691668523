import React, { useState, useEffect } from "react";

import { Row, Col, Form, Input, Button, Select, Table, Slider } from 'antd';
import { ReactComponent as ExternalLinkIcon } from '../../images/icons/external-link.svg';
import styles from './NinnekoSearcher.module.scss';
import Header from '../../components/Header/Header'
import { NinnekoPartsQuery, NinnekoSearcherAxios } from '../../graphql/axios';


const { Option } = Select

const NinnekoSearcher = (props) => {
  const [loading, setLoading] = useState(false);
  const [searchResults, setSearchResults] = useState([]);
  const [form] = Form.useForm();
  const [parts, setParts] = useState(false);

  const showLoading = () => {
    setLoading(true);
  }

  const stopLoading = () => {
    setLoading(false);
  }

  const columns = [
    {
      title: 'ID',
      dataIndex: 'id',
      key: 'id',
      render: (text) => <a target="_blank" href={`https://market.ninneko.com/pet/${text}`}>
        {text}
        <ExternalLinkIcon style={{
          verticalAlign: 'middle',
          transform: 'translateY(-2px)',
          marginLeft: '5px',
        }} />
      </a>
    },
    {
      title: 'Name',
      dataIndex: 'name',
      key: 'name',
    },
    {
      title: 'Price BNB',
      dataIndex: 'priceBnb',
      key: 'priceBnb',
      width: 200,
    },
    {
      title: 'Price USD',
      dataIndex: 'priceUsd',
      key: 'priceUsd',
      width: 200,
    },
    {
      title: 'Breeder Algo %',
      dataIndex: 'purityScore',
      key: 'purityScore',
      width: 200,
      render: (n) => <div>{n.toFixed(2)}</div>,
    },
  ]

  const onFinish = (values) => {
    const data = {};

    // Check all fields. Set "Any" as default if nothing is selected.
    for (var value in values) {
      if (Object.prototype.hasOwnProperty.call(values, value)) {
        data[value] = values[value];
      }
    }
    
    showLoading()

    NinnekoSearcherAxios(data)
      .then((result) => {
        setSearchResults(result.data?.data?.SearchNinnekoQuery?.results);
        stopLoading()
      }
    );
  }

  const clear = () => {
    form.resetFields()
  }

  const sliderHandler = (value, name) => {
    form.setFieldsValue({
      [name]: value,
    })
  }

  useEffect(() => {
    NinnekoPartsQuery()
      .then((result) => {
        setParts(result.data?.data?.NinnekoPartsQuery)
      });
  }, [])

  return(
    <>
      <Header title='Ninneko Searcher' />
      <Form
        form={form}
        layout="horizontal"
        name="advanced_search"
        onFinish={onFinish}
      >
        <Row gutter={40}>
          <Col xs={24}>
            <div className={styles.fieldsWrapper}>
              
              {/* Eyes starts here */}
              <div>
                <label className="white">Eyes</label>
                <Form.Item
                  name='eyes'
                  initialValue={[]}
                  rules={[
                    {
                      required: false,
                      message: 'Input something!',
                    },
                  ]}
                >
                  <Select
                    showSearch
                    name="eyes"
                    placeholder="Please choose the eyes type"
                    mode="multiple"
                    allowClear
                    style={{width: '100%'}}
                    optionFilterProp="label"
                    filterOption={(input, option) => 
                      option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0 
                    }
                  >
                    {
                      parts && (parts?.eyes?.map((item, key) => <Option key={key} value={item.id}>{item.name}</Option>))
                    }
                  </Select>
                </Form.Item>
              </div>
              {/* Eyes ends here  */}
              
              {/* Mouth starts here */}
              <div>
                <label className="white">Mouth</label>
                <Form.Item
                  name='mouth'
                  initialValue={[]}
                  rules={[
                    {
                      required: false,
                      message: 'Input something!',
                    },
                  ]}
                >
                  <Select
                    showSearch
                    name="mouth"
                    placeholder="Please choose the mouth type"
                    mode="multiple"
                    allowClear
                    style={{width: '100%'}}
                    optionFilterProp="label"
                    filterOption={(input, option) => 
                      option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0 
                    }
                  >
                    {
                      parts && (parts?.mouth?.map((item, key) => <Option key={key} value={item.id}>{item.name}</Option>))
                    }
                  </Select>
                </Form.Item>
              </div>
              {/* Mouth ends here  */}

              {/* Hair starts here */}
              <div>
                <label className="white">Hair</label>
                <Form.Item
                  name='hair'
                  initialValue={[]}
                  rules={[
                    {
                      required: false,
                      message: 'Input something!',
                    },
                  ]}
                >
                  <Select
                    showSearch
                    name="hair"
                    placeholder="Please choose the hair type"
                    mode="multiple"
                    allowClear
                    style={{width: '100%'}}
                    optionFilterProp="label"
                    filterOption={(input, option) => 
                      option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0 
                    }
                  >
                    {
                      parts && (parts?.hair?.map((item, key) => <Option key={key} value={item.id}>{item.name}</Option>))
                    }
                  </Select>
                </Form.Item>
              </div>
              {/* Hair ends here  */}

              {/* Hand starts here */}
              <div>
                <label className="white">Hand</label>
                <Form.Item
                  name='hand'
                  initialValue={[]}
                  rules={[
                    {
                      required: false,
                      message: 'Input something!',
                    },
                  ]}
                >
                  <Select
                    showSearch
                    name="hand"
                    placeholder="Please choose the hand type"
                    mode="multiple"
                    allowClear
                    style={{width: '100%'}}
                    optionFilterProp="label"
                    filterOption={(input, option) => 
                      option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0 
                    }
                  >
                    {
                      parts && (parts?.hand?.map((item, key) => <Option key={key} value={item.id}>{item.name}</Option>))
                    }
                  </Select>
                </Form.Item>
              </div>
              {/* Hand ends here  */}

              {/* Tail starts here */}
              <div>
                <label className="white">Tail</label>
                <Form.Item
                  name='tail'
                  initialValue={[]}
                  rules={[
                    {
                      required: false,
                      message: 'Input something!',
                    },
                  ]}
                >
                  <Select
                    showSearch
                    name="tail"
                    placeholder="Please choose the tail type"
                    mode="multiple"
                    allowClear
                    style={{width: '100%'}}
                    optionFilterProp="label"
                    filterOption={(input, option) => 
                      option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0 
                    }
                  >
                    {
                      parts && (parts?.tail?.map((item, key) => <Option key={key} value={item.id}>{item.name}</Option>))
                    }
                  </Select>
                </Form.Item>
              </div>
              {/* Tail ends here  */}

              {/* Ears starts here */}
              <div>
                <label className="white">Ears</label>
                <Form.Item
                  name='ears'
                  initialValue={[]}
                  rules={[
                    {
                      required: false,
                      message: 'Input something!',
                    },
                  ]}
                >
                  <Select
                    showSearch
                    name="ears"
                    placeholder="Please choose the ear type"
                    mode="multiple"
                    allowClear
                    style={{width: '100%'}}
                    optionFilterProp="label"
                    filterOption={(input, option) => 
                      option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0 
                    }
                  >
                    {
                      parts && (parts?.ears?.map((item, key) => <Option key={key} value={item.id}>{item.name}</Option>))
                    }
                  </Select>
                </Form.Item>
              </div>
              {/* Ears ends here  */}

              {/* Class starts here */}
              <div>
                <label className="white">Class</label>
                <Form.Item
                  name='ninekoClass'
                  initialValue={[]}
                  rules={[
                    {
                      required: false,
                      message: 'Input something!',
                    },
                  ]}
                >
                  <Select showSearch name="ninekoClass" placeholder="Please choose a class" mode="multiple" allowClear style={{width: '100%'}}>
                    <Option value={'ASSASIN'}>Assasin</Option>
                    <Option value={'RANGER'}>Ranger</Option>
                    <Option value={'SUPPORT'}>Support</Option>
                    <Option value={'MAGE'}>Surge</Option>
                    <Option value={'WARRIOR'}>Warrior</Option>
                  </Select>
                </Form.Item>
              </div>
              {/* Class ends here  */}

              {/* Faction starts here */}
              <div>
                <label className="white">Faction</label>
                <Form.Item
                  name='faction'
                  initialValue={[]}
                  rules={[
                    {
                      required: false,
                      message: 'Input something!',
                    },
                  ]}
                >
                  <Select showSearch name="faction" placeholder="Please choose a faction" mode="multiple" allowClear style={{width: '100%'}}>
                    <Option value={'EARTH'}>Earth</Option>
                    <Option value={'FIRE'}>Fire</Option>
                    <Option value={'THUNDER'}>Thunder</Option>
                    <Option value={'WATER'}>Water</Option>
                    <Option value={'WIND'}>Wind</Option>
                    <Option value={'YINYANG'}>Yinyang</Option>
                  </Select>
                </Form.Item>
              </div>
              {/* Faction ends here  */}

               {/* Life Stage starts here */}
               <div>
                <label className="white">Life Stage</label>
                <Form.Item
                  name='lifeStage'
                  initialValue={[]}
                  rules={[
                    {
                      required: false,
                      message: 'Input something!',
                    },
                  ]}
                >
                  <Select name="lifeStage" placeholder="Please choose a life stage" mode="multiple" allowClear style={{width: '100%'}}>
                    <Option value={'NEW_BORN'}>New Born</Option>
                    <Option value={'ADULT'}>Adult</Option>
                  </Select>
                </Form.Item>
              </div>
              {/* Life Stage ends here  */}

              {/* Breed Count starts here */}
              <div>
                <label className="white">Breed Count</label>
                <Form.Item
                  name='breedCount'
                  initialValue={[0, 6]}
                  rules={[
                    {
                      required: false,
                      message: 'Input something!',
                    },
                  ]}
                >
                    <Slider name="breedCount" range min={0} max={6} defaultValue={[0, 6]} style={{ width: '100%' }} onChange={value => { sliderHandler(value, 'breedCount') }} />
                    <table width={'100%'}>
                        <tr>
                            <td width={'14.2857142857%'}>0</td>
                            <td width={'14.2857142857%'}>1</td>
                            <td width={'14.2857142857%'}>2</td>
                            <td width={'14.2857142857%'}>3</td>
                            <td width={'14.2857142857%'}>4</td>
                            <td width={'14.2857142857%'}>5</td>
                            <td width={'14.2857142857%'}>6</td>
                        </tr>
                    </table>
                </Form.Item>
              </div>
              {/* Breed Count ends here  */}

              {/* Beast Count starts here */}
              <div>
                <label className="white">Wild Cattributes</label>
                <Form.Item
                  name='beastCount'
                  initialValue={0}
                  rules={[
                    {
                      required: false,
                      message: 'Input something!',
                    },
                  ]}
                >
                  <Slider name="beastCount" min={0} max={6} defaultValue={0} style={{ width: '100%' }} onChange={value => { sliderHandler(value, 'beastCount') }} />
                  <table width={'100%'}>
                    <tr>
                      <td width={'14.2857142857%'}>0</td>
                      <td width={'14.2857142857%'}>1</td>
                      <td width={'14.2857142857%'}>2</td>
                      <td width={'14.2857142857%'}>3</td>
                      <td width={'14.2857142857%'}>4</td>
                      <td width={'14.2857142857%'}>5</td>
                      <td width={'14.2857142857%'}>6</td>
                    </tr>
                  </table>
                </Form.Item>
              </div>
              {/* Beast Count ends here  */}

              {/* Threshold starts here */}
              <div>
                <label className="white">Threshold</label>
                <Form.Item
                  name='threshold'
                  initialValue={0}
                  rules={[
                    {
                      required: false,
                      message: 'Input something!',
                    },
                  ]}
                >
                  <Input type="number" name="pure" placeholder="Please input the threshold" />
                </Form.Item>
              </div>
              {/* Threshold ends here  */}

              {/* Generation starts here */}
              <div>
                <label className="white">Generation</label>
                <Form.Item
                  name='generation'
                  initialValue={[]}
                  rules={[
                    {
                      required: false,
                      message: 'Input something!',
                    },
                  ]}
                >
                  <Select name="generation" defaultValue={true} placeholder="Please choose the generation" style={{width: '100%'}} mode="multiple" allowClear>
                    <Option value={'ALPHA'}>Alpha</Option>
                    <Option value={'BETA'}>Beta</Option>
                  </Select>
                </Form.Item>
              </div>
              {/* Generation ends here  */}

              {/* Sort starts here */}
              <div>
                <label className="white">Sort</label>
                <Form.Item
                  name='orderBy'
                  initialValue={false}
                  rules={[
                    {
                      required: false,
                      message: 'Input something!',
                    },
                  ]}
                >
                  <Select name="orderBy" defaultValue={true} placeholder="Please choose the sort order" style={{width: '100%'}}>
                    <Option value={true} selected>Price Ascending</Option>
                    <Option value={false}>Price Descending</Option>
                  </Select>
                </Form.Item>
              </div>
              {/* Sort ends here  */}

            </div>
          </Col>
        </Row>

        <Row gutter={40}>
          <Col xs={24} xl={24} className={styles.actionWrapper}>
            <Button type="primary" className="btn btn-info" disabled={loading} htmlType="submit">Search</Button>
            <Button type="default" className="btn" onClick={clear}>Clear</Button>
          </Col>
        </Row>
      </Form>

      <Row gutter={40}>
        <Col xs={24} xl={24}>
          <Table
            columns={columns}
            dataSource={searchResults?.length > 0 && searchResults}
            loading={loading}
            pagination={true}
            scroll={{ x: true }}
          />
        </Col>
      </Row>
    </>
  )
}

export default NinnekoSearcher
