import React, { useRef, useState } from 'react';
import { Button, Space, Image, Select, Slider, notification, Checkbox } from 'antd';
import { BuildAlertAxios, GetProfitQuery } from '../../../graphql/axios';
import { CloseCircleOutlined, EditTwoTone  } from '@ant-design/icons';

const moment = require('moment');

const { Option } = Select;

function BuildList(props) {
    const { delayCount, exchangeRates, closeModal, dataInputs, count, deleteItem, selectData, showLoading } = props;
    const [isDisabled, setIsDisabled] = useState(true);
    const [updateValue, setUpdateValue] = useState(dataInputs);
    const [inputValue, setInputValue] = useState(updateValue.threshold+'%');
    const isCallData = useRef(false);
    const [isPreOriginChecked, setIsPreOriginChecked] = useState(updateValue.isPreOrigin);
    const input = useRef({
        'isPreOrigin': isPreOriginChecked,
        'name':updateValue.buildName,
        'class': updateValue.class,
        'percentage': updateValue.threshold,
        'skill': updateValue?.skill,
        'moral': updateValue?.morale,
        'health': updateValue?.health,
        'speed': updateValue?.speed,
        'average': updateValue.numberOfPrinter,
        'back': updateValue.back,
        'horn': updateValue.horn,
        'mouth': updateValue.mouth,
        'tail': updateValue.tail,
        'eyes': updateValue.eyes,
        'ears': updateValue.ears,
        'eyesClass': updateValue.eyesClass.map(name => name.toUpperCase()),
        'earsClass': updateValue.earsClass.map(name => name.toUpperCase()),
        'sellStrategy': 'MARKET',
        'threshold': updateValue.threshold,
        'percentToProduceDesirableTraits': 25,
        'percentToProducePrinter': 45,
        'percentToProduceFloor': 30,
        'numberOfAxiesToBuy': updateValue.numberOfPrinter,
        'generation': 13
    });

    const [profit, setProfit] = useState([]);
    const [health, setHealth] = useState(updateValue.health);
    const [moral, setMoral] = useState(updateValue.morale);
    const [speed, setSpeed] = useState(updateValue.speed);
    const [skill, setSkill] = useState(updateValue.skill);

    const getProfit = (input, onclick=false) => {

        if(onclick) {

            GetProfitQuery(input.current).then(response => {
                const profitData = response?.data?.data?.ProfitabilityCalculatorQuery?.most_profitable ? response?.data?.data?.ProfitabilityCalculatorQuery?.most_profitable : {};
                setProfit(profitData);

                notification['success']({
                    message: 'Profitability has been sync.',
                    description:
                    'Profitability for this build has been sync.',
                });
            });
        }

        if(isCallData.current === false){

            isCallData.current = true;

            setTimeout(function() {

                GetProfitQuery(input.current).then(response => {
                    const profitData = response?.data?.data?.ProfitabilityCalculatorQuery?.most_profitable ? response?.data?.data?.ProfitabilityCalculatorQuery?.most_profitable : {};
                    setProfit(profitData);
                });
                
            }, 1500 * delayCount);
        }
    }
    
    const saveItem = (e) => {
        e.preventDefault();

        showLoading();

        input.current['name'] = e.target.name.value;
        input.current['percentage'] = e.target.percentage.value.replace("%", "");
        input.current['average'] = e.target.average.value;

        BuildAlertAxios.updateItem(updateValue.id, input.current).then((response) => {
            const updatedData = response?.data?.data?.BuildAlertUpdateMutation?.result ? response?.data?.data?.BuildAlertUpdateMutation?.result : {};
            setUpdateValue(updatedData);
            closeModal();
            setIsDisabled(true);

            notification['success']({
                message: 'Success',
                description:
                  'Your selected build has been updated on the list.',
            });
        });
    }

    const enableForm = () => {
        setIsDisabled(false);
    }
    
    const setValue = (value, a) => {
        input.current[a] = value;

        const stats = ["health", "moral", "skill", "speed"];

        if(stats.includes(a)) {
            if(a === 'health') setHealth(value);
            if(a === 'moral') setMoral(value);
            if(a === 'skill') setSkill(value);
            if(a === 'speed') setSpeed(value);
        }
    }   

    const onChange = (e) => {
        const value = e.target.value.toString().replace("%", "");
        input.current['percentage'] = value;
        setInputValue(value);
    }

    const onFocus = (e) => {
        const value = e.target.value.toString().replace("%", "");
        input.current['percentage'] = value;
        setInputValue(value);
    }

    const onBlur = (e) => {
        const value = e.target.value.toString().replace("%", "");
        input.current['percentage'] = value;
        setInputValue(value+"%");
    }   

    const isPreOrigin = (e) => {
        setIsPreOriginChecked(e.target.checked);
        input.current['isPreOrigin'] = e.target.checked;
    }

    getProfit(input);

    const convertTimeStamptoCurrentDate = (timestamp) => {
        return moment(parseInt(timestamp)).format('LLL');
    }

    return (
        <>
            <div className="no-item padding-space" style={{padding: '30px', marginLeft: '30px', marginRight: '30px'}}>
                <form onSubmit={e => { saveItem(e) }} data-count={count}>
                    <table width={'100%'}>
                        <thead>
                            <tr>
                                <td colSpan={5}>
                                    Updated Data At <i className='color-saved'>{convertTimeStamptoCurrentDate(updateValue?.latestData?.timestamp)}</i>
                                    <br />
                                    &nbsp;
                                </td>
                            </tr>
                            <tr>
                                <td className='padding-right' width="40%">
                                    <input type="hidden" name="action" value="create" />
                                    <input  required name="name" defaultValue={updateValue.buildName} className='ant-input' style={{ width: '80%' }} placeholder="Axie Build Name" /> <br />
                                    &nbsp;
                                </td>
                                <td width="5%">Parts</td>
                                <td className='padding-right' width={`30%`}>
                                    <Checkbox defaultChecked onChange={isPreOrigin} style={{color: "#fff"}} checked={isPreOriginChecked}>Select Pre-origin version</Checkbox>
                                </td>
                                <td>Latest Data</td>
                                <td width="10%"></td>
                            </tr>
                            <tr>
                                <td colSpan={5}>
                                    <hr />
                                    &nbsp;
                                </td>
                            </tr>
                        </thead>
                        <tbody>
                            <tr className='color-saved'>
                                <td rowSpan={3}>
                                    <Image width={200} src={updateValue?.image} width={140} className="moveImg" />
                                </td>
                                <td>Back</td>
                                <td className='padding-right'>
                                    <Select showSearch name="back" allowClear mode="multiple" style={{ width: '100%' }} placeholder='Select Part' required defaultValue={updateValue.back} onChange={(e) => {setValue(e, 'back')}}>
                                        <Option value="ANY">Any</Option>
                                        {
                                            selectData?.SearchParametersQuery && selectData?.SearchParametersQuery?.back.map((item, key) => <Option key={key} value={item.partId}>{item.name}</Option>)
                                        }
                                    </Select>
                                </td>
                                <td>
                                        Axie Liquidity
                                </td>
                                <td>
                                        {
                                            (updateValue?.latestData?.axieLiquidity && updateValue?.latestData?.axieLiquidity !== 0) ? updateValue?.latestData?.axieLiquidity : 'N/A'
                                        }
                                </td>
                            </tr>
                            <tr className='color-saved'>
                                <td>Horn</td>
                                <td className='padding-right'>
                                    <Select showSearch name="horn" allowClear mode="multiple" style={{ width: '100%' }} placeholder='Select Part' required defaultValue={updateValue.horn} onChange={(e) => {setValue(e, 'horn')}}>
                                        <Option value="ANY">Any</Option>
                                        {
                                            selectData?.SearchParametersQuery && selectData?.SearchParametersQuery?.horn.map((item, key) => <Option key={key} value={item.partId}>{item.name}</Option>)
                                        }
                                    </Select>
                                </td>
                                <td>
                                    Floor Price
                                </td>
                                <td>
                                    {
                                        updateValue?.latestData?.floorPrice && (updateValue?.latestData?.floorPrice !== 0) && (
                                            <>
                                                $ {updateValue?.latestData?.floorPrice}&nbsp;&nbsp;:&nbsp;&nbsp;Ξ {parseFloat(updateValue?.latestData?.floorPrice / exchangeRates.current.ethereum.usd).toFixed(3)} 
                                            </>
                                        ) || 'N/A'
                                    }
                                </td>
                            </tr>
                            <tr className='color-saved'>
                                <td>Mouth</td>
                                <td className='padding-right'>
                                    <Select showSearch name="mouth" allowClear mode="multiple" style={{ width: '100%' }} placeholder='Select Part' required defaultValue={updateValue.mouth} onChange={(e) => {setValue(e, 'mouth')}}>
                                        <Option value="ANY">Any</Option>
                                        {
                                            selectData?.SearchParametersQuery && selectData?.SearchParametersQuery?.mouth.map((item, key) => <Option key={key} value={item.partId}>{item.name}</Option>)
                                        }
                                    </Select>
                                </td>
                                <td>
                                    First 100 Axies Ave.
                                </td>
                                <td>
                                    {
                                        updateValue?.latestData?.first100 && (updateValue?.latestData?.first100 !== 0) && (
                                            <>
                                                $ {updateValue?.latestData?.first100}&nbsp;&nbsp;:&nbsp;&nbsp;Ξ {parseFloat(updateValue?.latestData?.first100 / exchangeRates.current.ethereum.usd).toFixed(3)} 
                                            </>
                                        ) || 'N/A'
                                    }
                                </td>
                            </tr>
                            <tr className='color-saved'>
                                <td className='padding-right'>
                                    <Select showSearch name="class" style={{ width: '80%' }} placeholder='Select Class' required defaultValue={updateValue.class.toUpperCase()} onChange={(e) => {setValue(e, 'class')}} >
                                        {
                                            selectData?.SearchParametersQuery && selectData?.SearchParametersQuery?.classes.map((item, key) => <Option key={key} value={item.name.toUpperCase()}><img src={`/assets/images/class/${item.name.toLowerCase()}.png`} /> {item.name}</Option>)
                                        }
                                    </Select>
                                </td>     
                                <td>Tail</td>
                                <td className='padding-right'>
                                    <Select showSearch name="tail" allowClear mode="multiple" style={{ width: '100%' }} placeholder='Select Part' required defaultValue={updateValue.tail} onChange={(e) => {setValue(e, 'tail')}}>
                                        <Option value="ANY">Any</Option>
                                        {
                                            selectData?.SearchParametersQuery && selectData?.SearchParametersQuery?.tail.map((item, key) => <Option key={key} value={item.partId}>{item.name}</Option>)
                                        }
                                    </Select>
                                </td>
                                <td>
                                    First 200 Axies Ave.
                                </td>
                                <td>
                                    {
                                        updateValue?.latestData?.first200 && (updateValue?.latestData?.first200 !== 0) && (
                                            <>
                                                $ {updateValue?.latestData?.first200}&nbsp;&nbsp;:&nbsp;&nbsp;Ξ {parseFloat(updateValue?.latestData?.first200 / exchangeRates.current.ethereum.usd).toFixed(3)} 
                                            </>
                                        ) || 'N/A'
                                    }
                                </td>
                            </tr>
                            <tr className='color-saved'>
                                <td className='padding-right'>
                                    <input value={inputValue} onChange={onChange} onFocus={onFocus} onBlur={onBlur} name="percentage" type="text" className='ant-input' style={{ width: '80%' }} placeholder="Threshold for Printer (%)" min={1} max={100} maxLength={3} />
                                </td>    
                                <td>
                                    {isPreOriginChecked ? 'Eyes Class' : 'Eyes'}
                                </td>
                                <td className='padding-right'>
                                    <Select mode="multiple" allowClear name="eyes" style={{ width: '100%' }} placeholder={isPreOriginChecked ? 'Select Eyes Class' : 'Select Part'} required defaultValue={isPreOriginChecked ? updateValue.eyesClass.map(name => name.toUpperCase()) : updateValue.eyes} onChange={(e) => {setValue(e, isPreOriginChecked ? 'eyesClass' : 'eyes')}}>
                                        <Option value="ANY">Any</Option>
                                        {isPreOriginChecked ? 
                                            <>
                                                {
                                                    selectData.SearchParametersQuery && selectData.SearchParametersQuery.classes.filter(i=> !["Dusk","Dawn","Mech"].includes(i.name)).map((item, key) => <Option key={key} value={item.name.toUpperCase()}>{item.name}</Option>)
                                                }
                                            </>
                                            :
                                            <>  
                                                {
                                                    selectData?.SearchParametersQuery && selectData?.SearchParametersQuery?.eyes.map((item, key) => <Option key={key} value={item.partId}>{item.name}</Option>)
                                                }
                                            </>
                                        } 
                                    </Select>
                                </td>
                            </tr>
                            <tr className='color-saved'>
                                <td className='padding-right'>
                                    <input type="number" name="average" defaultValue={updateValue.numberOfPrinter} className='ant-input' style={{ width: '80%' }} placeholder="Number of Printer Axies (X)" min={1} maxLength={3} />
                                </td>       
                                <td>
                                    {isPreOriginChecked ? 'Ears Class' : 'Ears'}    
                                </td>
                                <td className='padding-right'>
                                    <Select mode="multiple" allowClear name="ears" style={{ width: '100%' }} placeholder={isPreOriginChecked ? 'Select Eyes Class' : 'Select Part'} required defaultValue={isPreOriginChecked ? updateValue.earsClass.map(name => name.toUpperCase()) : updateValue.ears} onChange={(e) => {setValue(e, isPreOriginChecked ? 'earsClass' : 'ears')}}>
                                        <Option value="ANY">Any</Option>
                                        {isPreOriginChecked ? 
                                            <>
                                                {
                                                    selectData.SearchParametersQuery && selectData.SearchParametersQuery.classes.filter(i=> !["Dusk","Dawn","Mech"].includes(i.name)).map((item, key) => <Option key={key} value={item.name.toUpperCase()}>{item.name}</Option>)
                                                }
                                            </>
                                            :
                                            <>  
                                                {
                                                    selectData?.SearchParametersQuery && selectData?.SearchParametersQuery?.ears.map((item, key) => <Option key={key} value={item.partId}>{item.name}</Option>)
                                                }
                                            </>
                                        } 
                                    </Select>
                                </td>
                                <td>
                                    Printer Liquidity
                                </td>
                                <td>
                                    {
                                        (updateValue?.latestData?.printerLiquidity &&  updateValue?.latestData?.printerLiquidity !== 0) ? updateValue?.latestData?.printerLiquidity : 'N/A'
                                    }
                                </td>
                            </tr>
                            <tr className='color-saved'>
                                <td className='padding-right' colSpan={3} style={{paddingTop: '15px'}}>
                                    HEALTH (Min <i style={{color: "#eee"}}>{health[0]}</i>, Max <i style={{color: "#eee"}}>{health[1]}</i>)
                                </td>
                                <td>
                                    First 20 Printer Ave.
                                </td>
                                <td>
                                    {
                                        updateValue?.latestData?.first20 && (updateValue?.latestData?.first20 !== 0) && (
                                            <>
                                                $ {updateValue?.latestData?.first20}&nbsp;&nbsp;:&nbsp;&nbsp;Ξ {parseFloat(updateValue?.latestData?.first20 / exchangeRates.current.ethereum.usd).toFixed(3)} 
                                            </>
                                        ) || 'N/A'
                                    }
                                </td>
                            </tr>
                            <tr className='color-saved'>
                                <td className='padding-right'>
                                    <Slider range min={27} max={61} defaultValue={updateValue?.health ? dataInputs?.health : [27, 61]} style={{ width: '80%' }} onChange={(e) => {setValue(e, 'health')}}/>
                                </td>
                                <td colSpan={2}></td>
                                <td>
                                    First 40 Printer Ave.
                                </td>
                                <td>
                                    {
                                        updateValue?.latestData?.first40 && (updateValue?.latestData?.first40 !== 0) &&  (
                                            <>
                                                $ {updateValue?.latestData?.first40}&nbsp;&nbsp;:&nbsp;&nbsp;Ξ {parseFloat(updateValue?.latestData?.first40 / exchangeRates.current.ethereum.usd).toFixed(3)} 
                                            </>
                                        ) || 'N/A'
                                    }
                                </td>
                            </tr>
                            <tr className='color-saved'>
                                <td className='padding-right' colSpan={3} style={{paddingTop: '15px'}}>
                                    SPEED (Min <i style={{color: "#eee"}}>{speed[0]}</i>, Max <i style={{color: "#eee"}}>{speed[1]}</i>)
                                </td>
                                <td>
                                    First 60 Printer Ave.
                                </td>
                                <td>
                                    {
                                        updateValue?.latestData?.first60 && (updateValue?.latestData?.first60 !== 0) &&  (
                                            <>
                                                $ {updateValue?.latestData?.first60}&nbsp;&nbsp;:&nbsp;&nbsp;Ξ {parseFloat(updateValue?.latestData?.first60 / exchangeRates.current.ethereum.usd).toFixed(3)} 
                                            </>
                                        ) || 'N/A'
                                    }
                                </td>
                            </tr>
                            <tr className='color-saved'>
                                <td className='padding-right'>
                                    <Slider range min={27} max={61} defaultValue={updateValue?.speed ? dataInputs?.speed : [27, 61]} style={{ width: '80%' }} onChange={(e) => {setValue(e, 'speed')}}/>
                                </td>
                                <td colSpan={2}></td>
                                <td>
                                    X Printer Axies Ave.
                                </td>
                                <td>
                                    {
                                        updateValue?.latestData?.numberOfPrinterAve && (updateValue?.latestData?.numberOfPrinterAve !== 0) &&  (
                                            <>
                                                $ {updateValue?.latestData?.numberOfPrinterAve}&nbsp;&nbsp;:&nbsp;&nbsp;Ξ {parseFloat(updateValue?.latestData?.numberOfPrinterAve / exchangeRates.current.ethereum.usd).toFixed(3)}
                                            </>
                                        ) || 'N/A'
                                    }
                                </td>
                            </tr>
                            <tr className='color-saved'>
                                <td className='padding-right' style={{paddingTop: '15px'}}>
                                    SKILL (Min <i style={{color: "#eee"}}>{skill[0]}</i>, Max <i style={{color: "#eee"}}>{skill[1]}</i>)
                                    <Slider range min={27} max={61} defaultValue={updateValue?.skill ? dataInputs?.skill : [27, 61]} style={{ width: '80%' }} onChange={(e) => {setValue(e, 'skill')}}/>
                                </td>
                                <td colSpan={2}></td>
                                <td>
                                    Cycle Name
                                </td>
                                <td>
                                    {
                                        profit?.name && (
                                            <>
                                                {profit?.name}
                                            </>
                                        ) || 'N/A'
                                    }
                                </td>
                            </tr>
                            <tr className='color-saved'>
                                <td className='padding-right' style={{paddingTop: '15px'}}>
                                    MORALE (Min <i style={{color: "#eee"}}>{moral[0]}</i>, Max <i style={{color: "#eee"}}>{moral[1]}</i>)
                                    <Slider range min={27} max={61} defaultValue={updateValue?.morale ? dataInputs?.morale : [27, 61]} style={{ width: '80%' }} onChange={(e) => {setValue(e, 'moral')}}/>
                                </td>
                                <td colSpan={2}></td>
                                <td>
                                    Investment Total
                                </td>
                                <td>
                                    {
                                        profit?.investments && (
                                            <>
                                                $ {parseFloat(profit?.investments).toFixed(3)}
                                            </>
                                        ) || 'N/A'
                                    }
                                </td>
                            </tr>
                            <tr className='color-saved'>
                                <td className='padding-right' style={{paddingTop: '15px'}}></td>
                                <td colSpan={2}></td>
                                <td>
                                    Sales Total
                                </td>
                                <td>
                                    {
                                        profit?.sales && (
                                            <>
                                                $ {parseFloat(profit?.sales).toFixed(3)}
                                            </>
                                        ) || 'N/A'
                                    }
                                </td>
                            </tr>
                            <tr className='color-saved'>
                                <td className='padding-right' style={{paddingTop: '15px'}}></td>
                                <td colSpan={2}></td>
                                <td>
                                    Profit
                                </td>
                                <td>
                                    {
                                        profit?.profit && (
                                            <>
                                                $ {parseFloat(profit?.profit).toFixed(3)}
                                            </>
                                        ) || 'N/A'
                                    }
                                </td>
                            </tr>
                            <tr className='color-saved'>
                                <td className='padding-right' style={{paddingTop: '15px'}}></td>
                                <td colSpan={2}></td>
                                <td>
                                    Profit Margin
                                </td>
                                <td>
                                    {
                                        profit?.profitMargin && (
                                            <>
                                                $ {parseFloat(profit?.profitMargin).toFixed(3)}
                                            </>
                                        ) || 'N/A'
                                    }
                                </td>
                            </tr>
                            <tr>
                                <td colSpan={5}>
                                    &nbsp;
                                    <hr />
                                </td>
                            </tr>
                        </tbody>
                        <tfoot>
                            <tr>
                                <td>
                                    <br />
                                    &nbsp;
                                    <Space>
                                        <a className="color-red" onClick={() => {deleteItem(updateValue.id, count)}}>
                                            <CloseCircleOutlined style={{ fontSize: '20px', color: '#FF6767' }} theme="outlined" />
                                        </a>
                                        <a className="color-red" onClick={() => {enableForm()}}>
                                            <EditTwoTone  style={{ fontSize: '20px' }} theme="outlined"  />
                                        </a>
                                    </Space>
                                </td>
                                <td></td>
                                <td></td>
                                <td>
                                    <Button type="primary" htmlType="button" onClick={() => getProfit(input, true)} className="btn btn-info" style={{cursor: 'default'}} size='large'> PROFITABILITY </Button>
                                </td>
                                <td style={{textAlign: 'right'}}> 
                                    { isDisabled && (
                                        <>
                                            <Button type="primary" htmlType="button" className="btn btn-info" style={{cursor: 'default'}} size='large'> SAVED </Button>
                                        </>
                                    ) }
                                    { !isDisabled && (
                                        <>
                                            <Button type="primary" htmlType="submit" className="btn btn-info" size='large'> UPDATE </Button>
                                        </>
                                    ) }
                                </td>
                            </tr>
                        </tfoot>
                    </table>
                </form>
            </div>
        </>
    )
}

export default BuildList;