import React, { useContext } from "react";
import { Typography, Row, Col} from 'antd'
import styles from './Header.module.scss'
import { AppContext } from "../../context/AppContext";

const { Title } = Typography

const Header = ({title, description}) => {
  const state = useContext(AppContext);

  const logOut = () =>{
    localStorage.removeItem('appToken')
    localStorage.removeItem('role')
    state.setAppState({
      ...state,
      auth:{
        accessToken: null,
        role:null
      }
    })
    window.location.reload()
  }

  return (
    <Row className={styles.headline}>
      <Col xs={15} sm={15} md={15} lg={15}>
        <Title level={1}>
          {title}
        </Title>
        {
          description && (
            <p>
              {description}
            </p>
          )
        }
      </Col>
      <Col xs={9} sm={9} md={9} lg={9}>
        {/* <a className="il-btn il-btn--gradient" href="#"><span>Connect Wallet</span></a> */}
        <a className="il-btn il-btn--danger" onClick={logOut} href="#">Logout</a>
      </Col>
    </Row>
  )
}

export default Header
