import React, { useEffect, useState, useRef } from "react";
import ReactDOM from "react-dom";
import _ from "lodash";
import Header from '../../components/Header/Header'

import {
    useLazyQuery,
    useQuery
} from "@apollo/client";

import {
    AppContext
} from "../../context/AppContext";

import {
    SearchParametersQuery,
    //SearchAxiesR1R2Query
} from '../../graphql/queries';

import {
    AxieGene
} from "agp-npm/dist/axie-gene";

import {
    SearchAxiesR1R2QueryAxios
} from '../../graphql/axios';

import { Breadcrumb, Typography, Row, Col, Table, Radio, Button, Form, Select, InputNumber, Pagination } from 'antd';
import { ReactComponent as ExternalLinkIcon } from '../../images/icons/external-link.svg';

const { Title } = Typography;
const { Option } = Select;

const SearchAxie = (props) => {
    const [expand, setExpand] = useState(false);
    const [loading, setLoading] = useState(false);
    const [form] = Form.useForm();

    // const [axiePartR1R2, setAxiePartsR1R2] = useState();
    // const [page, setPage] = useState(1);
    const axieClass = useRef();
    const axieParts = useRef([]);
    const axieParts1 = useRef([]);
    const axieParts2 = useRef([]);
    const breedCount = useRef('');
    const purity = useRef('');
    const sort = useRef('PRICE_ASC');

    const [searchResults, setSearchResults] = useState([]);
    const [sortPrice, setSortPrice] = useState();

    const {
        data
    } = useQuery(SearchParametersQuery);

    const onFinish = (values) => {
        const { fieldClass, fieldSort, fieldMouth, fieldHorn, fieldBack, fieldTail, fieldPurity, fieldBreedCount } = values;
        const { fieldMouthR1, fieldHornR1, fieldBackR1, fieldTailR1, fieldMouthR2, fieldHornR2, fieldBackR2, fieldTailR2 } = values;

        const fieldParts = [];
        fieldMouth && fieldParts.push(fieldMouth)
        fieldHorn && fieldParts.push(fieldHorn)
        fieldBack && fieldParts.push(fieldBack)
        fieldTail && fieldParts.push(fieldTail)
        
        const fieldParts1 = [];
        fieldMouthR1 && fieldParts1.push(fieldMouthR1)
        fieldHornR1 && fieldParts1.push(fieldHornR1)
        fieldBackR1 && fieldParts1.push(fieldBackR1)
        fieldTailR1 && fieldParts1.push(fieldTailR1)
        
        const fieldParts2 = [];
        fieldMouthR2 && fieldParts2.push(fieldMouthR2)
        fieldHornR2 && fieldParts2.push(fieldHornR2)
        fieldBackR2 && fieldParts2.push(fieldBackR2)
        fieldTailR2 && fieldParts2.push(fieldTailR2)

        const allEqual = axieParts.current.join(',') === fieldParts.join(',');
        const allEqual1 = axieParts1.current.join(',') === fieldParts1.join(',');
        const allEqual2 = axieParts2.current.join(',') === fieldParts2.join(',');

        let pagination = 0;
        let axieResults = [];

        if (axieClass.current !== fieldClass || sort.current !== fieldSort || breedCount.current !== fieldBreedCount || purity.current !== fieldPurity || !allEqual || !allEqual1 || !allEqual2) {
        // if (axieClass.current !== fieldClass || !allEqual) {
            setLoading(true);
            const lastPage = 6600;
            let reqCall = 0;
            let reqDone = 0;
            axieResults = [];
            pagination = 0;

            for (var i = pagination; i <= lastPage; i += 3300) {
                pagination = i
                reqCall++;

                SearchAxiesR1R2QueryAxios(
                    fieldClass,
                    fieldParts,
                    fieldBreedCount != null ? fieldBreedCount : null,
                    fieldPurity ? fieldPurity : null,
                    pagination,
                    fieldSort
                ).then((result) => {
                    reqDone++;
                    const results = result?.data?.data?.SearchAxiesR1R2Query?.results ? result?.data?.data?.SearchAxiesR1R2Query?.results : [];
                    axieResults.push(...results);
                    if (pagination >= lastPage && reqDone >= reqCall) {
                        done(axieResults, values);
                    }
                });
            }
            axieClass.current = fieldClass;
            sort.current = fieldSort;
            axieParts.current = fieldParts;
            axieParts1.current = fieldParts1;
            axieParts2.current = fieldParts2;
            breedCount.current = fieldBreedCount;
            purity.current = fieldPurity;
        } else {
            axieResults = searchResults;
            done(axieResults, values);
        }


        // setSortPrice(values["fieldSort"])
        // setLoading(true);
        // setAxieParts([
        //     values.fieldMouth,
        //     values.fieldHorn,
        //     values.fieldBack,
        //     values.fieldTail
        // ])
        // setAxiePartsR1R2(values)
    };

    const done = (axieResults, values) => {
        console.log(axieResults.length)
        console.log(_.uniqBy(axieResults, "id"))
        axieResults = _.uniqBy(axieResults, "id")
        if (sort.current === 'PRICE_ASC') {
            axieResults = _.orderBy(axieResults, "ethPrice", "asc");
        } else if (sort.current === 'PRICE_DESC') {
            axieResults = _.orderBy(axieResults, "ethPrice", "desc");
        } else if (sort.current === 'ID_ASC') {
            axieResults = _.orderBy(axieResults, "id", "asc");
        } else if (sort.current === 'ID_DESC') {
            axieResults = _.orderBy(axieResults, "id", "desc");
        }

        axieResults = axieResults.map(i => {
            i.geneData = new AxieGene(i.genes) //parse Genes
            return i
        })

        const { fieldBreedCount, fieldPurity, fieldMouthR1, fieldHornR1, fieldBackR1, fieldTailR1, fieldMouthR2, fieldHornR2, fieldBackR2, fieldTailR2 } = values;

        // if (fieldBreedCount !== undefined) {
        //     axieResults = axieResults.filter(i => i.breedCount === fieldBreedCount)
        // }

        // if (fieldPurity !== undefined) {
        //     axieResults = axieResults.filter(i => i.purity === fieldPurity)
        // }

        if (fieldMouthR1 && fieldMouthR1 != undefined) {
            axieResults = axieResults.filter(i => i.geneData["mouth"].r1.partId === fieldMouthR1)
        }

        if (fieldHornR1 && fieldHornR1 != undefined) {
            axieResults = axieResults.filter(i => i.geneData["horn"].r1.partId === fieldHornR1)
        }

        if (fieldBackR1 && fieldBackR1 != undefined) {
            axieResults = axieResults.filter(i => i.geneData["back"].r1.partId === fieldBackR1)
        }

        if (fieldTailR1 && fieldTailR1 != undefined) {
            axieResults = axieResults.filter(i => i.geneData["tail"].r1.partId === fieldTailR1)
        }

        if (fieldMouthR2 && fieldMouthR2 != undefined) {
            axieResults = axieResults.filter(i => i.geneData["mouth"].r2.partId === fieldMouthR2)
        }

        if (fieldHornR2 && fieldHornR2 != undefined) {
            axieResults = axieResults.filter(i => i.geneData["horn"].r2.partId === fieldHornR2)
        }

        if (fieldBackR2 && fieldBackR2 != undefined) {
            axieResults = axieResults.filter(i => i.geneData["back"].r2.partId === fieldBackR2)
        }

        if (fieldTailR2 && fieldTailR2 != undefined) {
            axieResults = axieResults.filter(i => i.geneData["tail"].r2.partId === fieldTailR2)
        }

        setSearchResults(axieResults);
        setLoading(false);
    }

    // useEffect(() => {

    // if (axieClass != null) {
    //     axieResults = [];
    //     for (var i = pagination + 1; i <= 8001; i += 4000) {
    //         pagination = i
    //         const searchResults = SearchAxiesR1R2QueryAxios(
    //             axieClass,
    //             axieParts,
    //             breedCount,
    //             purity,
    //             pagination,
    //             sort
    //         )
    //         console.log(searchResults)
    //         searchResults && searchResults.data && searchResults.data.data.SearchAxiesR1R2Query.results.map(i => axieResults.push(i))

    //     }
    // }

    // console.log(axieResults)
    // axieResults = axieResults.map(i => {
    //     i.geneData = new AxieGene(i.genes) //parse Genes
    //     return i
    // })
    // if (axiePartR1R2 && axiePartR1R2.fieldMouthR1) {
    //     axieResults = axieResults.filter(i => i.geneData["mouth"].r1.partId == axiePartR1R2.fieldMouthR1)
    // }

    // if (axiePartR1R2 && axiePartR1R2.fieldHornR1) {
    //     axieResults = axieResults.filter(i => i.geneData["horn"].r1.partId == axiePartR1R2.fieldHornR1)
    // }

    // if (axiePartR1R2 && axiePartR1R2.fieldBackR1) {
    //     axieResults = axieResults.filter(i => i.geneData["back"].r1.partId == axiePartR1R2.fieldBackR1)
    // }

    // if (axiePartR1R2 && axiePartR1R2.fieldTailR1) {
    //     axieResults = axieResults.filter(i => i.geneData["tail"].r1.partId == axiePartR1R2.fieldTailR1)
    // }

    // if (axiePartR1R2 && axiePartR1R2.fieldMouthR2) {
    //     axieResults = axieResults.filter(i => i.geneData["mouth"].r2.partId == axiePartR1R2.fieldMouthR2)
    // }

    // if (axiePartR1R2 && axiePartR1R2.fieldHornR2) {
    //     axieResults = axieResults.filter(i => i.geneData["horn"].r2.partId == axiePartR1R2.fieldHornR2)
    // }

    // if (axiePartR1R2 && axiePartR1R2.fieldBackR2) {
    //     axieResults = axieResults.filter(i => i.geneData["back"].r2.partId == axiePartR1R2.fieldBackR2)
    // }

    // if (axiePartR1R2 && axiePartR1R2.fieldTailR2) {
    //     axieResults = axieResults.filter(i => i.geneData["tail"].r2.partId == axiePartR1R2.fieldTailR2)
    // }

    // axieResults = await _.uniqBy(axieResults, "id")
    // axieResults = _.orderBy(axieResults, "ethPrice", sortPrice);
    // console.log(axieResults)

    // setSearchResults(axieResults)
    // setLoading(false)

    // }, [axieClass, axieParts, breedCount, purity, sort])

    const columns = [
        {
            title: 'ID',
            dataIndex: 'id',
            key: 'id',
            render: (text) => <a target="_blank" href={`https://marketplace.axieinfinity.com/axie/${text}`}>
                {text} 
                <ExternalLinkIcon style={{
                    verticalAlign: 'middle',
                    transform: 'translateY(-2px)',
                    marginLeft: '5px',
                }} />
            </a>
        },
        {
            title: 'Name',
            dataIndex: 'name',
            key: 'name',
        },
        {
            title: 'Price',
            dataIndex: 'ethPrice',
            key: 'ethPrice',
        },
        {
            title: 'Breed Count',
            dataIndex: 'breedCount',
            key: 'breedCount',
        },
        {
          title: 'Ability',
          dataIndex: 'ability',
          key: 'ability',
          sorter: (a,b) => a.ability - b.ability,
        },
        {
            title: 'Purity',
            dataIndex: 'purity',
            key: 'purity',
            sorter: (a,b) => a.purity - b.purity,
        },
        {
            title: 'R1R2',
            dataIndex: 'r1r2',
            key: 'r1r2',
            sorter: (a,b) => a.r1r2 - b.r1r2,
        },
    ]
    // const itemRender = (current, type, originalElement) => {
    //   if (type === 'prev') {
    //     return <a>Previous</a>;
    //   }
    //   if (type === 'next') {
    //     return <a>Next</a>;
    //   }
    //   return originalElement;
    // }
    // const pageChange = (page,pageSize) =>{
    //  console.log(page,pageSize);
    //  setPagination({
    //      from:((page-1)*pageSize),
    //      size:pageSize
    //  })
    // }
    const layout = {
        labelCol: { span: 8 },
        wrapperCol: { span: 16 },
      };
    return (
        <>
            <Header title='R1R2 Searcher' />
            <Row>
                <Col xs={24} xl={24}>
                    <Form
                    {...layout}
                        form={form}
                        layout="horizontal"
                        name="advanced_search"
                        className="ant-advanced-search-form"
                        onFinish={onFinish}
                    >
                        <Row gutter={24}>
                            <Col span={6} key={1}>
                                <Form.Item
                                    name={`fieldMouth`}
                                    label={`Mouth`}
                                    rules={[
                                        {
                                            required: false,
                                            message: 'Input something!',
                                        },
                                    ]}
                                >
                                    <Select showSearch name="mouth" placeholder="Please choose Mouth">
                                        <Option value="">Please choose Mouth</Option>
                                        {
                                            data && data.SearchParametersQuery && data.SearchParametersQuery.mouth.map((item, key) => <Option key={key} value={item.partId}>{item.name}</Option>)
                                        }
                                    </Select>
                                </Form.Item>
                            </Col>
                            <Col span={6} key={2}>
                                <Form.Item
                                    name={`fieldHorn`}
                                    label={`Horn`}
                                    rules={[
                                        {
                                            required: false,
                                            message: 'Input something!',
                                        },
                                    ]}

                                >
                                    <Select showSearch name="horn" placeholder="Please choose Horn">
                                        <Option value="">Please choose Horn</Option>
                                        {
                                            data && data.SearchParametersQuery && data.SearchParametersQuery.horn.map((item, key) => <Option key={key} value={item.partId}>{item.name}</Option>)
                                        }
                                    </Select>
                                </Form.Item>
                            </Col>
                            <Col span={6} key={3}>
                                <Form.Item
                                    name={`fieldBack`}
                                    label={`Back`}
                                    rules={[
                                        {
                                            required: false,
                                            message: 'Input something!',
                                        },
                                    ]}

                                >
                                    <Select showSearch name="back" placeholder="Please choose Back">
                                        <Option value="">Please choose Back</Option>
                                        {
                                            data && data.SearchParametersQuery && data.SearchParametersQuery.back.map((item, key) => <Option key={key} value={item.partId}>{item.name}</Option>)
                                        }
                                    </Select>
                                </Form.Item>
                            </Col>
                            <Col span={6} key={4}>
                                <Form.Item
                                    name={`fieldTail`}
                                    label={`Tail`}
                                    rules={[
                                        {
                                            required: false,
                                            message: 'Input something!',
                                        },
                                    ]}

                                >
                                    <Select showSearch name="tail" placeholder="Please choose tail">
                                        <Option value="">Please choose tail</Option>
                                        {
                                            data && data.SearchParametersQuery && data.SearchParametersQuery.tail.map((item, key) => <Option key={key} value={item.partId}>{item.name}</Option>)
                                        }
                                    </Select>
                                </Form.Item>
                            </Col>
                            <Col span={6} key={5}>
                                <Form.Item
                                    name={`fieldMouthR1`}
                                    label={`MouthR1`}
                                    rules={[
                                        {
                                            required: false,
                                            message: 'Input something!',
                                        },
                                    ]}
                                >
                                    <Select showSearch name="mouthR1" placeholder="Please choose Mouth">
                                        <Option value="">Please choose Mouth</Option>
                                        {
                                            data && data.SearchParametersQuery && data.SearchParametersQuery.mouth.map((item, key) => <Option key={key} value={item.partId}>{item.name}</Option>)
                                        }
                                    </Select>
                                </Form.Item>
                            </Col>
                            <Col span={6} key={6}>
                                <Form.Item
                                    name={`fieldHornR1`}
                                    label={`HornR1`}
                                    rules={[
                                        {
                                            required: false,
                                            message: 'Input something!',
                                        },
                                    ]}

                                >
                                    <Select showSearch name="hornR1" placeholder="Please choose Horn">
                                        <Option value="">Please choose Horn</Option>
                                        {
                                            data && data.SearchParametersQuery && data.SearchParametersQuery.horn.map((item, key) => <Option key={key} value={item.partId}>{item.name}</Option>)
                                        }
                                    </Select>
                                </Form.Item>
                            </Col>
                            <Col span={6} key={7}>
                                <Form.Item
                                    name={`fieldBackR1`}
                                    label={`BackR1`}
                                    rules={[
                                        {
                                            required: false,
                                            message: 'Input something!',
                                        },
                                    ]}

                                >
                                    <Select showSearch name="backR1" placeholder="Please choose Back">
                                        <Option value="">Please choose Back</Option>
                                        {
                                            data && data.SearchParametersQuery && data.SearchParametersQuery.back.map((item, key) => <Option key={key} value={item.partId}>{item.name}</Option>)
                                        }
                                    </Select>
                                </Form.Item>
                            </Col>
                            <Col span={6} key={8}>
                                <Form.Item
                                    name={`fieldTailR1`}
                                    label={`TailR1`}
                                    rules={[
                                        {
                                            required: false,
                                            message: 'Input something!',
                                        },
                                    ]}

                                >
                                    <Select showSearch name="tailR1" placeholder="Please choose tail">
                                        <Option value="">Please choose tail</Option>
                                        {
                                            data && data.SearchParametersQuery && data.SearchParametersQuery.tail.map((item, key) => <Option key={key} value={item.partId}>{item.name}</Option>)
                                        }
                                    </Select>
                                </Form.Item>
                            </Col>
                            <Col span={6} key={9}>
                                <Form.Item
                                    name={`fieldMouthR2`}
                                    label={`MouthR2`}
                                    rules={[
                                        {
                                            required: false,
                                            message: 'Input something!',
                                        },
                                    ]}
                                >
                                    <Select showSearch name="mouthR2" placeholder="Please choose Mouth">
                                        <Option value="">Please choose Mouth</Option>
                                        {
                                            data && data.SearchParametersQuery && data.SearchParametersQuery.mouth.map((item, key) => <Option key={key} value={item.partId}>{item.name}</Option>)
                                        }
                                    </Select>
                                </Form.Item>
                            </Col>
                            <Col span={6} key={10}>
                                <Form.Item
                                    name={`fieldHornR2`}
                                    label={`HornR2`}
                                    rules={[
                                        {
                                            required: false,
                                            message: 'Input something!',
                                        },
                                    ]}

                                >
                                    <Select showSearch name="hornR2" placeholder="Please choose Horn">
                                        <Option value="">Please choose Horn</Option>
                                        {
                                            data && data.SearchParametersQuery && data.SearchParametersQuery.horn.map((item, key) => <Option key={key} value={item.partId}>{item.name}</Option>)
                                        }
                                    </Select>
                                </Form.Item>
                            </Col>
                            <Col span={6} key={11}>
                                <Form.Item
                                    name={`fieldBackR2`}
                                    label={`BackR2`}
                                    rules={[
                                        {
                                            required: false,
                                            message: 'Input something!',
                                        },
                                    ]}

                                >
                                    <Select showSearch name="backR2" placeholder="Please choose Back">
                                        <Option value="">Please choose Back</Option>
                                        {
                                            data && data.SearchParametersQuery && data.SearchParametersQuery.back.map((item, key) => <Option key={key} value={item.partId}>{item.name}</Option>)
                                        }
                                    </Select>
                                </Form.Item>
                            </Col>
                            <Col span={6} key={12}>
                                <Form.Item
                                    name={`fieldTailR2`}
                                    label={`TailR2`}
                                    rules={[
                                        {
                                            required: false,
                                            message: 'Input something!',
                                        },
                                    ]}

                                >
                                    <Select showSearch name="tailR2" placeholder="Please choose tail">
                                        <Option value="">Please choose tail</Option>
                                        {
                                            data && data.SearchParametersQuery && data.SearchParametersQuery.tail.map((item, key) => <Option key={key} value={item.partId}>{item.name}</Option>)
                                        }
                                    </Select>
                                </Form.Item>
                            </Col>
                            <Col span={6} key={13}>
                                <Form.Item
                                    name={`fieldClass`}
                                    label={`Class`}
                                    rules={[
                                        {
                                            required: true,
                                            message: 'Input something!',
                                        },
                                    ]}
                                >
                                    <Select name="axieClass" placeholder="Please choose class">
                                        <Option value="">Please choose class</Option>
                                        {
                                            data && data.SearchParametersQuery && data.SearchParametersQuery.classes.map((item, key) => <Option key={key} value={item.name.toUpperCase()}>{item.name}</Option>)
                                        }
                                    </Select>
                                </Form.Item>
                            </Col>
                            <Col span={6} key={14}>
                                <Form.Item
                                    name={`fieldBreedCount`}
                                    label={`Breed #`}
                                    rules={[
                                        {
                                            required: false,
                                            message: 'Input something!',
                                        },
                                    ]}
                                // onKeyUp={(event) => event.target.value != '' && setBreedCount(event.target.value)}
                                >
                                    <InputNumber style={{ width: '100%' }} placeholder="Please input Breed Count" min={0} max={7} className="ant-input" />
                                </Form.Item>
                            </Col>
                            <Col span={6} key={15}>
                                <Form.Item
                                    name={`fieldPurity`}
                                    label={`Purity`}
                                    rules={[
                                        {
                                            required: false,
                                            message: 'Input something!',
                                        },
                                    ]}
                                >
                                    <InputNumber style={{ width: '100%' }} placeholder="Please input Purity" min={1} max={6} className="ant-input"  />
                                </Form.Item>
                            </Col>
                            <Col span={6} key={16}>
                                <Form.Item
                                    name={`fieldSort`}
                                    label={`Sort`}
                                    rules={[
                                        {
                                            required: true,
                                            message: 'Input something!',
                                        },
                                    ]}
                                    initialValue={sort.current}
                                >
                                    <Select name="sort" placeholder="Sort">
                                        <Option value="PRICE_ASC">Price Ascending</Option>
                                        <Option value="PRICE_DESC">Price Descending</Option>
                                        <Option value="ID_ASC">ID Ascending</Option>
                                        <Option value="ID_DESC">ID Descending</Option>
                                        {/* <Option value="LATEST">Latest</Option> */}
                                    </Select>
                                </Form.Item>
                            </Col>
                        </Row>
                        <Row>
                            <Col
                                span={24}
                                style={{
                                    textAlign: 'right',
                                }}
                            >
                                <Button type="primary" htmlType="submit" disabled={loading}>
                                    Search
                                </Button>
                                <Button
                                    style={{
                                        margin: '0 8px',
                                    }}
                                    onClick={() => {
                                        form.resetFields();
                                    }}
                                >
                                    Clear
                                </Button>
                                {/*<a
                        style={{
                          fontSize: 12,
                        }}
                        onClick={() => {
                          setExpand(!expand);
                        }}
                      >
                        {expand ? <UpOutlined /> : <DownOutlined />} Collapse
                      </a>*/}
                            </Col>
                        </Row>
                    </Form>
                </Col>

                <Col xs={24} xl={24}>
                    <br />
                    {/*<Pagination size="small" onChange={pageChange} itemRender={itemRender} total={!loading && searchResults && searchResults.SearchAxiesR1R2Query.results.length}/>*/}
                    <Table
                        className="table"
                        columns={columns}
                        pagination={true}
                        dataSource={searchResults.length > 0 && searchResults}
                        loading={loading}
                        rowKey="id"
                    />
                </Col>
            </Row>
            <br />
        </>
    )
}

export default SearchAxie