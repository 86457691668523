import axios from 'axios';
import { refreshTokenHandler } from '../utils/refreshTokenHandler';

export const SearchAxiesR1R2QueryAxios = (axieClass,axieParts,breedCount,purity,from, sort) =>{
  let cond = `
    axieClass: ${axieClass}
    sort: ${sort}
    from: ${from}
    `
    if (breedCount != null) {
      
        cond += `breedCount: ${breedCount} `
    }
    if (purity != null) {
      
        cond += `pureness: ${purity} `
    }
    if (axieParts.length > 0) {
        cond += `axieParts: ["${axieParts.join('","')}"] `
    }
    else
    {
      cond += `axieParts: [] `  
    }
    
  return axios({
    url: process.env.REACT_APP_GQL,
    method:"POST",
    data:{
      query:`{
        SearchAxiesR1R2Query(
          ${cond}         
        ){
          total
          results {
            id
            name
            ethPrice
            breedCount
            purity
            ability
            r1r2
            genes
          }
        }
      }`
    },
    headers:{
      'Authorization':`Bearer ${localStorage.getItem('appToken') || null}`
    }
  }).then(response=>{
    if(response.data.error !== undefined && response.data.error.toString().toLowerCase() === 'jwt expired') {
      refreshTokenHandler();
    }
    return response 
  }).catch(error=>{
    if (error.response) {
      // The request was made and the server responded with a status code
      // that falls out of the range of 2xx
      console.log(error.response.data);
      console.log(error.response.status);
      console.log(error.response.headers);
      return error.response.data.error
    } else if (error.request) {
      // The request was made but no response was received
      // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
      // http.ClientRequest in node.js
      console.log(error.request);
    } else {
      // Something happened in setting up the request that triggered an Error
      console.log('Error', error.message);
    }
    console.log(error.config);
  }); 
} 

export const SearcherAlgoQueryAxios = (axieClass,axieParts, eyesClass,earsClass, breedCount,purity,from, sort, health, skill, moral, speed) =>{
  let cond = `
    axieClass: [${axieClass}]
    eyesClass: [${eyesClass}]
    earsClass: [${earsClass}]
    sort: ${sort}
    from: ${from}
    health: [${health}]
    speed: [${speed}]
    morale: [${moral}]
    skill: [${skill}]
    `
    if (breedCount != null) {
        cond += `breedCount: ${breedCount} `
    }
    if (purity != null) {
        cond += `pureness: ${purity} `
    }
    
    if (axieParts.length > 0) {
        cond += `axieParts: ["${axieParts.join('","')}"] `
    } else {
      cond += `axieParts: [] `  
    }
    
  return axios({
    url: process.env.REACT_APP_GQL,
    method:"POST",
    data:{
      query:`{
        BreederAlgoSearcherQuery(
          ${cond}         
        ){
          results {
            id
            name
            ethPrice
            usdPrice
            breedCount
            genes
            purityScore
          }
        }
      }`
    },
    headers:{
      'Authorization':`Bearer ${localStorage.getItem('appToken') || null}`
    }
  }).then(response=>{
    if(response.data.error !== undefined && response.data.error.toString().toLowerCase() === 'jwt expired') {
      refreshTokenHandler();
    }
    return response 
  }).catch(error=>{
    if (error.response) {
      // The request was made and the server responded with a status code
      // that falls out of the range of 2xx
      console.log(error.response.data);
      console.log(error.response.status);
      console.log(error.response.headers);
      return error.response.data.error
    } else if (error.request) {
      // The request was made but no response was received
      // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
      // http.ClientRequest in node.js
      console.log(error.request);
    } else {
      // Something happened in setting up the request that triggered an Error
      console.log('Error', error.message);
    }
    console.log(error.config);
  }); 
} 

export const CrabadaSubclassesQuery = (params) => {
  return axios({
    url: process.env.REACT_APP_GQL,
    method:"POST",
    data:{
      query:`{
        CrabadaSubclassesQuery {
          name
        }
      }`
    },
    headers:{
      'Authorization':`Bearer ${localStorage.getItem('appToken') || null}`
    }
  }).then(response=>{
    if(response.data.error !== undefined && response.data.error.toString().toLowerCase() === 'jwt expired') {
      refreshTokenHandler();
    }
    return response
  }).catch(error=>{
    if (error.response) {
      // console.log("error: ", error.response.error);
      return error.response.data.error
    } else if (error.request) {
      // console.log(error.request);
      return error.request;
    } else {
      // console.log('Error', error.message);
      return error.message;
    }
    // console.log(error.config);
  });
}

export const CrabadaChartQueryAxios = (params) =>{
  return axios({
    url: process.env.REACT_APP_GQL,
    method:"POST",
    data:{
      query:`{
        CrabadaChartQuery(
          saleType: ${params.saletype}
          crabadaClass: ${params.class}
          purity: ${params.purity}
          virgin: ${params.virgin}
        ) {
          results {
            date
            total
          }
        }
      }`
    },
    headers:{
      'Authorization':`Bearer ${localStorage.getItem('appToken') || null}`
    }
  }).then(response=>{
    if(response.data.error !== undefined && response.data.error.toString().toLowerCase() === 'jwt expired') {
      refreshTokenHandler();
    }
    return response
  }).catch(error=>{
    if (error.response) {
      // console.log("error: ", error.response.error);
      return error.response.data.error
    } else if (error.request) {
      // console.log(error.request);
      return error.request;
    } else {
      // console.log('Error', error.message);
      return error.message;
    }
    // console.log(error.config);
  }); 
} 

export const refreshTokenQuery = () =>{
  return axios({
    url: process.env.REACT_APP_GQL+'/login',
    method:"POST",
    data:{
      query:`{
        AdminLoginQuery {
          accessToken
          refreshToken
        }
      }`
    },
    headers:{
      'Authorization':`Bearer ${localStorage.getItem('refreshToken') || null}`
    }
  }).then(response=>{
    if(response.data.error !== undefined && response.data.error.toString().toLowerCase() === 'jwt expired') {
      refreshTokenHandler();
    }
    return response
  }).catch(error=>{
    if (error.response) {
      // console.log("error: ", error.response.error);
      return error.response.data.error
    } else if (error.request) {
      // console.log(error.request);
      return error.request;
    } else {
      // console.log('Error', error.message);
      return error.message;
    }
    // console.log(error.config);
  }); 
} 

export const ProfitabilityQueryAxios = (params) =>{
  let trait = params.desiredTraitsSellingPrice !== 0 ? `desiredTraitsSellingPrice: ${params.desiredTraitsSellingPrice},` : ``;
  
  return axios({
    url: process.env.REACT_APP_GQL,
    method:"POST",
    data:{
      query:`{
        ProfitabilityCalculatorQuery(
          ${
            (params.eyes.includes("ANY") === false) ? 'eyes: '+params.eyes+',' : ''
          }
          ${
            (params.ears.includes("ANY") === false) ? 'ears: '+params.ears+',' : ''
          }
          back: "${params.back}",
          horn: "${params.horn}",
          mouth: "${params.mouth}",
          tail: "${params.tail}",
          axieClass: ${params.axieClass},
          breedCountSlider: ${params.breedCountSlider},
          threshold: ${params.threshold},
          sellStrategy: ${params.sellStrategy},
          percentToProduceDesirableTraits: ${params.percentToProduceDesirableTraits},
          ${trait}
          percentToProducePrinter: ${params.percentToProducePrinter},
          percentToProduceFloor: ${params.percentToProduceFloor},
          numberOfAxiesToBuy: ${params.numberOfAxiesToBuy}      
        ) {
          midwayResults {
            desirable
            printer
            floor
          }
          investments {
            name
            quantity
            dollarQuantity
            total
          }
          sales {
            name
            quantity
            dollarQuantity
            total
          }
          profit {
            profit
            profitMargin
          }
        }
      }`
    },
    headers:{
      'Authorization':`Bearer ${localStorage.getItem('appToken') || null}`
    }
  }).then(response=>{
    if(response.data.error !== undefined && response.data.error.toString().toLowerCase() === 'jwt expired') {
      refreshTokenHandler();
    }
    return response
  }).catch(error=>{
    if (error.response) {
      // console.log("error: ", error.response.error);
      return error.response.data.error
    } else if (error.request) {
      // console.log(error.request);
      return error.request;
    } else {
      // console.log('Error', error.message);
      return error.message;
    }
    // console.log(error.config);
  }); 
} 

export const BuildAlertAxios = {
  listItem: () => {
    return axios({
      url: process.env.REACT_APP_GQL,
      method:"POST",
      data:{
      query:`
        {
          BuildAlertsQuery {
              results {
                  id
                  buildName
                  isPreOrigin
                  image
                  class
                  threshold
                  numberOfPrinter
                  back
                  horn
                  mouth
                  tail
                  eyes
                  ears
                  eyesClass
                  earsClass
                  health
                  speed
                  skill
                  morale
                  latestData {
                    axieLiquidity
                    floorPrice
                    first100
                    first200
                    printerLiquidity
                    first20
                    first40
                    first60
                    numberOfPrinterAve
                    timestamp
                  }
              }
          }
        }
      `
    },
    headers:{
      'Authorization':`Bearer ${localStorage.getItem('appToken') || null}`
    }
    }).then(response=>{
      if(response.data.error !== undefined && response.data.error.toString().toLowerCase() === 'jwt expired') {
        refreshTokenHandler();
      }
      return response 
    }).catch(error=>{
      if (error.response) {
        console.log(error.response.data);
        console.log(error.response.status);
        console.log(error.response.headers);
        return error.response.data.error
      } else if (error.request) {
        console.log(error.request);
      } else {
        console.log('Error', error.message);
      }
      console.log(error.config);
    })
  },

  createItem: (data) => {
  return axios({
    url: process.env.REACT_APP_GQL,
    method:"POST",
    data:{
      query:`
        mutation {
          BuildAlertMutation(
            input: {
              isPreOrigin: ${data.isPreOrigin}
              buildName: "${data.name}"
              axieClass: ${data.class.toUpperCase()}
              threshold: ${data.percentage}
              health: [${data.health}]
              speed: [${data.speed}]
              morale: [${data.moral}]
              skill: [${data.skill}]
              numberOfPrinter: ${data.average}
              ${
                (data.back.includes("ANY") === false) ? 'back: ["'+data.back.join('","')+'"] ' : ''
              }
              ${
                (data.horn.includes("ANY") === false) ? 'horn: ["'+data.horn.join('","')+'"]' : ''
              }
              ${
                (data.mouth.includes("ANY") === false) ? 'mouth: ["'+data.mouth.join('","')+'"]' : ''
              }
              ${
                (data.tail.includes("ANY") === false) ? 'tail: ["'+data.tail.join('","')+'"]' : ''
              }
              ${
                (data.eyesClass.includes("ANY") === false) ? 'eyesClass: ['+data.eyesClass+']' : ''
              }
              ${
                (data.earsClass.includes("ANY") === false) ? 'earsClass: ['+data.earsClass+']' : ''
              }
              ${
                (data.eyes !== false) ?
                  (data.eyes.includes("ANY") === false) ? 'eyes: ["'+data.eyes.join('","')+'"]' : ''
                : ''
              }
              ${
                (data.ears !== false) ?
                  (data.ears.includes("ANY") === false) ? 'ears: ["'+data.ears.join('","')+'"]' : ''
                : ''
              }
            }
          ) {
            result {
              id
              buildName
              isPreOrigin
              image
              class
              threshold
              numberOfPrinter
              back
              horn
              mouth
              tail
              eyesClass
              earsClass
              eyes
              ears
              health
              speed
              skill
              morale
              latestData {
                axieLiquidity
                floorPrice
                first100
                first200
                printerLiquidity
                first20
                first40
                first60
                numberOfPrinterAve
                timestamp
              }
            }
            clientMutationId
          }
        }
      `
    },
    headers:{
      'Authorization':`Bearer ${localStorage.getItem('appToken') || null}`
    }
    }).then(response=>{
      if(response.data.error !== undefined && response.data.error.toString().toLowerCase() === 'jwt expired') {
        refreshTokenHandler();
      }
      return response 
      }).catch(error=>{
        if (error.response) {
          console.log(error.response.data);
          console.log(error.response.status);
          console.log(error.response.headers);
          return error.response.data.error
        } else if (error.request) {
          console.log(error.request);
        } else {
          console.log('Error', error.message);
        }
        console.log(error.config);
    })
  },

  updateItem: (id, data) => {
    return axios({
      url: process.env.REACT_APP_GQL,
      method:"POST",
      data:{
        query:`
          mutation {
              BuildAlertUpdateMutation(
                input: {
                  isPreOrigin: ${data.isPreOrigin}
                  buildAlertId: "${id}"
                  buildName: "${data.name}"
                  axieClass: ${data.class.toUpperCase()}
                  threshold: ${data.percentage}
                  health: [${data.health}]
                  speed: [${data.speed}]
                  morale: [${data.moral}]
                  skill: [${data.skill}]
                  numberOfPrinter: ${data.average}
                  ${
                    (data.back.includes("ANY") === false) ? 'back: ["'+data.back.join('","')+'"] ' : ''
                  }
                  ${
                    (data.horn.includes("ANY") === false) ? 'horn: ["'+data.horn.join('","')+'"]' : ''
                  }
                  ${
                    (data.mouth.includes("ANY") === false) ? 'mouth: ["'+data.mouth.join('","')+'"]' : ''
                  }
                  ${
                    (data.tail.includes("ANY") === false) ? 'tail: ["'+data.tail.join('","')+'"]' : ''
                  }
                  ${
                    (data.eyes.includes("ANY") === false) ? 'eyes: ["'+data.eyes.join('","')+'"]' : ''
                  }
                  ${
                    (data.ears.includes("ANY") === false) ? 'ears: ["'+data.ears.join('","')+'"]' : ''
                  }
                  ${
                    (data.eyesClass.includes("ANY") === false) ? 'eyesClass: ['+data.eyesClass+']' : ''
                  }
                  ${
                    (data.earsClass.includes("ANY") === false) ? 'earsClass: ['+data.earsClass+']' : ''
                  }
                }
              ) {
                result {
                  id
                  buildName
                  isPreOrigin
                  image
                  class
                  threshold
                  numberOfPrinter
                  back
                  horn
                  mouth
                  tail
                  eyesClass
                  earsClass
                  eyes
                  ears
                  health
                  speed
                  skill
                  morale
                  latestData {
                    axieLiquidity
                    floorPrice
                    first100
                    first200
                    printerLiquidity
                    first20
                    first40
                    first60
                    numberOfPrinterAve
                    timestamp
                  }
                }
                clientMutationId
              }
            }
            `
      },
      headers:{
        'Authorization':`Bearer ${localStorage.getItem('appToken') || null}`
      }
      }).then(response=>{
        if(response.data.error !== undefined && response.data.error.toString().toLowerCase() === 'jwt expired') {
          refreshTokenHandler();
        }
        return response 
      }).catch(error=>{
        if (error.response) {
          console.log(error.response.data);
          console.log(error.response.status);
          console.log(error.response.headers);
          return error.response.data.error
        } else if (error.request) {
          console.log(error.request);
        } else {
          console.log('Error', error.message);
        }
        console.log(error.config);
    })
  },

  deleteItem: (id) => {
    return axios({
      url: process.env.REACT_APP_GQL,
      method:"POST",
      data:{
      query:`
                {
                    BuildAlertDeleteQuery(buildAlertId: "${id}") {
                        success
                    }
                }
            `
        },
        headers:{
          'Authorization':`Bearer ${localStorage.getItem('appToken') || null}`
        }
        })
        .then(response=>{
          if(response.data.error !== undefined && response.data.error.toString().toLowerCase() === 'jwt expired') {
            refreshTokenHandler();
          }
          return response 
        }).catch(error=>{
          if (error.response) {
            console.log(error.response.data);
            console.log(error.response.status);
            console.log(error.response.headers);
            return error.response.data.error
          } else if (error.request) {
            console.log(error.request);
          } else {
            console.log('Error', error.message);
          }
          console.log(error.config);
      }
    )
  }
} 

export const CalculateCrabadaParts = (params) =>{  
  return axios({
    url: process.env.REACT_APP_GQL,
    method:"POST",
    data:{
      query:`{
        probabilities: CrabadaProbabilityQuery(
         crab1: "${params.crabAId}",
         crab2: "${params.crabBId}",
        ) {
          results {
            shell {
              part
              score
              class
            }
            horn {
              part
              score
              class
            }
            body {
              part
              score
              class
            }
            mouth {
              part
              score
              class
            }
            eyes {
              part
              score
              class
            }
            pincer {
              part
              score
              class
            }
          }
        }
        crabA: CrabadaInfoQuery (crabId: "${params.crabAId}") {
          class_name
          breed_count
          pure_number
          image
        }
        crabB: CrabadaInfoQuery (crabId: "${params.crabBId}") {
          class_name
          breed_count
          pure_number
          image
        }
      }`
    },
    headers:{
      'Authorization':`Bearer ${localStorage.getItem('appToken') || null}`
    }
  }).then(response=>{
    if(response.data.error !== undefined && response.data.error.toString().toLowerCase() === 'jwt expired') {
      refreshTokenHandler();
    }
    return response
  }).catch(error=>{
    if (error.response) {
      return error.response.data.error
    } else if (error.request) {
      return error.request;
    } else {
      return error.message;
    }
  }); 
}

export const CrabadaSearcherAxios = (params) => {
  return axios({
    url: process.env.REACT_APP_GQL,
    method:"POST",
    data:{
      query:`{
        CrabadaSearcher(
          crabClasses: [${params.class.join(', ')}]
          shell: [${params.shell.join(', ')}]
          horn: [${params.horn.join(', ')}]
          body: [${params.body.join(', ')}]
          mouth: [${params.mouth.join(', ')}]
          eyes: [${params.eyes.join(', ')}]
          pincer: [${params.pincer.join(', ')}]
          breedCount: [${params.breedCount.join(', ')}]
          legend: [${params.legend.join(', ')}]
          pure: [${params.pure.join(', ')}]
          breedType: [${params.breedType.join(', ')}]
          breedTypeCount: ${params.breedTypeCount}
          threshold: ${params.threshold}
          orderBy: ${params.orderBy}
        ) {
          total
          results {
            id
            name
            priceTus
            priceUsd
            breedCount
            purityScore
          }
        }
      }`
    },
    headers:{
      'Authorization':`Bearer ${localStorage.getItem('appToken') || null}`
    }
  }).then(response=>{
    return response
  }).catch(error=>{
    if (error.response) {
      return error.response.data.error
    } else if (error.request) {
      return error.request;
    } else {
      return error.message;
    }
  }); 
}

export const ProfitabilityCalculatorQueryB = (params) => {
  let trait = params.desiredTraitsSellingPrice !== 0 ? `desiredTraitsSellingPrice: ${params.desiredTraitsSellingPrice},` : ``;
  let isPreOrigin = params['preOrigin'] ? `
    ${
      (params.eyesClass.includes("ANY") === false || params.eyesClass !== '') ? 'eyesClass: ['+params.eyesClass+']' : 'eyesClass: []'
    }
    ${
      (params.earsClass.includes("ANY") === false || params.earsClass !== '') ? 'earsClass: ['+params.earsClass+']' : 'earsClass: []'
    }
  ` : `
    ${
      (params.eyes.includes("ANY") === false) ? `eyes: ["${params.eyes}"]` : `eyes: []`
    }
    ${
      (params.ears.includes("ANY") === false) ? `ears: ["${params.ears}"]` : `ears: []`
    }
  `;

  return axios({
    url: process.env.REACT_APP_GQL,
    method: "POST",
    data: {
      query: `{
        ProfitabilityCalculatorQuery(
          ${isPreOrigin}
          ${
            (params.back.includes("ANY") === false) ? `back: ["${params.back}"]` : `back: []`
          }
          ${
            (params.horn.includes("ANY") === false) ? `horn: ["${params.horn}"]` : `horn: []`
          }
          ${
            (params.mouth.includes("ANY") === false) ? `mouth: ["${params.mouth}"]` : `mouth: []`
          }
          ${
            (params.tail.includes("ANY") === false) ? `tail: ["${params.tail}"]` : `tail: []`
          }
          axieClass: ${params.axieClass},
          generation: ${params.generation},
          threshold: ${params.threshold},
          sellStrategy: ${params.sellStrategy},
          ${trait}
          percentToProduceDesirableTraits: ${params.percentToProduceDesirableTraits},
          percentToProducePrinter: ${params.percentToProducePrinter},
          percentToProduceFloor: ${params.percentToProduceFloor},
          numberOfAxiesToBuy: ${params.numberOfAxiesToBuy} 
          health: [${params.health}]
          speed: [${params.speed}]
          morale: [${params.moral}]
          skill: [${params.skill}]     
        ) {
          breed_cycle_1 {
            midwayResults {
              desirable
              printer
              floor
            }
            investments {
              name
              quantity
              dollarQuantity
              total
            }
            sales {
              name
              quantity
              dollarQuantity
              total
            }
            profit {
              profit
              profitMargin
            }
          }
          breed_cycle_2 {
            midwayResults {
              desirable
              printer
              floor
            }
            investments {
              name
              quantity
              dollarQuantity
              total
            }
            sales {
              name
              quantity
              dollarQuantity
              total
            }
            profit {
              profit
              profitMargin
            }
          }
          breed_cycle_3 {
            midwayResults {
              desirable
              printer
              floor
            }
            investments {
              name
              quantity
              dollarQuantity
              total
            }
            sales {
              name
              quantity
              dollarQuantity
              total
            }
            profit {
              profit
              profitMargin
            }
          }
          breed_cycle_4 {
            midwayResults {
              desirable
              printer
              floor
            }
            investments {
              name
              quantity
              dollarQuantity
              total
            }
            sales {
              name
              quantity
              dollarQuantity
              total
            }
            profit {
              profit
              profitMargin
            }
          }
          breed_cycle_5 {
            midwayResults {
              desirable
              printer
              floor
            }
            investments {
              name
              quantity
              dollarQuantity
              total
            }
            sales {
              name
              quantity
              dollarQuantity
              total
            }
            profit {
              profit
              profitMargin
            }
          }
          breed_cycle_6 {
            midwayResults {
              desirable
              printer
              floor
            }
            investments {
              name
              quantity
              dollarQuantity
              total
            }
            sales {
              name
              quantity
              dollarQuantity
              total
            }
            profit {
              profit
              profitMargin
            }
          }
          breed_cycle_7 {
            midwayResults {
              desirable
              printer
              floor
            }
            investments {
              name
              quantity
              dollarQuantity
              total
            }
            sales {
              name
              quantity
              dollarQuantity
              total
            }
            profit {
              profit
              profitMargin
            }
          }
          most_profitable {
            name
            profit
            profitMargin
          }
          message
        }
      }`
    },
    headers:{
      'Authorization':`Bearer ${localStorage.getItem('appToken') || null}`
    }
  }).then(response=>{
    if(response.data.error !== undefined && response.data.error.toString().toLowerCase() === 'jwt expired') {
      refreshTokenHandler();
    }
    return response
  }).catch(error=>{
    if (error.response) {
      return error.response.data.error
    } else if (error.request) {
      return error.request;
    } else {
      return error.message;
    }
  });
}

export const NinnekoPartsQuery = (params) => {
  return axios({
    url: process.env.REACT_APP_GQL,
    method:"POST",
    data:{
      query:`{
        NinnekoPartsQuery {
          eyes {
            id
            name
          }
          mouth {
            id
            name
          }
          hair {
            id
            name
          }
          hand {
            id
            name
          }
          tail {
            id
            name
          }
          ears {
            id
            name
          }
        }
      }`
    },
    headers:{
      'Authorization':`Bearer ${localStorage.getItem('appToken') || null}`
    }
  }).then(response=>{
    console.log('whoa', response)
    return response
  }).catch(error=>{
    if (error.response) {
      return error.response.data.error
    } else if (error.request) {
      return error.request;
    } else {
      return error.message;
    }
  }); 
}

export const NinnekoSearcherAxios = (params) => {
  console.log('params',params)
  return axios({
    url: process.env.REACT_APP_GQL,
    method:"POST",
    data:{
      query:`{
        SearchNinnekoQuery(
          ninnekoClass: [${params.ninekoClass.join(', ')}]
          eyes: [${params.eyes.join(', ')}]
          mouth: [${params.mouth.join(', ')}]
          hair: [${params.hair.join(', ')}]
          hand: [${params.hand.join(', ')}]
          tail: [${params.tail.join(', ')}]
          ears: [${params.ears.join(', ')}]
          generation: [${params.generation}]
          breedCount: [${params.breedCount}]
          beastCount: ${params.beastCount}
          faction: [${params.faction.join(', ')}]
          ${params.lifeStage.length === 1 ? 'lifeStage: ' + params.lifeStage : ''}
          threshold: ${params.threshold}
          sort: true
        ) {
          results {
            id
            name
            priceBnb
            priceUsd
            breedCount
            purityScore
          }
        }
      }`
    },
    headers:{
      'Authorization':`Bearer ${localStorage.getItem('appToken') || null}`
    }
  }).then(response=>{
    return response
  }).catch(error=>{
    if (error.response) {
      return error.response.data.error
    } else if (error.request) {
      return error.request;
    } else {
      return error.message;
    }
  }); 
}

export const GetProfitQuery = (params) => {
  let isPreOrigin = params['preOrigin'] ? `
    ${
      (params.eyesClass.includes("ANY") === false || params.eyesClass !== '') ? 'eyesClass: ['+params.eyesClass+']' : 'eyesClass: []'
    }
    ${
      (params.earsClass.includes("ANY") === false || params.earsClass !== '') ? 'earsClass: ['+params.earsClass+']' : 'earsClass: []'
    }
  ` : `
    ${
      (params.eyes.includes("ANY") === false) ? `eyes: ["${params.eyes}"]` : `eyes: []`
    }
    ${
      (params.ears.includes("ANY") === false) ? `ears: ["${params.ears}"]` : `ears: []`
    }
  `;

  return axios({
    url: process.env.REACT_APP_GQL,
    method: "POST",
    data: {
      query: `{
        ProfitabilityCalculatorQuery(
          ${isPreOrigin}
          ${
            (params.back.includes("ANY") === false) ? `back: ["${params.back.join('","')}"]` : `back: []`
          }
          ${
            (params.horn.includes("ANY") === false) ? `horn: ["${params.horn.join('","')}"]` : `horn: []`
          }
          ${
            (params.mouth.includes("ANY") === false) ? `mouth: ["${params.mouth.join('","')}"]` : `mouth: []`
          }
          ${
            (params.tail.includes("ANY") === false) ? `tail: ["${params.tail.join('","')}"]` : `tail: []`
          }
          axieClass: ${params.class.toUpperCase()}
          generation: ${params.generation}
          threshold: ${params.threshold}
          sellStrategy: ${params.sellStrategy}
          percentToProduceDesirableTraits: ${params.percentToProduceDesirableTraits}
          percentToProducePrinter: ${params.percentToProducePrinter}
          percentToProduceFloor: ${params.percentToProduceFloor}
          numberOfAxiesToBuy: ${params.numberOfAxiesToBuy}
          health: [${params.health}]
          speed: [${params.speed}]
          morale: [${params.moral}]
          skill: [${params.skill}]    
        ) {
          most_profitable {
            name
            investments
            sales
            profit
            profitMargin
          }
        }
      }`
    },
    headers:{
      'Authorization':`Bearer ${localStorage.getItem('appToken') || null}`
    }
  }).then(response=>{
    if(response.data.error !== undefined && response.data.error.toString().toLowerCase() === 'jwt expired') {
      refreshTokenHandler();
    }
    return response
  }).catch(error=>{
    if (error.response) {
      return error.response.data.error
    } else if (error.request) {
      return error.request;
    } else {
      return error.message;
    }
  });
}

export const CryptoUnicornsParametersQuery = () => {
  return axios({
    url: process.env.REACT_APP_GQL,
    method:"POST",
    data:{
      query:`{
        CryptoUnicornsParametersQuery {
          body {
            value
          }
          face {
            value
          }
          hooves {
            value
          }
          horn {
            value
          }
          mane {
            value
          }
          tail {
            value
          }
          unicornClass {
            value
          }
          lifecycle {
            value
          }
          mythic {
            value
          }
          lifecycle {
            enum
            value
          }
        }
      }`
    },
    headers:{
      'Authorization':`Bearer ${localStorage.getItem('appToken') || null}`
    }
  }).then(response=>{
    return response
  }).catch(error=>{
    if (error.response) {
      return error.response.data.error
    } else if (error.request) {
      return error.request;
    } else {
      return error.message;
    }
  }); 
}

export const CryptoUnicornSearcherQuery = (values, breedingpoints, power, endurance, speed, attackspeed, intelligence, accuracy, defense, magic, attack, movement, vitality, resistance) => {
  return axios({
    url: process.env.REACT_APP_GQL,
    method:"POST",
    data:{
      query:`{
        CryptoUnicornSearcherQuery(
          ${
            (values['Class'] !== undefined) ? (values['Class'].length !== 0) ? `unicornClass: [${values['Class']}]` : `` : ``
          }
          ${
            (values['Mythic'] !== undefined) ? (values['Mythic'].length !== 0) ? `mythic: [${values['Mythic']}]` : `` : ``
          }
          ${
            (values['Body'] !== undefined) ? (values['Body'].length !== 0) ? `body: [${values['Body']}]` : `` : ``
          }
          ${
            (values['Hooves'] !== undefined) ? (values['Hooves'].length !== 0) ? `hooves: [${values['Hooves']}]` : `` : ``
          }
          ${
            (values['Horn'] !== undefined) ? (values['Horn'].length !== 0) ? `horn: [${values['Horn']}]` : `` : ``
          }
          ${
            (values['Mane'] !== undefined) ? (values['Mane'].length !== 0) ? `mane: [${values['Mane']}]` : `` : ``
          }
          ${
            (values['Tail'] !== undefined) ? (values['Tail'].length !== 0) ? `tail: [${values['Tail']}]` : `` : ``
          }
          ${
            (values['Face'] !== undefined) ? (values['Face'].length !== 0) ? `face: [${values['Face']}]` : `` : ``
          }
          ${
            (values['Lifecycle'] !== undefined) ? (values['Lifecycle'].length !== 0) ? `lifecycle: [${values['Lifecycle']}]` : `` : ``
          }
          accuracy: [${accuracy}]
          attack: [${attack}]
          powerScore: [${power}]
          attackSpeed: [${attackspeed}]
          movementSpeed: [${movement}]
          speedScore: [${speed}]
          defense: [${defense}]
          vitality: [${vitality}]
          enduranceScore: [${endurance}]
          magic: [${magic}]
          resistance: [${resistance}]
          intelligenceScore: [${intelligence}]
          breedingScore: ${breedingpoints}
          sortBy: ${values.Sort}
        ) {
          results {
            id
            link
            name
            price
            breedingPoints
            powerScore
            speedScore
            enduranceScore
            intelligenceScore
            totalScore
          }
        }
      }`
    },
    headers:{
      'Authorization':`Bearer ${localStorage.getItem('appToken') || null}`
    }
  }).then(response=>{
    return response
  }).catch(error=>{
    if (error.response) {
      return error.response.data.error
    } else if (error.request) {
      return error.request;
    } else {
      return error.message;
    }
  }); 
}

export const CryptoUnicornSearchByIdQuery = (id) => {
  return axios({
    url: process.env.REACT_APP_GQL,
    method:"POST",
    data:{
      query:`{
        CryptoUnicornSearchByIdQuery(unicornId: ${id}) {
          unicornClass
          lifecycle
          mythic
          body
          face
          hooves
          horn
          mane
          tail
          breedingPoints
          attack
          accuracy
          powerScore
          attackSpeed
          movementSpeed
          speedScore
          defense
          vitality
          enduranceScore
          magic
          resistance
          intelligenceScore
          totalScore
        }
      }`
    },
    headers:{
      'Authorization':`Bearer ${localStorage.getItem('appToken') || null}`
    }
  }).then(response=>{
    return response
  }).catch(error=>{
    if (error.response) {
      return error.response.data.error
    } else if (error.request) {
      return error.request;
    } else {
      return error.message;
    }
  }); 
}

export const AdminLogin = (username, password) => {
  return axios({
    url: process.env.REACT_APP_GQL+'/login',
    method:"POST",
    data:{
      query:`{
        AdminLoginQuery(username: "${username}", password: "${password}") {
            accessToken
            refreshToken
          }
        }`
    }
  }).then(response=>{
    return response
  }).catch(error=>{
    if (error.response) {
      return error.response.data.error
    } else if (error.request) {
      return error.request;
    } else {
      return error.message;
    }
  }); 
}