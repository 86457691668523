import React, { useState, useContext, useEffect } from "react";
import { Space } from 'antd'

// import {
//   ApolloClient,
//   InMemoryCache
// } from "@apollo/client";

import {
	AdminLogin
} from '../../graphql/axios';

import {
  AppContext
} from "../../context/AppContext";

import jwt_decode from "jwt-decode";
import { Form, Input, Button, Row, Col } from 'antd';
import { ReactComponent as AppLogo } from '../../images/BD.svg';

// const authClient = new ApolloClient({
//   uri: process.env.REACT_APP_GQL + '/login',
//   cache: new InMemoryCache()
// });

const Login = () => {
	const state = useContext(AppContext);

	const [credentials,setCredentials] = useState({
		username:null,
		password:null
	})

  const [errors, setErrors] = useState(false)

	const getAuth = () => {
    AdminLogin(credentials.username, credentials.password)
      .then((data) => {
        const authResponse = data.data.data
        if (authResponse && authResponse.AdminLoginQuery) {
  
          const { role } = jwt_decode(authResponse.AdminLoginQuery.accessToken)
  
          localStorage.setItem("appToken", authResponse.AdminLoginQuery.accessToken)
          localStorage.setItem("refreshToken", authResponse.AdminLoginQuery.refreshToken)
          localStorage.setItem("role", role)
  
          authResponse && authResponse.AdminLoginQuery && state.setAppState({
            ...state,
            auth: {
              accessToken: authResponse.AdminLoginQuery.accessToken,
              refreshToken: authResponse.AdminLoginQuery.refreshToken,
              role:role
            }
          })
  
          window.location = "/admin/dashboard";
  
          setErrors(false)
        } else {
          setErrors({
            description: data.data.errors[0].message,
          })
        }
      })
  };

	const onFinish = (values) => {
		getAuth();
	};

	const onFinishFailed = (errorInfo) => {
    setErrors({
      description: 'Please try again later or contact software developer.',
    })
	};

	const setUsername = (e) =>{
		setCredentials({
			...credentials,
			username:	e.target.value
		})
	}

	const setPassword = (e) =>{
		setCredentials({
			...credentials,
			password:	e.target.value
		})
	}

  useEffect(()=> {
    setErrors(false)
  },[credentials])
    
  return (
    <div className="loginWrapper">
      <Row>
        <Col xs={24} xl={24} style={{paddingTop:'6em'}}>
          <Space direction="horizontal" style={{width: '100%', justifyContent: 'center'}}>
            <AppLogo />
          </Space>
        </Col>
      </Row>
      <Row>
        <Col xs={24} xl={{offset:6, span: 12}}>
          <Form
            style={{display:'block'}}
            layout="vertical"
            name="basic"
            labelCol={{
              span: 5,
            }}
            wrapperCol={{
              span: 16,
            }}
            // initialValues={{
            //   remember: true,
            // }}
            onFinish={onFinish}
            onFinishFailed={onFinishFailed}
            autoComplete="off"
            className={errors ? 'has-errors' : ''}
          >
            <Form.Item
              label="Username"
              name="username"
              onKeyUp={setUsername}
              // bordered={false}

              rules={[
                {
                  required: true,
                  message: 'Please input your username!',
                },
              ]}

              wrapperCol={{
                offset: 0,
                span: 24,
              }}
            >
              <Input placeholder="Enter your username" />
            </Form.Item>
            {
              errors && errors.description === 'User does not exist' &&
                <span className="bd-error">The username you entered does not exist.</span>
            }

            <Form.Item
              label="Password"
              name="password"
              onKeyUp={setPassword}
              // bordered={false}
              rules={[
                {
                  required: true,
                  message: 'Please input your password!',
                },
              ]}

              wrapperCol={{
                offset: 0,
                span: 24,
              }}
            >
              <Input.Password visibilityToggle={false} placeholder="Enter your password" />
            </Form.Item>
            {
              errors && errors.description === 'Incorrect password' &&
                <span className="bd-error">The password you entered is incorrect.</span>
            }

            <Form.Item
              wrapperCol={{
                offset: 0,
                span: 24,
              }}
          >
              <Button block htmlType="submit" className="ant-btn-primary ant-btn-lg btn-main ant-btn-lg">
                Log In
              </Button>
            </Form.Item>
          </Form>
        </Col>
      </Row>
    </div>
  	);
};

export default Login