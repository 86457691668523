import React, {useRef, useState} from 'react';
import { Button, Space, Select, Slider, notification, Checkbox } from 'antd';
import { BuildAlertAxios } from '../../../graphql/axios';
import { Link } from 'react-scroll';
import { CloseCircleOutlined } from '@ant-design/icons';

const { Option } = Select;

function CreateAlert(props) {
    const { updateList, count, deleteItem, selectData, showLoading} = props;
    const [inputValue, setInputValue] = useState('');
    const [isPreOriginChecked, setIsPreOriginChecked] = useState(true);
    const input = useRef({
        'isPreOrigin': isPreOriginChecked,
        'name': '',
        'class': '',
        'percentage': '',
        'skill': [27,61],
        'moral': [27,61],
        'health': [27,61],
        'speed': [27,61],
        'average': '',
        'back': '',
        'horn': '',
        'mouth': '',
        'tail': '',
        'eyes': false,
        'ears': false,
        'eyesClass': '',
        'earsClass': ''
    });

    const [health, setHealth] = useState([27,61]);
    const [moral, setMoral] = useState([27,61]);
    const [speed, setSpeed] = useState([27,61]);
    const [skill, setSkill] = useState([27,61]);


    const saveItem = (e) => {
        e.preventDefault();

        showLoading();

        input.current['name'] = e.target.name.value;
        input.current['percentage'] = e.target.percentage.value.replace("%", "");
        input.current['average'] = e.target.average.value;

        BuildAlertAxios.createItem(input.current).then((response) => {
            console.log('create response :', response);
            updateList();

            notification['success']({
                message: 'Success.',
                description: 'Your new build has been added to the list.',
            });

            deleteItem(false, count);
        });

    }
    
    const setValue = (value, a) => {
        input.current[a] = value;

        const stats = ["health", "moral", "skill", "speed"];

        if(stats.includes(a)) {
            if(a === 'health') setHealth(value);
            if(a === 'moral') setMoral(value);
            if(a === 'skill') setSkill(value);
            if(a === 'speed') setSpeed(value);
        }
    }   

    const onChange = (e) => {
        const value = e.target.value.toString().replace("%", "");
        input.current['percentage'] = value;
        setInputValue(value);
    }

    const onFocus = (e) => {
        const value = e.target.value.toString().replace("%", "");
        input.current['percentage'] = value;
        setInputValue(value);
    }

    const onBlur = (e) => {
        const value = e.target.value.toString().replace("%", "");
        input.current['percentage'] = value;
        setInputValue(value+"%");
    }
    
    const isPreOrigin = (e) => {
        setIsPreOriginChecked(e.target.checked);
        input.current['isPreOrigin'] = e.target.checked;
    }

    return (
        <>
            <div className="no-item padding-space" style={{padding: '30px', margin: '30px'}}>
                <form onSubmit={e => { saveItem(e) }} data-count={count}>
                    <table width={'100%'}>
                        <thead>
                            <tr>
                                <td className='padding-right' width="40%">
                                    <input type="hidden" name="action" value="create" />
                                    <input name="name" className='ant-input' style={{ width: '80%' }} placeholder="Axie Build Name" required /> <br />
                                    &nbsp;
                                </td>
                                <td width="5%">Parts</td>
                                <td className='padding-right' width={`30%`}>
                                    <Checkbox defaultChecked onChange={isPreOrigin} style={{color: "#fff"}} checked={isPreOriginChecked}>Select Pre-origin version</Checkbox>
                                </td>
                                <td>Latest Data</td>
                                <td width="5%"></td>
                            </tr>
                            <tr>
                                <td colSpan={5}>
                                    <hr />
                                    &nbsp;
                                </td>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td rowSpan={3}></td>
                                <td>Back</td>
                                <td className='padding-right'>
                                    <Select showSearch name="back" allowClear mode="multiple"  style={{ width: '100%' }} placeholder='Select Part' required onChange={(e) => {setValue(e, 'back')}}>
                                        <Option value="ANY">Any</Option>
                                        {
                                            selectData?.SearchParametersQuery && selectData?.SearchParametersQuery?.back.map((item, key) => <Option key={key} value={item.partId}>{item.name}</Option>)
                                        }
                                    </Select>
                                </td>
                                <td>
                                    Axie Liquidity
                                </td>
                                <td>-</td>
                            </tr>
                            <tr>
                                <td>Horn</td>
                                <td className='padding-right'>
                                    <Select showSearch name="horn" allowClear mode="multiple"  style={{ width: '100%' }} placeholder='Select Part' required onChange={(e) => {setValue(e, 'horn')}}>
                                        <Option value="ANY">Any</Option>
                                        {
                                            selectData?.SearchParametersQuery && selectData?.SearchParametersQuery?.horn.map((item, key) => <Option key={key} value={item.partId}>{item.name}</Option>)
                                        }
                                    </Select>
                                </td>
                                <td>
                                    Floor Price
                                </td>
                                <td>-</td>
                            </tr>
                            <tr>
                                <td>Mouth</td>
                                <td className='padding-right'>
                                    <Select showSearch name="mouth" allowClear mode="multiple"  style={{ width: '100%' }} placeholder='Select Part' required onChange={(e) => {setValue(e, 'mouth')}}>
                                        <Option value="ANY">Any</Option>
                                        {
                                            selectData?.SearchParametersQuery && selectData?.SearchParametersQuery?.mouth.map((item, key) => <Option key={key} value={item.partId}>{item.name}</Option>)
                                        }
                                    </Select>
                                </td>
                                <td>
                                    First 100 Axies Ave.
                                </td>
                                <td>-</td>
                            </tr>
                            <tr>
                                <td className='padding-right'>
                                    <Select showSearch name="class" style={{ width: '80%' }} placeholder='Select Class' required onChange={(e) => {setValue(e, 'class')}} >
                                        {
                                            selectData?.SearchParametersQuery && selectData?.SearchParametersQuery?.classes.map((item, key) => <Option key={key} value={item.name.toUpperCase()}><img src={`/assets/images/class/${item.name.toLowerCase()}.png`} /> {item.name}</Option>)
                                        }
                                    </Select>
                                </td>     
                                <td>Tail</td>
                                <td className='padding-right'>
                                    <Select showSearch name="tail" allowClear mode="multiple"  style={{ width: '100%' }} placeholder='Select Part' required onChange={(e) => {setValue(e, 'tail')}}>
                                        <Option value="ANY">Any</Option>
                                        {
                                            selectData?.SearchParametersQuery && selectData?.SearchParametersQuery?.tail.map((item, key) => <Option key={key} value={item.partId}>{item.name}</Option>)
                                        }
                                    </Select>
                                </td>
                                <td>
                                    First 200 Axies Ave.
                                </td>
                                <td>-</td>
                            </tr>
                            <tr>
                                <td className='padding-right'>
                                    <input value={inputValue} onChange={onChange} onFocus={onFocus} onBlur={onBlur} name="percentage" type="text" className='ant-input' style={{ width: '80%' }} placeholder="Threshold for Printer (%)" min={1} max={100} maxLength={3} />
                                </td>    
                                <td>{isPreOriginChecked ? 'Eyes Class' : 'Eyes'}</td>
                                <td className='padding-right'>
                                    <Select mode="multiple" allowClear name="eyes" style={{ width: '100%' }} placeholder={isPreOriginChecked ? 'Select Eyes Class' : 'Select Part'} required onChange={(e) => {setValue(e, isPreOriginChecked ? 'eyesClass' : 'eyes')}}>
                                        <Option value="ANY">Any</Option>
                                        {isPreOriginChecked ? 
                                            <>
                                                {
                                                    selectData.SearchParametersQuery && selectData.SearchParametersQuery.classes.filter(i=> !["Dusk","Dawn","Mech"].includes(i.name)).map((item, key) => <Option key={key} value={item.name.toUpperCase()}>{item.name}</Option>)
                                                }
                                            </>
                                            :
                                            <>  
                                                {
                                                    selectData?.SearchParametersQuery && selectData?.SearchParametersQuery?.eyes.map((item, key) => <Option key={key} value={item.partId}>{item.name}</Option>)
                                                }
                                            </>
                                        } 
                                    </Select>
                                </td>
                            </tr>
                            <tr>
                                <td className='padding-right'>
                                    <input type="number" name="average" className='ant-input' style={{ width: '80%' }} placeholder="Number of Printer Axies (X)" min={1} maxLength={3} />
                                </td>       
                                <td>{isPreOriginChecked ? 'Ears Class' : 'Ears'}</td>
                                <td className='padding-right'>
                                    <Select mode="multiple" allowClear name="ears" style={{ width: '100%' }} placeholder={isPreOriginChecked ? 'Select Ears Class' : 'Select Part'} required onChange={(e) => {setValue(e, isPreOriginChecked ? 'earsClass' : 'ears')}}>
                                        {isPreOriginChecked ? 
                                            <>
                                                {
                                                    selectData.SearchParametersQuery && selectData.SearchParametersQuery.classes.filter(i=> !["Dusk","Dawn","Mech"].includes(i.name)).map((item, key) => <Option key={key} value={item.name.toUpperCase()}>{item.name}</Option>)
                                                }
                                            </>
                                            :
                                            <>  
                                                {
                                                    selectData?.SearchParametersQuery && selectData?.SearchParametersQuery?.ears.map((item, key) => <Option key={key} value={item.partId}>{item.name}</Option>)
                                                }
                                            </>
                                        } 
                                    </Select>
                                </td>
                                <td>
                                    Printer Liquidity
                                </td>
                                <td>-</td>
                            </tr>
                            <tr>
                                <td className='padding-right' colSpan={3} style={{paddingTop: '15px'}}>
                                    HEALTH (Min {health[0]}, Max {health[1]})
                                </td>
                                <td>
                                    First 20 Printer Ave.
                                </td>
                                <td>-</td>
                            </tr>
                            <tr>
                                <td className='padding-right'>
                                    <Slider range min={27} max={61} defaultValue={[27, 61]} style={{ width: '80%' }} onChange={(e) => {setValue(e, 'health')}}/>
                                </td>
                                <td colSpan={2}></td>
                                <td>
                                    First 40 Printer Ave.
                                </td>
                                <td>-</td>
                            </tr>
                            <tr>
                                <td className='padding-right' colSpan={3} style={{paddingTop: '15px'}}>
                                    SPEED (Min {speed[0]}, Max {speed[1]})
                                </td>
                                <td>
                                    First 60 Printer Ave.
                                </td>
                                <td>-</td>
                            </tr>
                            <tr>
                                <td className='padding-right'>
                                    <Slider range min={27} max={61} defaultValue={[27, 61]} style={{ width: '80%' }} onChange={(e) => {setValue(e, 'speed')}}/>
                                </td>
                                <td colSpan={2}></td>
                                <td>
                                    X Printer Axies Ave.
                                </td>
                                <td>-</td>
                            </tr>
                            <tr>
                                <td className='padding-right' style={{paddingTop: '15px'}}>
                                    SKILL (Min {skill[0]}, Max {skill[1]})
                                    <Slider range min={27} max={61} defaultValue={[27, 61]} style={{ width: '80%' }} onChange={(e) => {setValue(e, 'skill')}}/>
                                </td>
                                <td></td>
                            </tr>
                            <tr>
                                <td className='padding-right' style={{paddingTop: '15px'}}>
                                    MORALE (Min {moral[0]}, Max {moral[1]})
                                    <Slider range min={27} max={61} defaultValue={[27, 61]} style={{ width: '80%' }} onChange={(e) => {setValue(e, 'moral')}}/>
                                </td>
                                <td></td>
                            </tr>
                            <tr>
                                <td colSpan={5}>
                                    &nbsp;
                                    <hr />
                                </td>
                            </tr>
                        </tbody>
                        <tfoot>
                            <tr>
                                <td>
                                    <br />
                                    &nbsp;
                                    <Link to="bottom" spy={true} smooth={true}>
                                        <Space>
                                            <a className="color-red" onClick={() => {deleteItem(false, count)}}>
                                                <CloseCircleOutlined style={{ fontSize: '20px', color: '#FF6767' }} theme="outlined" />
                                            </a>
                                        </Space>
                                    </Link>
                                </td>
                                <td></td>
                                <td></td>
                                <td></td>
                                <td> 
                                    <Button type="primary" htmlType="submit" className="btn btn-info" size='large'> SAVE </Button>
                                </td>
                            </tr>
                        </tfoot>
                    </table>
                </form>
            </div>
        </>
    )
}

export default CreateAlert;