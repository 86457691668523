import React, { createContext, useState, useContext } from "react";

export const AppContext = createContext({
    auth:{
      accessToken: localStorage.getItem('appToken') || null,
      role:localStorage.getItem('role') || "",
    },
    dashboard:{},
    breeding:{
      findFirstAxie:'',
      findSecondAxie:'',
      firstAxie: {},
      secondAxie: {},
      breedCount:0,
      firstAxieGene:null,
      secondAxieGene:null,
      modal: false,
    },
    breedingPool:{},
    setAppState : () =>{}
  });

export function AppContextProvider({children}) {
  const setAppState = (data) =>{
    setState(data);
  }

  const initialState = {
    auth:{
      accessToken: localStorage.getItem('appToken') || null,
      role:localStorage.getItem('role') || "",
    },
    dashboard:{},
    breeding:{
      findFirstAxie:'',
      findSecondAxie:'',
      firstAxie: {},
      secondAxie: {},
      breedCount:0,
      firstAxieGene:null,
      secondAxieGene:null,
      modal: false,
    },
    breedingPool:{},
    setAppState: setAppState
  };

  const [state,setState] = useState(initialState);

  return (
    <AppContext.Provider value={state}>
      {children}
    </AppContext.Provider>
  );
}