import React, { useState, useRef } from "react";
import { useQuery } from "@apollo/client";
import { Row, Col, Button, notification, Modal, Input, Select, Result } from 'antd';
import { ExclamationCircleOutlined } from '@ant-design/icons';
import { Link } from 'react-scroll';
import { PlusOutlined, LoadingOutlined } from '@ant-design/icons';
import { SearchParametersQuery } from '../../graphql/queries';
import { BuildAlertAxios } from '../../graphql/axios';
import CreateAlert from './crud/create';
import BuildList from './crud/list';
import ActionModal from './modal/action';
import { rates } from '../../utils/exchange';
import Header from '../../components/Header/Header'

const { confirm } = Modal;
const { Search } = Input;
const { Option } = Select;

const BuildAlert = (props) => {
    const [loading, setLoading] = useState(true);
    const [disable, setDisable] = useState(false);
    const [loadingHome, setLoadingHome] = useState(true);
    const [actionText, setActionText] = useState('');
    const [deleteID, setDeleteID] = useState(false);
    const [openModalStatus, setOpenModalStatus] = useState(false);
    const [apiResponse, setApiResponse] = useState([]);
    const count = useRef(0);
    const addForm = useRef([]);
    const isCallData = useRef(false);
    const [forms, addForms] = useState([]);
    const blist = useRef([]);
    const [itemList, setItemList] = useState([]);
    const { data } = useQuery(SearchParametersQuery);
    const exchangeRates = useRef({});
    const isCallRate = useRef(false);
    const waitTime = 500;  

    let timer;            

    if(isCallRate.current === false){
        rates(['ethereum'],['usd']).then((response) => {
            exchangeRates.current = response.data;
            isCallRate.current = true;
        });
    }

    const getList = () => {
        if(isCallData.current === false){
            setLoadingHome(true);
            BuildAlertAxios.listItem().then((response) => {
                const mappedData = response?.data?.data?.BuildAlertsQuery?.results ? response?.data?.data?.BuildAlertsQuery?.results : [];

                setApiResponse(mappedData);
                setList(mappedData);

                if(blist.current.length >= 100) {
                    setDisable(true);
                }

                setOpenModalStatus(false);
                setLoading(false);
                setLoadingHome(false);
            });

            isCallData.current = true;
        }
    }

    const showLoading = () => {
        setOpenModalStatus(true);
        setLoading(true);
    }

    const updateList = () => {
        console.log("Updating List");
        isCallData.current = false;
        blist.current = [];
        setItemList([]);
        getList();
    }

    const closeModal = () => {
        setOpenModalStatus(false);
        setLoading(false);
        setLoadingHome(false);
    }
    
    const setList = (mappedData) => {
        let count = 0;

        setItemList([]);
        for(let item in mappedData){
            count += 1;
            
            let dataInputs = mappedData[item];
            if(dataInputs.isPreOrigin === null) {
                dataInputs['isPreOrigin'] = true;
            }
            if(dataInputs.eyes.length === 0) {
                dataInputs['eyes'] = ['ANY'];
            }
            if(dataInputs.ears.length === 0) {
                dataInputs['ears'] = ['ANY'];
            }
            if(dataInputs.eyesClass.length === 0) {
                dataInputs['eyesClass'] = ['ANY'];
            }
            if(dataInputs.earsClass.length === 0) {
                dataInputs['earsClass'] = ['ANY'];
            }
            if(dataInputs.tail.length === 0) {
                dataInputs['tail'] = ['ANY'];
            }
            if(dataInputs.back.length === 0) {
                dataInputs['back'] = ['ANY'];
            }
            if(dataInputs.horn.length === 0) {
                dataInputs['horn'] = ['ANY'];
            }
            if(dataInputs.mouth.length === 0) {
                dataInputs['mouth'] = ['ANY'];
            }       

            blist.current = [...blist.current, {'form_count': 'saved_'+mappedData[item], 'component' : <BuildList delayCount={count} exchangeRates={exchangeRates} closeModal={closeModal} showLoading={showLoading} updateList={updateList} dataInputs={mappedData[item]} dataId={mappedData[item].id} count={'saved_'+item} deleteItem={deleteItem} selectData={data} />}];
        }

        setItemList(blist.current);
    }

    const addNew = () =>{
        count.current += 1;
        const fCount = count.current;
        const fCounts = addForm.current.length;

        if((fCounts + itemList.length) >= 100) {
            setDisable(true);
            
            notification['error']({
                message: 'Build Count Error.',
                description:
                  'You have reach the maximum 50 builds allowed to create.',
            });

            return;
        }

        addForm.current = [...addForm.current, {'form_count': fCount, 'component' : <CreateAlert showLoading={showLoading} updateList={updateList} count={fCount} deleteItem={deleteItem} selectData={data} />}];
        
        addForms(addForm.current);
    }

    const filterSearch = (searchText, type) => {
        clearTimeout(timer);
        setLoadingHome(true);

        let text = searchText !== undefined ? searchText.toString().toLowerCase(): '';

        function fake() {
            let mappedData = apiResponse.filter(obj => Object.values(obj).some(val => val?val.toString().toLowerCase().includes(text):false));

            if(type === 'class' && text !== '') mappedData = apiResponse.filter(obj => obj.class.toString().toLowerCase() === text);
            if(type === 'sort' && text !== '') mappedData = apiResponse.reverse();

            setList([]);
            blist.current = [];
    
            setList(mappedData);
            setLoadingHome(false);
        }

        timer = setTimeout(() => { fake(text) }, waitTime);
    }

    const catchEmpty = (text) => {
        console.log(text);
        if(text === '' || text === undefined) filterSearch(text, 'text');
    }

    const deleteItem = (id, key) =>{
         if(id === false) {
            let newArr = [];
    
            for(let i in addForm.current) {
                if(addForm.current[i].form_count !== key) {
                    newArr.push(addForm.current[i]);
                }
            }
    
            addForm.current = newArr;
            addForms(addForm.current);

        const fCounts = addForm.current.length;
        if((fCounts + itemList.length) <= 50) {
            setDisable(false);
        }

        } else {
            setDeleteID(id);

            confirm({
                title: 'Are you sure you want to delete this build ?',
                icon: <ExclamationCircleOutlined />,
                // content: 'Some descriptions',
                okText: 'Yes',
                okType: 'danger',
                cancelText: 'No',
                onOk() {
                  setLoadingHome(true);
                  setDisable(false);
                  deleteRequest(id);
                },
                onCancel() {
                  console.log('Cancel');
                },
            });
            // setActionText('Are you sure you want to delete this build ?');
            // setOpenModalStatus(true);
            
        }
    }

    const deleteRequest = (id) =>{
        setLoading(true);
        BuildAlertAxios.deleteItem(id).then((response) => {

            console.log("delete response: ", response);
            isCallData.current = false;
            blist.current = [];
            setItemList([]);
            getList();
            setOpenModalStatus(false);
            setLoading(false);
            
            notification['success']({
                message: 'Success deletion.',
                description:
                  'Selected build has been deleted from the list',
            });
        });
    }

    const onCloseModal = () => {
        setOpenModalStatus(false);
    }

    if(data && data.SearchParametersQuery) {
        getList();
    }

    return (
        <>
            <Header title='Build Alerts' />
            <Row>
                <Col xs={24} xl={24}>
                    <div className="padding-space-top">
                        <table width={'100%'}>
                            <tr>
                                <td width={'50%'}>
                                    <Link to="bottom" spy={true} smooth={true} style={{marginLeft: '0px'}}>
                                        <Button type="primary" className="btn btn-info" icon={<PlusOutlined style={{ fontSize: '20px' }} theme="outlined" />} onClick={() => {addNew()}} disabled={disable} size='large'>
                                            ADD NEW BUILD
                                        </Button>
                                    </Link>
                                </td>
                                <td width={'10%'}>
                                    Total Build <b>{itemList.length}</b>
                                </td>
                                <td width={'10%'}>
                                    <Select size={'large'} defaultValue={'DESC'} name="order" style={{ width: '100%' }} placeholder='Sort Order' onChange={(e) => filterSearch(e, 'sort')} >
                                        <Option value={'DESC'}>Descending Order</Option>
                                        <Option value={'ASC'}>Ascending Order</Option>
                                    </Select>
                                </td>
                                <td width={'10%'} style={{paddingLeft: '10px'}}>
                                    <Select size={'large'} allowClear showSearch name="class" style={{ width: '100%' }} placeholder='Filter by Class' onChange={(e) => filterSearch(e, 'class')} >
                                        {
                                            data?.SearchParametersQuery && data?.SearchParametersQuery?.classes.map((item, key) => <Option key={key} value={item.name.toUpperCase()}><img src={`/assets/images/class/${item.name.toLowerCase()}.png`} /> {item.name}</Option>)
                                        }
                                    </Select>
                                </td>
                                <td width={'20%'} style={{paddingLeft: '10px'}}>
                                    <Search size={'large'} placeholder="Search Build Name" style={{ width: '100%' }} onChange={(e) => catchEmpty(e.target.value)} onSearch={(e) => filterSearch(e, 'text')} enterButton />
                                </td>
                            </tr>
                        </table>
                    </div>
                </Col>
            </Row>
            <Row>
                <ActionModal openModalStatus={openModalStatus} isLoading={loading} proceedFunction={deleteRequest} deleteID={deleteID} titleText={actionText}  onCloseModal={onCloseModal} />
                <Col xs={24} xl={24}>
                    { 
                    (loadingHome) ? 
                        <>
                            <div className="text-center">
                                <LoadingOutlined style={{ fontSize: '50px'}} theme="outlined" className="text-center"  /><br />
                                Loading list
                            </div>
                        </>
                     : 
                     <>
                        { 
                            itemList.length === 0 &&  
                            <div className="text-center nores">
                                <Result
                                    title="No results found."
                                />        
                            </div>
                        }
                        { itemList.length !== 0 && itemList.map(a => a.component) }
                        {forms.map(a => a.component)}
                        <br />
                        <Link to="bottom" spy={true} smooth={true}>
                            <Button type="primary" className="btn btn-info margin-left" icon={<PlusOutlined style={{ fontSize: '20px' }} theme="outlined" />} onClick={() => {addNew()}} disabled={disable} size='large'>
                                ADD NEW BUILD
                            </Button>
                        </Link>
                        <br />
                        <br />
                     </>
                    }
                </Col>
            </Row>
            <div id="bottom"></div>
            <br />
        </>
    )
}

export default BuildAlert