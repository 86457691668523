import React from 'react';
import Modal from 'react-modal';
import { Button } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';
import Bounce from 'react-reveal/Bounce';

const customStyles = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)'
  },
};

Modal.setAppElement('#root');

function ActionModal({titleText, openModalStatus, proceedFunction, isLoading, onCloseModal, deleteID}) {
    return (
        <div>
            <Modal
                isOpen={openModalStatus}
                onRequestClose={onCloseModal}
                style={customStyles}
                contentLabel="Action Modal" 
            > 
                { isLoading && (
                    <div className="text-center">
                        <LoadingOutlined style={{ fontSize: '50px'}} theme="outlined" className="text-center"  /><br />
                        <h2 style={{textAlign: 'center'}}>Loading please wait ...</h2>
                    </div>
                )}

                { !isLoading && (
                    <>
                        <h2>{titleText}</h2>
                        <br /> 
                        
                        <Bounce>
                            <Button type="primary" className="btn btn-info align-right margin-left" onClick={() => {proceedFunction(deleteID)}}>PROCEED</Button>
                            <Button type="default" className="btn btn-info align-left" onClick={onCloseModal}>CANCEL</Button> 
                        </Bounce>
                    </>
                )}
            </Modal>
        </div>
    );
}

export default ActionModal