import React, { useState } from "react";

import { Typography, Row, Col, Form, Input, Button, Card, message} from 'antd';
import { LoadingOutlined } from '@ant-design/icons';
import styles from './CrabadaPartCalculator.module.scss';
import { ReactComponent as IconPincers } from '../../images/icons/crabada/crabada-pincers.svg';
import { ReactComponent as IconBody } from '../../images/icons/crabada/crabada-body.svg';
import { ReactComponent as IconShell } from '../../images/icons/crabada/crabada-shell.svg';
import { ReactComponent as IconMouth } from '../../images/icons/crabada/crabada-mouth.svg';
import { ReactComponent as IconEyes } from '../../images/icons/crabada/crabada-eyes.svg';
import { ReactComponent as IconHorn } from '../../images/icons/crabada/crabada-horn.svg';
import { CalculateCrabadaParts } from '../../graphql/axios';
import Header from '../../components/Header/Header'

const { Title } = Typography;

const CrabadaPartCalculator = (props) => {
  const [crabAId, setCrabAId] = useState('');
  const [crabBId, setCrabBId] = useState('');
  const [crabA, setCrabA] = useState({});
  const [crabB, setCrabB] = useState({});
  const [data, setData] = useState({});
  const [loading, setLoading] = useState(false);

  const calculate = () => {
    if(crabAId && crabBId) {
      // Fetch data here
      showLoading()
      CalculateCrabadaParts({crabAId, crabBId}).then(({data}) => {
        if(data.errors) {
          data.errors.forEach(error => {
            message.error(error.message);
          });
          
          setData({})
          setCrabA({})
          setCrabB({})
        } else {
          setData(data?.data?.probabilities?.results ? data.data.probabilities.results : {})
          setCrabA(data?.data?.crabA)
          setCrabB(data?.data?.crabB)
        }
        stopLoading()
      })
    } else {
      setData({})
    }
  }

  const showLoading = () => {
    setLoading(true);
  }

  const stopLoading = () => {
      setLoading(false);
  }

  return(
    <>
      <Header title='Crabada Part Calculator' description={'Discover what you can breed from different parent crabs.'} />
      <Row>
        <Title level={3}>
          Enter the Crab IDs
        </Title>
      </Row>
      <Form className='white'>
        <Row gutter={40}>
          <Col xs={24} sm={24} md={24} lg={8}>
            <Form.Item>
                <Input  type="text" name="crab_id[]" value={crabAId} onInput={e => setCrabAId(e.target.value)} className='ant-input' placeholder="Enter Crab ID 1" />
            </Form.Item>
          </Col>
          <Col xs={24} sm={24} md={24} lg={8}>
            <Form.Item>
                <Input  type="text" name="crab_id[]" value={crabBId} onInput={e => setCrabBId(e.target.value)} className='ant-input' placeholder="Enter Crab ID 2" />
            </Form.Item>
          </Col>
          <Col xs={24} sm={24} md={24} lg={8}>
            <Button type="primary" htmlType="submit" size="large" className="btn btn-info ant-btn-lg" block onClick={calculate}>Calculate</Button>
          </Col>
        </Row>
      </Form>
      {
        (loading) ? 
        <>
            <div className="text-center">
                <LoadingOutlined style={{ fontSize: '50px'}} theme="outlined" className="text-center"  /><br />
                Initializing Results
            </div>
        </> :
        Object.keys(data).length !== 0 && (
        <div className={styles.resultWrapper}>
          <Row>
            <Col xs={24} sm={24} md={24} lg={24}>
              <Title level={3}>
                Results
              </Title>
            </Col>
          </Row>
          <Row gutter={40} className={styles.cardContainer}>
            <Col xs={24} sm={24} md={24} lg={8}>
              <Title level={4}>
                CRAB ID 1
              </Title>

              <Card className={styles.crabCard} bordered={false}>
                <div className={styles.crabPreview}>
                  <img src={crabA?.image} />
                </div>
                <div className={styles.crabInfo}>
                  <span>Class</span>
                  <span className={styles['crabClass--' + crabA?.class_name.toLowerCase()]}>{crabA?.class_name}</span>

                  <span>Breed Count</span>
                  <span>{crabA?.breed_count}</span>

                  <span>Purity</span>
                  <span>{crabA?.pure_number}</span>
                </div>
              </Card>
            </Col>
            <Col xs={24} sm={24} md={24} lg={8}>
              <Title level={4}>
                CRAB ID 2
              </Title>

              <Card className={styles.crabCard} bordered={false}>
                <div className={styles.crabPreview}>
                  <img src={crabB?.image} />
                </div>
                <div className={styles.crabInfo}>
                <span>Class</span>
                  <span className={styles['crabClass--' + crabB?.class_name.toLowerCase()]}>{crabB?.class_name}</span>

                  <span>Breed Count</span>
                  <span>{crabB?.breed_count}</span>

                  <span>Purity</span>
                  <span>{crabB?.pure_number}</span>
                </div>
              </Card>
            </Col>
            <Col xs={24} sm={24} md={24} lg={8}>
              <Title level={4}>
                CLASS LEGEND
              </Title>

              <Card className={styles.classLegend} bordered={false}>
                <span className={styles.surge}><i></i>Surge</span>
                <span className={styles.craboid}><i></i>Craboid</span>
                <span className={styles.sunken}><i></i>Sunken</span>
                <span className={styles.ruined}><i></i>Ruined</span>
                <span className={styles.prime}><i></i>Prime</span>
                <span className={styles.gem}><i></i>Gem</span>
                <span className={styles.bulk}><i></i>Bulk</span>
                <span className={styles.organic}><i></i>Organic</span>
              </Card>
            </Col>
          </Row>

          <Row>
            <Col xs={24} sm={24} md={24} lg={24}>
              <Title level={3}>
                Probabilities
              </Title>
            </Col>
          </Row>
          <Row gutter={40} className={styles.cardContainer}>

            <Col xs={24} sm={24} md={24} lg={8}>
              <Card title={<Title level={4}>Pincers <IconPincers /></Title>} className={`${styles.infoCard} ${styles.pincers}`}>
                {
                  data.pincer && data.pincer.map((item, i) => 
                    <>
                      <span className={styles[item.class.toLowerCase()]}><i></i>{item.part}</span>
                      <span>{item.score}%</span>
                    </>
                  )
                }
              </Card>
            </Col>

            <Col xs={24} sm={24} md={24} lg={8}>
              <Card title={<Title level={4}>Body <IconBody /></Title>} className={`${styles.infoCard} ${styles.pincers}`}>
                {
                  data.pincer && data.body.map((item, i) => 
                    <>
                      <span className={styles[item.class.toLowerCase()]}><i></i>{item.part}</span>
                      <span>{item.score}%</span>
                    </>
                  )
                }
              </Card>
            </Col>

            <Col xs={24} sm={24} md={24} lg={8}>
              <Card title={<Title level={4}>Shell <IconShell /></Title>} className={`${styles.infoCard} ${styles.pincers}`}>
              {
                data.pincer && data.shell.map((item, i) => 
                  <>
                    <span className={styles[item.class.toLowerCase()]}><i></i>{item.part}</span>
                    <span>{item.score}%</span>
                  </>
                )
              }
              </Card>
            </Col>
          </Row>

          <Row gutter={40} className={styles.cardContainer}>

            <Col xs={24} sm={24} md={24} lg={8}>
              <Card title={<Title level={4}>Mouth <IconMouth /></Title>} className={`${styles.infoCard} ${styles.pincers}`}>
              {
                data.pincer && data.mouth.map((item, i) => 
                  <>
                    <span className={styles[item.class.toLowerCase()]}><i></i>{item.part}</span>
                    <span>{item.score}%</span>
                  </>
                )
              }
              </Card>
            </Col>

            <Col xs={24} sm={24} md={24} lg={8}>
              <Card title={<Title level={4}>Eyes <IconEyes /></Title>} className={`${styles.infoCard} ${styles.pincers}`}>
              {
                data.pincer && data.eyes.map((item, i) => 
                  <>
                    <span className={styles[item.class.toLowerCase()]}><i></i>{item.part}</span>
                    <span>{item.score}%</span>
                  </>
                )
              }
              </Card>
            </Col>

            <Col xs={24} sm={24} md={24} lg={8}>
              <Card title={<Title level={4}>Horn <IconHorn /></Title>} className={`${styles.infoCard} ${styles.pincers}`}>
                {
                  data.pincer && data.horn.map((item, i) => 
                    <>
                      <span className={styles[item.class.toLowerCase()]}><i></i>{item.part}</span>
                      <span>{item.score}%</span>
                    </>
                  )
                }
              </Card>
            </Col>
          </Row>
        </div>
      )}
    </>
  )
}

export default CrabadaPartCalculator
