import React from 'react';
import {
  useQuery
} from "@apollo/client";

import { DashboardQuery } from '../../graphql/queries';
import ChartCard from "../../components/ChartCard";
import SupplyDemandTable from "../../components/SupplyDemandTable";
import BreedingStrategyTable from "../../components/BreedingStrategyTable";
import CrabadaChart from "../../components/CrabadaChart";
import { Breadcrumb, Typography, Row, Col } from 'antd';
import Header from '../../components/Header/Header'

const { Title } = Typography;

const Dashboard = (props) => {
  const {
    data,
    loading
  } = useQuery(DashboardQuery);
  
  let tableData=[];
  
  if(data && data.AxieMarketQuery)//if(data && data.AxieMarketQuery && floorPrice && floorPrice.data && floorPrice.data.AxiesQuery)
  {
    tableData = [{
          name: 'Axie Infinity',
          supply_24hr: data.AxieMarketQuery.marketStats.last24Hours.supply,
          demand_24hr: data.AxieMarketQuery.marketStats.last24Hours.demand,
          sdratio: data.AxieMarketQuery.marketStats.last24Hours.supply / data.AxieMarketQuery.marketStats.last24Hours.demand,
          supply_7d: data.AxieMarketQuery.marketStats.last7Days.supply,
          demand_7d: data.AxieMarketQuery.marketStats.last7Days.demand/7,
          sdratio_7d: data.AxieMarketQuery.marketStats.last7Days.supply / (data.AxieMarketQuery.marketStats.last7Days.demand/7),
          floor_7d: data.AxieMarketQuery.marketStats.floorPrice
    }];
  }

  return(
    <>
      <Header title='Dashboard' />
      <Row>
        <Col xs={24} xl={8}>
          <ChartCard
          title="Total AUM"
          amount={500}
          rate={100.00}
          currency={"$ 1.5M"}
          data={[1,3,2,5,4,7,6,3,9]}>
          </ChartCard>
        </Col>
        <Col xs={24} xl={8}>
          <ChartCard
          title="Total Revenue"
          amount={500}
          rate={100.00}
          currency={"$ 1.5M"}
          data={[1,3,2,5,4,7,6,3,9]}>
          </ChartCard>
        </Col>
        <Col xs={24} xl={8}>
          <ChartCard
          title="Net Profit"
          amount={500}
          rate={100.00}
          currency={"$ 1.5M"}
          data={[1,3,2,5,4,7,6,3,9]}>
          </ChartCard>
        </Col>
      </Row>
      <div>&nbsp;</div>
      <Row>
        <Col xs={24} xl={24}>
          <CrabadaChart />
        </Col>
      </Row>
      <Row>
        <Col xs={24} xl={24}>
          <SupplyDemandTable data={tableData} loading={loading}>
          </SupplyDemandTable>
        </Col>
      </Row>
      <Row>
        <Col xs={24} xl={24}>
          <BreedingStrategyTable>
          </BreedingStrategyTable>
        </Col>
      </Row>
      <br/>
    </>
  )
}

export default Dashboard
