import React, { useState, useRef } from 'react';
import _ from "lodash";
import { Column } from '@ant-design/plots';
import { Typography } from 'antd';
import { CrabadaChartQueryAxios } from '../graphql/axios';
import { Row, Col, Form, Select } from 'antd';

const { Title } = Typography;
const { Option } = Select;
const CrabadaChart = () => {
    const virgin = ['true','false'];
    const purity = [0,1,2,3,4,5,6];
    const isCallData = useRef(false);
    const classes = ['SURGE', 'SUNKEN', 'PRIME', 'BULK', 'CRABOID', 'RUINED', 'GEM', 'ORGANIC'];
    const [chartItems, setChartItems] = useState([]);
    const items = useRef({'class': 'BULK', 'stage': 'ADULT', 'purity': 6, 'virgin': true});

    const filterData = () => {
        if(isCallData.current === false){
            const toUpdate1 = {'saletype': 'SOLD', 'class':items.current.class, 'stage': items.current.stage, 'purity': items.current.purity, 'virgin': items.current.virgin}
            const toUpdate2 = {'saletype': 'LISTED', 'class':items.current.class, 'stage': items.current.stage, 'purity': items.current.purity, 'virgin': items.current.virgin}

            CrabadaChartQueryAxios(toUpdate1).then((response) => {
                const mappedData = response?.data?.data?.CrabadaChartQuery?.results ? response?.data?.data?.CrabadaChartQuery?.results : [];
                setChartItems(chartItems => [...chartItems, ...mappedData.map(v => ({...v, saletype: 'SOLD'}))]);
            });

            CrabadaChartQueryAxios(toUpdate2).then((response) => {
                const mappedData = response?.data?.data?.CrabadaChartQuery?.results ? response?.data?.data?.CrabadaChartQuery?.results : [];
                setChartItems(chartItems => [...chartItems, ...mappedData.map(v => ({...v, saletype: 'LISTED'}))]);
            });

            isCallData.current = true;
        }
    }

    const reloadDataWithFilter = name => value => {
        isCallData.current = false;
        items.current[name] = value;
        setChartItems([]);
        filterData();
    }

    const ChartData = () => {
        filterData();
        let finalData = chartItems.filter((v,i,a)=>a.findIndex(t=>(t.date === v.date && t.saletype===v.saletype && t.total===v.total))===i);
            finalData = _.sortBy(finalData, 'date');//finalData.sort(function (x, y) { return y.date - x.date; });

        let data = finalData;

        if(data.length === 0){
            data = [
                {
                    date: 'No Data Found',
                    saletype: 'SOLD',
                    total: 0,
                },
                {
                    date: 'No Data Found',
                    saletype: 'LISTED',
                    total: 0,
                },
            ]
        }

        const config = {
            data,
            isGroup: true,
            xField: 'date',
            yField: 'total',
            seriesField: 'saletype',
            label: {
                position: 'middle',
                layout: [
                    {
                        type: 'interval-adjust-position',
                    }, 
                    {
                        type: 'interval-hide-overlap',
                    },
                    {
                        type: 'adjust-color',
                    }
                ],
            },
        }
    
        return <Column {...config} />;
    }

	return(
        <>
            <Title level={3} className="table-title">
                Crabada Weekly Report
            </Title>
            
            <Row>
                <Col xs={24} xl={24}>
                    <Row gutter={24}>
                        <Col span={6} key={1}>
                            <Form.Item
                                name={`class`}
                                label={`Class`}
                            >
                                <Select name="class" placeholder="Select Class" onChange={reloadDataWithFilter('class')} defaultValue={'BULK'}>
                                    { 
                                        classes.map(
                                            (i)=>{
                                                return <Option value={i}>{i}</Option>
                                            }
                                        )
                                    }
                                </Select>
                            </Form.Item>
                        </Col>
                        <Col span={6} key={3}>
                            <Form.Item
                                name={`virgin`}
                                label={`Virgin`}
                            >
                                <Select name="virgin" placeholder="Is Virgin ?" onChange={reloadDataWithFilter('virgin')} defaultValue={'true'}>
                                    { 
                                        
                                        virgin.map(
                                            (i)=>{
                                                return <Option value={i}>{i.toString().toUpperCase()}</Option>
                                            }
                                        )
                                    }
                                </Select>
                            </Form.Item>
                        </Col>
                        <Col span={6} key={4}>
                            <Form.Item
                                name={`purity`}
                                label={`Purity`}
                            >
                                <Select name="purity" placeholder="Select Purity" onChange={reloadDataWithFilter('purity')} defaultValue={'6'}>
                                    { 
                                        
                                        purity.map(
                                            (i)=>{
                                                return <Option value={i}>{i}</Option>
                                            }
                                        )
                                    }
                                </Select>
                            </Form.Item>
                        </Col>
                    </Row>
                </Col>
            </Row>
            <ChartData />
        </>
    )
}
    
export default CrabadaChart;