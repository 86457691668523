import React, { useState, useRef, useMemo } from "react";
import _ from "lodash";
import { useQuery } from "@apollo/client";
import { SearchParametersQuery } from '../../graphql/queries';
import { AxieGene } from "agp-npm/dist/axie-gene";
import { SearcherAlgoQueryAxios } from '../../graphql/axios';
import { Typography, Row, Col, Table, Button, Form, Select, InputNumber, Input, Slider, Checkbox } from 'antd';
import { ReactComponent as ExternalLinkIcon } from '../../images/icons/external-link.svg';
import { rates } from '../../utils/exchange';
import Header from '../../components/Header/Header'
import styles from './SearchAxieV2.module.scss';

const { Title } = Typography;
const { Option } = Select;

const SearchAxieV2 = (props) => {
    const [loading, setLoading] = useState(false);
    const [form] = Form.useForm();
    const axieClass = useRef([]);
    const eyesClass = useRef([]);
    const earsClass = useRef([]);
    const axieParts = useRef([]);
    const breedCount = useRef('');
    const purity = useRef('');
    const threshold = useRef('');
    const sort = useRef('PRICE_ASC');
    const [searchResults, setSearchResults] = useState([]);
    const { data } = useQuery(SearchParametersQuery);
    const exchangeRates = useRef({});
    const isCallRate = useRef(false);
    const [isPreOriginChecked, setIsPreOriginChecked] = useState(true);
    const isPreOriginStat = useRef(true);

    const [health, setHealth] = useState([27,61]);
    const [moral, setMoral] = useState([27,61]);
    const [speed, setSpeed] = useState([27,61]);
    const [skill, setSkill] = useState([27,61]);

    if(isCallRate.current === false){
        rates(['ethereum'],['usd']).then((response) => {
            exchangeRates.current = response.data;
            isCallRate.current = true;
        });
    }

    const setValue = (value, a) => {
        const stats = ["health", "moral", "skill", "speed"];

        if(stats.includes(a)) {
            if(a === 'health') setHealth(value);
            if(a === 'moral') setMoral(value);
            if(a === 'skill') setSkill(value);
            if(a === 'speed') setSpeed(value);
        }
    }   

    const onFinish = (values) => {
        const { fieldSort, fieldPurity, fieldBreedCount, fieldThreshold } = values;

        let parts = [];
        const classes = [...new Set(axieClass.current)];

        for(let a in axieParts.current) {
            for(let p in axieParts.current[a]) {
                parts.push(axieParts.current[a][p]);
            }
        }

        let pagination = 0;
        let axieResults = [];

        setLoading(true);

        // const lastPage = 6600;

        // let reqCall = 0;
        // let reqDone = 0;
            axieResults = [];
            pagination = 0;

        const searchData = (fieldSort) => {
            SearcherAlgoQueryAxios(classes, parts, eyesClass.current, earsClass.current, fieldBreedCount != null ? fieldBreedCount : null, fieldPurity ? fieldPurity : null, pagination, fieldSort, health, skill, moral, speed)
                .then((result) => {
                    const results = result?.data?.data?.BreederAlgoSearcherQuery?.results ? result?.data?.data?.BreederAlgoSearcherQuery?.results : [];
                    
                    axieResults.push(...results);
                    done(axieResults, values);
                }
            );
        }

        for (var i = 0; i <= 1; i ++) {
            if(i === 0) searchData(fieldSort);
            if(i === 1 && fieldSort === 'PRICE_DESC') searchData('PRICE_ASC');
            if(i === 1 && fieldSort === 'PRICE_ASC') searchData('PRICE_DESC');
            if(i === 1 && fieldSort === 'ID_DESC') searchData('ID_ASC');
            if(i === 1 && fieldSort === 'ID_ASC') searchData('ID_DESC');
        }

        sort.current = fieldSort;
        breedCount.current = fieldBreedCount;
        purity.current = fieldPurity;
        threshold.current = fieldThreshold;
    }

    const done = (axieResults, values) => {
        axieResults = _.uniqBy(axieResults, "id")
        if (sort.current === 'PRICE_ASC') {
            axieResults = _.orderBy(axieResults, "ethPrice", "asc");
        } else if (sort.current === 'PRICE_DESC') {
            axieResults = _.orderBy(axieResults, "ethPrice", "desc");
        } else if (sort.current === 'ID_ASC') {
            axieResults = _.orderBy(axieResults, "id", "asc");
        } else if (sort.current === 'ID_DESC') {
            axieResults = _.orderBy(axieResults, "id", "desc");
        }

        axieResults = axieResults.map(i => {
            i.geneData = new AxieGene(i.genes) //parse Genes
            i['ethPrice'] = parseFloat(i.ethPrice).toFixed(3) 
            // i['usdPrice'] = parseFloat(i.ethPrice * exchangeRates.current.ethereum.usd).toFixed(3) 
            return i
        })
        
        if(threshold.current > 0 && threshold.current !== null)
        {
            axieResults = axieResults.filter(i => i.purityScore >= threshold.current)
        }
        
        
        setSearchResults(axieResults);
        setLoading(false);
    }
    
    const columns = [
        {
            title: 'ID',
            dataIndex: 'id',
            key: 'id',
            render: (text) => <a target="_blank" href={`https://marketplace.axieinfinity.com/axie/${text}`}>
                {text} 
                <ExternalLinkIcon style={{
                    verticalAlign: 'middle',
                    transform: 'translateY(-2px)',
                    marginLeft: '5px',
                }} />
            </a>
        },
        {
            title: 'Name',
            dataIndex: 'name',
            key: 'name',
        },
        {
            title: 'Price Ξ',
            dataIndex: 'ethPrice',
            key: 'ethPrice',
            width: 200,
        },
        {
            title: 'Price $',
            dataIndex: 'usdPrice',
            key: 'usdPrice',
            width: 200,
        },
        {
            title: 'Breed Count',
            dataIndex: 'breedCount',
            key: 'breedCount',
            width: 200,
        },
        {
            title: 'Breeder Algo %',
            dataIndex: 'purityScore',
            key: 'purityScore',
            sorter: (a,b) => a.purityScore - b.purityScore,
            width: 200,
        },
    ]
    
    // const layout = { labelCol: { span: 8 }, wrapperCol: { span: 16 } };

    const handleParts = name => value => {
        if(name === 'eyesClass') eyesClass.current = value;
        else if(name === 'earsClass') earsClass.current = value;
        else axieParts.current[name] = value;
    }

    const handleClass = (value) => {
        axieClass.current = value;
    }

    const onChangeTable = (pagination, filters, sorter, extra) => {
      //console.log('params', extra);
      const {currentDataSource} = extra
      setSearchResults(currentDataSource)
    }

    const onReset = () => {
        axieClass.current = []
        eyesClass.current = []
        earsClass.current = []
        axieParts.current = []
        breedCount.current = ''
        purity.current = ''
        sort.current = 'PRICE_ASC'
        setHealth([27,61])
        setMoral([27,61])
        setSkill([27,61])
        setSpeed([27,61])
    }

    const avgPrice = useMemo(()=>{
        let result = 0
        if(searchResults.length > 19 )
        {
            result = searchResults.slice(0,20).reduce((acc,seed)=>+acc + +seed.ethPrice,0) / 20
        }
        else if(searchResults.length < 20 &&  searchResults.length > 0)
        {
            result = searchResults.slice(0,searchResults.length).reduce((acc,seed)=>+acc + +seed.ethPrice,0) / searchResults.length
        }
        return result.toFixed(3)
    },[searchResults])

    const avgPriceForty = useMemo(()=>{
        let result = 0
        if(searchResults.length > 39 )
        {
            result = searchResults.slice(0,40).reduce((acc,seed)=>+acc + +seed.ethPrice,0) / 40
        }
        else if(searchResults.length < 40 &&  searchResults.length > 0)
        {
            result = searchResults.slice(0,searchResults.length).reduce((acc,seed)=>+acc + +seed.ethPrice,0) / searchResults.length
        }
        return result.toFixed(3)
    },[searchResults])

    const avgPriceSixty = useMemo(()=>{
        let result = 0
        if(searchResults.length > 59 )
        {
            result = searchResults.slice(0,60).reduce((acc,seed)=>+acc + +seed.ethPrice,0) / 60
        }
        else if(searchResults.length < 60 &&  searchResults.length > 0)
        {
            result = searchResults.slice(0,searchResults.length).reduce((acc,seed)=>+acc + +seed.ethPrice,0) / searchResults.length
        }
        return result.toFixed(3)
    },[searchResults])

    const avgPriceNumberOfResults = useMemo(()=>{
        if(searchResults.length > 19 )
        {
            return 20
        }
        else if(searchResults.length < 20 &&  searchResults.length > 0)
        {
            return searchResults.length
        }
    },[searchResults])
    
    const avgPriceFortyNumberOfResults = useMemo(()=>{
        if(searchResults.length > 39 )
        {
            return 40
        }
        else if(searchResults.length < 40 &&  searchResults.length > 0)
        {
            return searchResults.length
        }
    },[searchResults])

    const avgPriceSixtyNumberOfResults = useMemo(()=>{
        if(searchResults.length > 59 )
        {
            return 60
        }
        else if(searchResults.length < 60 &&  searchResults.length > 0)
        {
            return searchResults.length
        }
    },[searchResults])

    const onKeyPressPurity = (e) => {
       //const specialCharRegex = new RegExp("[a-zA-Z0-9@.' ,-]");
       const specialCharRegex = new RegExp("[1-6]");
       const pressedKey = String.fromCharCode(!e.charCode ? e.which : e.charCode);
       if (!specialCharRegex.test(pressedKey)) {
          e.preventDefault();
          return false;
       }
    }

    const onKeyPressBCount = (e) => {
       //const specialCharRegex = new RegExp("[a-zA-Z0-9@.' ,-]");
       const specialCharRegex = new RegExp("[0-7]");
       const pressedKey = String.fromCharCode(!e.charCode ? e.which : e.charCode);
       if (!specialCharRegex.test(pressedKey)) {
          e.preventDefault();
          return false;
       }
    }

    // const onKeyPressThreshold = (e) => {
    // const specialCharRegex = new RegExp("[a-zA-Z0-9@.' ,-]");
    //    const specialCharRegex = new RegExp("[0-9]");
    //    const pressedKey = String.fromCharCode(!e.charCode ? e.which : e.charCode);
    //    if (!specialCharRegex.test(pressedKey)) {
    //       e.preventDefault();
    //       return false;
    //    }
    // }

    const isPreOrigin = (e) => {
        setIsPreOriginChecked(e.target.checked);
        isPreOriginStat.current = e.target.checked;

        form.resetFields();
        onReset();
    }

    return (
        <>
            <Header title='Breeder Algo % Searcher' />
            <Form
                form={form}
                layout="horizontal"
                name="advanced_search"
                className="ant-advanced-search-form"
                onFinish={onFinish}
            >
                <Row gutter={40}>
                    <Col xs={24}>
                        <div className={styles.fieldsWrapper}>
                            <div>
                                <Checkbox defaultChecked onChange={isPreOrigin} style={{color: "#fff"}} checked={isPreOriginChecked}>Select Pre-origin version</Checkbox>
                            </div>
                            <br />&nbsp;
                        </div>
                    </Col>
                    <Col xs={24}>
                        <div className={styles.fieldsWrapper}>
                            {isPreOriginChecked ? <>
                                <div>
                                    <label className="white">Eyes Class</label>
                                    <Form.Item
                                        name={`fieldEyesClass`}
                                        // label={`Eyes`}
                                        rules={[
                                            {
                                                required: false,
                                                message: 'Input something!',
                                            },
                                        ]}

                                    >
                                        <Select showSearch name="eyesClass" placeholder="Please choose Eyes Class" mode="multiple" allowClear onChange={handleParts("eyesClass")} style={{width: '100%'}}>
                                            {
                                                data && data.SearchParametersQuery && data.SearchParametersQuery.classes.filter(i=> !["Dusk","Dawn","Mech"].includes(i.name)).map((item, key) => <Option key={key} value={item.name.toUpperCase()}>{item.name}</Option>)
                                            }
                                        </Select>
                                    </Form.Item>
                                </div>
                                <div>
                                    <label className="white">Ears Class</label>
                                    <Form.Item
                                        name={`fieldEarsClass`}
                                        // label={`Ears`}
                                        rules={[
                                            {
                                                required: false,
                                                message: 'Input something!',
                                            },
                                        ]}

                                    >
                                        <Select showSearch name="earsClass" placeholder="Please choose Ears Class" mode="multiple" allowClear onChange={handleParts("earsClass")}>
                                            {
                                                data && data.SearchParametersQuery && data.SearchParametersQuery.classes.filter(i=> !["Dusk","Dawn","Mech"].includes(i.name)).map((item, key) => <Option key={key} value={item.name.toUpperCase()}>{item.name}</Option>)
                                            }
                                        </Select>
                                    </Form.Item>
                                </div>
                            </> : <>
                                    <div>
                                        <label className="white">Eyes</label>
                                        <Form.Item
                                            name={`fieldEyes`}
                                            rules={[
                                                {
                                                    required: false,
                                                    message: 'Input something!',
                                                },
                                            ]}
                                        >
                                            <Select showSearch name="eyes" placeholder="Please choose Eyes" mode="multiple" allowClear onChange={handleParts("eyes")}>
                                                {
                                                    data && data.SearchParametersQuery && data.SearchParametersQuery.eyes.map((item, key) => <Option key={key} value={item.partId}>{item.name}</Option>)
                                                }
                                            </Select>
                                        </Form.Item>
                                    </div>
                                    <div>
                                        <label className="white">Ears</label>
                                        <Form.Item
                                            name={`fieldEars`}
                                            rules={[
                                                {
                                                    required: false,
                                                    message: 'Input something!',
                                                },
                                            ]}
                                        >
                                            <Select showSearch name="ears" placeholder="Please choose Ears" mode="multiple" allowClear onChange={handleParts("ears")}>
                                                {
                                                    data && data.SearchParametersQuery && data.SearchParametersQuery.ears.map((item, key) => <Option key={key} value={item.partId}>{item.name}</Option>)
                                                }
                                            </Select>
                                        </Form.Item>
                                    </div>     
                            </>
                            }
                            <div>
                                <label className="white">Mouth</label>
                                <Form.Item
                                    name={`fieldMouth`}
                                    // label={`Mouth`}
                                    rules={[
                                        {
                                            required: false,
                                            message: 'Input something!',
                                        },
                                    ]}
                                >
                                    <Select showSearch name="mouth" placeholder="Please choose Mouth" mode="multiple" allowClear onChange={handleParts("mouth")}>
                                        {
                                            data && data.SearchParametersQuery && data.SearchParametersQuery.mouth.map((item, key) => <Option key={key} value={item.partId}>{item.name}</Option>)
                                        }
                                    </Select>
                                </Form.Item>
                            </div>
                            <div>
                                <label className="white">Horn</label>
                                <Form.Item
                                    name={`fieldHorn`}
                                    // label={`Horn`}
                                    rules={[
                                        {
                                            required: false,
                                            message: 'Input something!',
                                        },
                                    ]}

                                >
                                    <Select showSearch name="horn" placeholder="Please choose Horn" mode="multiple" allowClear onChange={handleParts("horn")}>
                                        {
                                            data && data.SearchParametersQuery && data.SearchParametersQuery.horn.map((item, key) => <Option key={key} value={item.partId}>{item.name}</Option>)
                                        }
                                    </Select>
                                </Form.Item>
                            </div>
                            <div>
                                <label className="white">Back</label>
                                <Form.Item
                                    name={`fieldBack`}
                                    // label={`Back`}
                                    rules={[
                                        {
                                            required: false,
                                            message: 'Input something!',
                                        },
                                    ]}

                                >
                                    <Select showSearch name="back" placeholder="Please choose Back" mode="multiple" allowClear onChange={handleParts("back")}>
                                        {
                                            data && data.SearchParametersQuery && data.SearchParametersQuery.back.map((item, key) => <Option key={key} value={item.partId}>{item.name}</Option>)
                                        }
                                    </Select>
                                </Form.Item>
                            </div>
                            <div>
                                <label className="white">Tail</label>
                                <Form.Item
                                    name={`fieldTail`}
                                    // label={`Tail`}
                                    rules={[
                                        {
                                            required: false,
                                            message: 'Input something!',
                                        },
                                    ]}

                                >
                                    <Select showSearch name="tail" placeholder="Please choose tail" mode="multiple" allowClear onChange={handleParts("tail")}>
                                        {
                                            data && data.SearchParametersQuery && data.SearchParametersQuery.tail.map((item, key) => <Option key={key} value={item.partId}>{item.name}</Option>)
                                        }
                                    </Select>
                                </Form.Item>
                            </div>
                            <div>
                                <label className="white">Class</label>
                                <Form.Item
                                    name={`fieldClass`}
                                    // label={`Class`}
                                    rules={[
                                        {
                                            required: true,
                                            message: 'Input something!',
                                        },
                                    ]}
                                >
                                    <Select name="axieClass" placeholder="Please choose class" mode="multiple" allowClear onChange={handleClass}>
                                        {
                                            data && data.SearchParametersQuery && data.SearchParametersQuery.classes.map((item, key) => <Option key={key} value={item.name.toUpperCase()}>{item.name}</Option>)
                                        }
                                    </Select>
                                </Form.Item>
                            </div>
                            <div>
                                <label className="white">Breed #</label>
                                <Form.Item
                                    name={`fieldBreedCount`}
                                    // label={`Breed #`}
                                    onKeyPress = {onKeyPressBCount}
                                    rules={[
                                        {
                                            min: 0,
                                            max: 7,
                                            type: "number",
                                            //transform: (value) => parseInt(Math.min(7,Math.max(0,value))),
                                            required: false,

                                            message: 'Must be 1-6',
                                        },
                                    ]}
                                // onKeyUp={(event) => event.target.value != '' && setBreedCount(event.target.value)}
                                >
                                    <InputNumber style={{ width: '100%' }} placeholder="Please input Breed Count" min={0} max={7} maxLength={1} className="ant-input" />
                                </Form.Item>
                            </div>
                            <div>
                                <label className="white">Purity</label>
                                <Form.Item
                                    name={`fieldPurity`}
                                    // label={`Purity`}
                                    onKeyPress = {onKeyPressPurity}
                                    rules={[
                                        {
                                            min: 1,
                                            max: 6,
                                            type: "number",
                                            //transform: (value) => parseInt(Math.min(6,Math.max(1,value))),
                                            required: false,
                                            message: 'Must be 1-6',
                                        },
                                    ]}
                                >
                                    <InputNumber style={{ width: '100%' }} placeholder="Please input Purity" min={1} max={6} maxLength={1} className="ant-input" />
                                </Form.Item>
                            </div>
                            <div>
                                <label className="white">Sort</label>
                                <Form.Item
                                    name={`fieldSort`}
                                    // label={`Sort`}
                                    rules={[
                                        {
                                            required: true,
                                            message: 'Input something!',
                                        },
                                    ]}
                                    initialValue={sort.current}
                                >
                                    <Select name="sort" placeholder="Sort">
                                        <Option value="PRICE_ASC">Price Ascending</Option>
                                        <Option value="PRICE_DESC">Price Descending</Option>
                                        <Option value="ID_ASC">ID Ascending</Option>
                                        <Option value="ID_DESC">ID Descending</Option>
                                        {/* <Option value="LATEST">Latest</Option> */}
                                    </Select>
                                </Form.Item>
                            </div>
                            <div>
                                <label className="white">Threshold</label>
                                <Form.Item
                                    name={`fieldThreshold`}
                                    // label={`Threshold`}
                                    // onKeyPress = {onKeyPressThreshold}
                                    rules={[
                                        {
                                            //min: 0,
                                            //max: 99,
                                            type: "string",
                                            //transform: (value) => parseInt(Math.min(100,Math.max(0,value))),
                                            required: false,
                                            message: 'Must be 0-100',
                                        },
                                    ]}
                                >
                                    <Input style={{ width: '100%' }} placeholder="Please input Threshold" className="ant-input"  />
                                </Form.Item>
                            </div>
                            <div>
                                <label className="white">
                                    HEALTH (Min <i style={{color: "#FFB70F"}}>{health[0]}</i>, Max <i style={{color: "#FFB70F"}}>{health[1]}</i>)
                                </label>
                                <Slider range min={27} max={61} value={health} defaultValue={health} style={{ width: '80%' }} onChange={(e) => {setValue(e, 'health')}}/>
                            </div>
                            <div>
                                <label className="white">
                                    SPEED (Min <i style={{color: "#FFB70F"}}>{speed[0]}</i>, Max <i style={{color: "#FFB70F"}}>{speed[1]}</i>)
                                </label>
                                <Slider range min={27} max={61} value={speed} defaultValue={speed} style={{ width: '80%' }} onChange={(e) => {setValue(e, 'speed')}}/>
                            </div>
                            <div>
                                <label className="white">
                                    SKILL (Min <i style={{color: "#FFB70F"}}>{skill[0]}</i>, Max <i style={{color: "#FFB70F"}}>{skill[1]}</i>)
                                </label>
                                <Slider range min={27} max={61} value={skill} defaultValue={skill} style={{ width: '80%' }} onChange={(e) => {setValue(e, 'skill')}}/>
                            </div>
                            <div>
                                <label className="white">
                                    MORALE (Min <i style={{color: "#FFB70F"}}>{moral[0]}</i>, Max <i style={{color: "#FFB70F"}}>{moral[1]}</i>)
                                </label>
                                <Slider range min={27} max={61} value={moral} defaultValue={moral} style={{ width: '80%' }} onChange={(e) => {setValue(e, 'moral')}}/>
                            </div>
                        </div>
                    </Col>
                </Row>

                <Row gutter={40}>
                    <Col
                        span={7}
                        style={{
                            textAlign: 'left',
                        }}
                    >
                        { 
                            searchResults.length > 0 && <Title level={5} className="content-title">
                                                            Avg. Price of first {avgPriceNumberOfResults} : {parseFloat(avgPrice).toFixed(3)} Ξ<br />
                                                            Avg. Price of first {avgPriceNumberOfResults} : {parseFloat(avgPrice * exchangeRates.current.ethereum.usd).toFixed(3)} $
                                                        </Title>
                        }
                    </Col>

                    <Col
                        span={6}
                        style={{
                            textAlign: 'left',
                        }}
                    >
                        { 
                            searchResults.length > 0 && <Title level={5} className="content-title">
                                                            Avg. Price of first {avgPriceFortyNumberOfResults} : {parseFloat(avgPriceForty).toFixed(3)} Ξ<br />
                                                            Avg. Price of first {avgPriceFortyNumberOfResults} : {parseFloat(avgPriceForty * exchangeRates.current.ethereum.usd).toFixed(3)} $
                                                        </Title>
                        }
                    </Col>

                    <Col
                        span={6}
                        style={{
                            textAlign: 'left',
                        }}
                    >
                        { 
                            searchResults.length > 0 && <Title level={5} className="content-title">
                                                            Avg. Price of first {avgPriceSixtyNumberOfResults} : {parseFloat(avgPriceSixty).toFixed(3)} Ξ<br />
                                                            Avg. Price of first {avgPriceSixtyNumberOfResults} : {parseFloat(avgPriceSixty * exchangeRates.current.ethereum.usd).toFixed(3)} $
                                                        </Title>
                        }
                    </Col>

                    <Col
                        span={5}
                        style={{
                            textAlign: 'right',
                        }}
                    >
                        <Button type="primary" htmlType="submit" disabled={loading}>
                            Search
                        </Button>
                        <Button
                            style={{
                                margin: '0 8px',
                            }}
                            onClick={() => {
                                form.resetFields();
                                onReset()
                            }}
                        >
                            Clear
                        </Button>
                    </Col>
                </Row>
            </Form>

            <Row gutter={40}>
                <Col xs={24} xl={24}>
                    <br />
                    {/*<Pagination size="small" onChange={pageChange} itemRender={itemRender} total={!loading && searchResults && searchResults.SearchAxiesR1R2Query.results.length}/>*/}
                    <Table
                        className="table"
                        columns={columns}
                        pagination={true}
                        dataSource={searchResults.length > 0 && searchResults}
                        onChange={onChangeTable}
                        loading={loading}
                        rowKey="id"
                    />
                </Col>
            </Row>
        </>
    )
}

export default SearchAxieV2